/**
 * The Home component is the welcoming page of the application, designed to give users an immediate overview of the application's capabilities and encourage exploration and engagement. It acts as a central hub from which users can navigate to various features of the application.
 * 
 * Interactions and Features:
 * - **Topbar Component**: Integrated at the top for consistent navigation and branding. The Topbar includes the application's logo and may offer navigation links or language selection options, ensuring a seamless user experience across different parts of the application.
 * - **Language Selection**: Utilizes the `useTranslation` hook from `react-i18next` for dynamic language switching. This allows users to select their preferred language, which affects not just the Home page but also propagates this preference across other components and pages within the application.
 * - **Navigation Buttons**: Material-UI `Button` components are used to direct users to key areas of the application, such as signing up (`/signup`), survey creation (`/createSurvey`), and survey participation (`/survey`). These actions are facilitated through the `useNavigate` hook from `react-router-dom`, demonstrating how the Home component serves as a launchpad for user activities.
 * - **Dynamic Content Display**: The component dynamically displays content like GIFs and information texts, which are internationalized to match the user's selected language. This includes descriptions of how to use the application, the benefits of participating in surveys, and how results are utilized.
 * - **Contact Information**: A section is dedicated to providing users with contact information, encouraging users to reach out for support or more information. This direct line of communication is crucial for user support and feedback collection.
 * - **SVG Wave Component**: A custom SVG component (`Wave`) adds a decorative, dynamic visual element to the page, enhancing the aesthetic appeal without impacting the navigational or informational functions of the page.
 * 
 */

import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import logo from "../../assets/logo.svg"; 
import createMonitoringAssessment from "../../assets/create-monitoring-and-assessment.gif"; 
import editPreviewOpenAssessment from "../../assets/edit-preview-open-assessment.gif"; 
import results from "../../assets/results.gif"; 
import logbook from "../../assets/logbook.gif"; 
import logoEpfl from "../../assets/logo-epfl.svg";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Topbar from "../../scenes/global/Topbar";

const Home = () => {
  
  const { t } = useTranslation('translation');
  const { i18n } = useTranslation();
  const [lng, setLng] = useState(i18n.language);
  const navigate = useNavigate();

  /**
 * Initializes the component's state with the current language from i18next and sets up a listener to update the state whenever the language changes.
 *  This ensures that the component re-renders with the correct language content.
 */
  useEffect(() => {
    const handleLanguageChange = (newLng) => {
      setLng(newLng);
    };

    // Attach the event listener
    i18n.on('languageChanged', handleLanguageChange);

    // Clean up on component unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  /**
 * A functional component that renders a decorative SVG wave pattern. 
 * The 'reverse' prop determines the orientation of the wave.
 */
  const Wave = ({ reverse }) => (
  <svg viewBox="0 0 1200 120" preserveAspectRatio="none" style={{ width: '100%', height: '150px', transform: reverse ? 'rotate(0deg)' : 'rotate(180deg)' }}>
    <path d="M0 0 C 300 100 650 0 1200 100 V 120 H 0 Z" fill="rgba(87,193,202,0.5)"></path>
  </svg>
);

  return (
    <Box display="flex" backgroundColor="white" style={{height: '100vh', overflow: 'auto'} }>
          <Box>
              <Topbar logo={logo} boxShadow={"rgba(0, 0, 0, 0.24) 0px 8px 8px -8px"} />
              <Box>
               <Wave/>
               <Box display="flex" flexDirection="column" alignItems="center" mt={-5} ml={20} mr={20}>
                <Typography variant="h2" sx={{ fontWeight: "bold" }} mb={2} >
                  {t('home.title')}
                </Typography>
                <Typography variant="h3" align="justify" mt={1} mb={5} >
                  {t('home.description')}
                </Typography>
                 <Box display="flex" flexDirection="row" justifyContent="center" mt={-2} >
                      <Button
                        variant="contained"
                        onClick={() => navigate("/signup", { state: { sandbox: true } })} // navigation to signup/index.jsx
                        sx={{
                          mr: 2,
                          backgroundColor: '#F7941E',
                          borderRadius: '50px',
                          color: 'black',
                          '&:hover': {
                            backgroundColor: '#D17A1D',
                          },
                        }}
                      >
                        <Typography variant="h4">{t('home.try_button')}</Typography>
                      </Button>
                      <Button 
                        variant="contained"
                        onClick={() => navigate("/dashboard", { state: { sandbox: true } })} // navigation to signup/index.jsx
                        sx={{
                          backgroundColor: '#F7941E',
                          borderRadius: '50px',
                          color: 'black',
                          '&:hover': {
                            backgroundColor: '#D17A1D',
                          },
                        }}
                      >
                        <Typography variant="h4">{t('home.signin_button')}</Typography>
                      </Button>
                  </Box>
                  <Box mt={1}  mb={1} display="flex" justifyContent="center" textAlign="center" flexDirection="column" alignItems="center">
                    <Typography mt={1} variant="h6">{t('home.try_message')}</Typography>
                    <Typography variant="h6">{t('home.try_message2')}</Typography>
                    <Typography variant="h6">{t('home.try_message3')}</Typography>
                    <Typography variant="h6">{t('home.try_message4')}<a href="mailto:info@digitaltrainingcompanion.tech">info@digitaltrainingcompanion.tech</a> </Typography>
                  </Box>
                  
                  
                  <Typography variant="h3" align="justify" mt={4} >
                  {t('home.survey_description')}
                 </Typography>
                  <Box mt={3} display="flex" justifyContent="center">
                  <Button 
                    variant="contained"
                    onClick={() => navigate("/survey")} // navigation to survey/index.jsx
                    sx={{
                      backgroundColor: '#F7941E',
                      borderRadius: '50px',
                      color: 'black',
                      '&:hover': {
                        backgroundColor: '#D17A1D',
                      },
                    }}
                  >
                 
                    <Typography variant="h4">{t('home.survey_button')}</Typography>
                  </Button>
                </Box>
                <Box mt={1} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                  <Typography variant="h6">{t('home.survey_message')}</Typography>
                  <Typography variant="h6">{t('home.survey_message2')}</Typography>
                  <Typography variant="h6">{t('home.survey_message3')}</Typography>
                </Box>
                  <Box display="flex" flexDirection="column" alignItems="center" mt={3} mb={-6}>
                    <img
                    alt=""
                    src={logoEpfl}
                    style={{
                       cursor: "pointer", 
                      borderRadius: "1%", 
                      maxWidth: "200px",
                      minWidth: "200px",
                    }}
                    />
                  </Box>
                </Box>
              </Box>
              <Wave reverse={true}/>
              <Box display="flex" flexDirection="column" alignItems="center" ml={20} mr={20} mb={2}>
                 <Typography variant="h2" sx={{ fontWeight: "bold" }} mt={8} align="center" >
                  {t('home.title2')}
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: "bold" }} mt={4} mb={1}>
                  {t('home.subtitle2')}
                </Typography>
                <Typography variant="h5" mt={1} mb={2} align="justify" maxWidth="90%">
                  {t('home.description2')}
              </Typography>
                  <img
                  alt=""
                  src={createMonitoringAssessment}
                  style={{
                    cursor: "pointer", 
                    borderRadius: "1%", 
                    maxWidth: "90%",
                    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)"  
                  }}
                />

                <Typography variant="h3" sx={{ fontWeight: "bold" }} mt={4} mb={1}>
                  {t('home.subtitle3')}
                </Typography>
                <Typography variant="h5" mt={1} mb={2} align="justify" maxWidth="90%">
                  {t('home.description3')} 
                </Typography>
                
                <img
                  alt=""
                  src={editPreviewOpenAssessment}
                  style={{
                    cursor: "pointer", 
                    borderRadius: "1%", 
                    maxWidth: "90%",
                    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)"  
                  }}
                />
                <Typography variant="h3" sx={{ fontWeight: "bold" }} mt={4} mb={1}>
                  {t('home.subtitle4')}
                </Typography>
                <Typography variant="h5" mt={1} mb={2} align="justify" maxWidth="90%">
                  {t('home.description4')}
                </Typography>
                <img
                  alt=""
                  src={results}
                  style={{
                    cursor: "pointer", 
                    borderRadius: "1%", 
                    maxWidth: "90%",
                    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)"  
                  }}
                />
                <Typography variant="h3" sx={{ fontWeight: "bold" }} mt={4} mb={1}>
                  {t('home.subtitle5')}  
                </Typography>
                <Typography variant="h5" mt={1} mb={2} align="justify" maxWidth="90%">
                  {t('home.description5')}
                </Typography>           
                <img
                  alt=""
                  src={logbook}
                  style={{
                    cursor: "pointer", 
                    borderRadius: "1%", 
                    maxWidth: "90%",
                    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)"  
                  }}
                />
                
              </Box>
              <Box>

                 
                 

                
                </Box>
              </Box>
                 </Box>
 
  )
};

export default Home;
