/**
 * Main Function:
 * - The CreateSurvey component dynamically renders different forms based on the assessment type selected, facilitating the creation of various types of survey questions related to training evaluation.
 * 
 * Frontend Operations:
 * - Dynamically selects and renders a specific component for adding survey questions based on the assessment type passed through the location state. This includes components for trainee characteristics, training characteristics, immediate reactions, learning outcomes, organizational conditions, sustainability conditions, student characteristics, and student learning outcomes.
 * - Utilizes the Sidebar and Topbar components for navigation and layout consistency across the application.
 * - Uses React Router's useLocation hook to access the assessment type and name from the previous page's state, ensuring the correct form is displayed for creating survey questions.
 * 
 * Backend Connections:
 * - While this component primarily focuses on frontend operations, the individual components it renders (e.g., AddTraineeCharacteristics, AddTrainingCharacteristics, etc.) are responsible for submitting the created survey questions to the backend.
 * - Each of these components may interact with specific backend endpoints to save the new survey questions, although the exact endpoints are not specified in this snippet. The interaction typically involves sending a POST request to a survey-related API endpoint on form submission.
 */


import { Box} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../scenes/global/Sidebar";
import Topbar from "../../scenes/global/Topbar";
import React, { useState, useEffect} from "react";
import AddTraineeCharacteristics from "../../components/AddTraineeCharacteristics";
import AddTrainingCharacteristics from "../../components/AddTrainingCharacteristics";
import AddImmediateReactions from "../../components/AddImmediateReactions";
import AddLearning from "../../components/AddLearning";
import AddOrganizationalConditions from "../../components/AddOrganizationalConditions";
import AddSustainabilityConditions from "../../components/AddSustainabilityConditions";
import AddStudentCharacteristics from "../../components/AddStudentCharacteristics";
import AddStudentLearning from "../../components/AddStudentLearning";
import AddBehavioralChanges from "../../components/AddBehavioralChanges";

const CreateSurvey = () => {
    const location = useLocation();
    const { assessmentType, assessmentName } = location.state || {};
    
    return (
        <Box display="flex" backgroundColor="white" style={{ height: '100vh' }}>
            <Sidebar />
            <Box flex={1}>
                <Box ml="10px">
                    <Topbar />
                </Box>
                    {assessmentType === "Trainee characteristics" && <AddTraineeCharacteristics assessmentName={assessmentName} />}  
                    {assessmentType === "Training characteristics" && <AddTrainingCharacteristics assessmentName={assessmentName} />}  
                    {assessmentType === "Immediate reactions" && <AddImmediateReactions assessmentName={assessmentName} />}  
                    {assessmentType === "Learning" && <AddLearning assessmentName={assessmentName} />}  
                    {assessmentType === "Organizational conditions" && <AddOrganizationalConditions assessmentName={assessmentName} />}
                    {assessmentType === "Behavioral changes" && <AddBehavioralChanges assessmentName={assessmentName} />}  
                    {assessmentType === "Sustainability conditions" && <AddSustainabilityConditions assessmentName={assessmentName} />}
                    {assessmentType === "Student characteristics" && <AddStudentCharacteristics assessmentName={assessmentName} />}
                    {assessmentType === "Student learning outcomes" && <AddStudentLearning assessmentName={assessmentName} />}  

                </Box>
            </Box>

    );

};

export default CreateSurvey;