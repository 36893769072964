/**
 * The Footer component serves as an informational and interactive section at the bottom of the application, indicating the application's current version and mode, and providing a quick way for users to report bugs. It enhances user engagement and feedback mechanisms.
 *
 * Functions and Interactions:
 * - **Version Indicator**: Displays a chip indicating the application is in Beta version, informing users about the development stage of the application.
 * - **Sandbox Mode Indicator**: If the user is in sandbox mode, determined by decoding the JWT stored in localStorage, a chip indicating "Sandbox mode" is shown. This feature is useful for users testing or exploring the application without affecting production data.
 * - **Bug Reporting**: Offers a "Report a bug" chip that, when clicked, copies a predefined email address to the user's clipboard and shows a Snackbar notification as feedback. This simplifies the process for users to report issues or provide feedback.
 * - **Feedback Notification**: Utilizes Snackbar and Alert components from MUI to give immediate feedback when the email address has been copied to the clipboard
 */

import React, { useState } from 'react';
import { Box, Chip, Snackbar, Alert } from "@mui/material";
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';

const Footer = () => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const token = localStorage.getItem("token");
    let isSandboxMode = false;

    if (token) {
        const payload = JSON.parse(atob(token.split('.')[1]));
        isSandboxMode = payload.sandbox;
    }

    const handleReportBugClick = () => {
        navigator.clipboard.writeText("info@digitaltrainingcompanion.tech")
            .then(() => {
                setOpenSnackbar(true);
            })
            .catch(err => {
                console.error('Could not copy email to clipboard: ', err);
            });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" mb="10px">
            <Box display="flex" alignItems="center">
                <Chip
                    label="Beta version"
                    variant="outlined"
                    color="primary"
                    sx={{ marginRight: 2 }}
                />

                {isSandboxMode && (
                    <Chip
                        icon={<VideogameAssetIcon style={{ color: 'black' }} />}
                        label="Sandbox mode"
                        variant="outlined"
                        sx={{ p: '5px', marginRight: 2, color: 'black', bgcolor: 'rgb(195,114,45)', border: 'none' }}
                    />
                )}

                <Chip 
                    label="Report a bug"
                    variant="outlined"
                    onClick={handleReportBugClick}
                    sx={{ cursor: 'pointer' }}
                />
            </Box>

            <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Email address copied to clipboard!
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Footer;
