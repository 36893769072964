/**
 * Main Function:
 * - The AddLearning component is designed to create and manage questions specifically related to the learning outcomes of training programs. It supports adding new questions, editing existing ones, and organizing them into workshops if required.
 * 
 * Frontend Operations:
 * - Utilizes Formik for handling form submissions, allowing for the dynamic addition and editing of questions.
 * - Questions can be organized into workshops, enabling a structured approach to categorizing learning outcome questions.
 * - Features include duplicating questions, editing details (including uploading images for questions), deleting questions, and reordering questions within their respective workshops.
 * - Offers a switch to toggle between a single workshop and multiple workshops, providing flexibility in how questions are grouped and presented.
 * - Integrates an image upload feature for questions, enhancing the interactive aspect of question presentation.
 * 
 * Backend Connections:
 * - Fetches existing data for the assessment from the backend using a GET request to `${BACKEND_URL}/survey`, enabling the component to pre-populate with existing questions and workshop arrangements.
 * - Images for questions are uploaded through a POST request to `${BACKEND_URL}/upload`, and the image URL is stored as part of the question's data.
 * - Final submission of all questions (including any changes made) is done through a PUT request to `${BACKEND_URL}/monitorings/${currentMonitoringServerId}/assessments/${currentAssessmentServerId}/survey`, ensuring that all modifications are saved and reflected in the backend.
 */

import { Box, Button, Typography, IconButton, FormControlLabel, Switch, TextField, Grid, FormControl, InputLabel, Select, MenuItem, Chip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SurveyQuestion from './SurveyQuestion';
import { Formik, Form } from 'formik';
import { Delete, ArrowUpward, ArrowDownward, Edit, ContentCopy, AddPhotoAlternate } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import React, { useState, useEffect} from "react";
import AddLearningQuestions from "./AddLearningQuestions";
import AddWorkshop from "./AddWorkshop";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { BACKEND_URL } from "../config";
import { traineeCompetenceAreas, 
  digCompEduElementaryCompetences, 
  digCompEduActivities, 
  RCNumObligatoireElementaryCompetences,
  RCNumObligatoireActivities,
  RCNumPostObligatoireElementaryCompetences,
  RCNumPostObligatoireActivities } from "../data/frameworksData" 

const AddLearning = ({ assessmentName }) => {
    
    const location = useLocation();
    const navigate = useNavigate();
    const { assessmentType, currentAssessmentServerId, currentMonitoringServerId } = location.state || {};
    const [initialQuestions, setInitialQuestions] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [workshops, setWorkshops] = useState([]);
    const [splitWorkshops, setSplitWorkshops] = useState(false);
    const [editingQuestionId, setEditingQuestionId] = useState(null);
    const [automaticEncoding, setAutomaticEncoding] = useState(false);
    const [selectedArea, setSelectedArea] = useState('');
    const [selectedCompetency, setSelectedCompetency] = useState('');
    const [activity, setActivity] = useState(''); 

   useEffect(() => {
    const fetchExistingData = async () => {
        try {
                const token = localStorage.getItem("token");
                const decodedToken = jwt_decode(token);
                const sandbox = decodedToken.sandbox;
                const response = await axios.get(`${BACKEND_URL}/survey`, {
                params: {
                currentAssessmentServerId: currentAssessmentServerId,
                currentMonitoringServerId: currentMonitoringServerId,
                sandbox: sandbox
                }
            });
            if (
                response.data &&
                response.data.survey &&
                Array.isArray(response.data.survey) &&
                response.data.survey.length > 0
            ) {

                // Check for workshops values and set splitWorkshops
                const workshopsArray = Array.from(new Set(response.data.survey
                .map(survey => survey.workshop)
                .filter(workshop => workshop)))
                if (Array.isArray(workshopsArray) && workshopsArray.length > 0) {
                    setSplitWorkshops(true);
                    setWorkshops(workshopsArray);
                
                    console.log("splitWorkShop",splitWorkshops)
                    console.log("Workshops",workshops)
                }

                const updatedQuestions = response.data.survey.map(question => ({
                    ...question,
                    options: question.choices.map(choice => ({ label: choice, value: choice })),
                }));
                
                setQuestions(updatedQuestions);
            }
        } catch (error) {
            console.error('Error fetching existing survey data:', error);
        }
    };

    fetchExistingData();
}, [currentMonitoringServerId, currentAssessmentServerId]);

const moveQuestion = (workshopName, questionId, direction) => {

    console.log(workshopName, questionId, direction)
    // Filter questions for the specified workshop
    const workshopQuestions = questions.filter(question => question.workshop === workshopName);
 
    // Find the index of the question to be moved within the workshop
    const questionIndex = workshopQuestions.findIndex(question => question.questionId === questionId);
    if (questionIndex === -1) return; // question not found

    // Check bounds within the workshop's questions
    if ((direction === 'up' && questionIndex === 0) || (direction === 'down' && questionIndex === workshopQuestions.length - 1)) {
        return; // Can't move beyond array bounds within the same workshop
    }

    const moveIndex = direction === 'up' ? questionIndex - 1 : questionIndex + 1;

    // Find the actual indices of these questions in the main questions array
    const actualIndex = questions.findIndex(question => question.questionId === workshopQuestions[questionIndex].questionId && question.workshop === workshopName);
    const actualMoveIndex = questions.findIndex(question => question.questionId === workshopQuestions[moveIndex].questionId && question.workshop === workshopName);

    // Swap the questions in the main array
    const newQuestions = [...questions];
    [newQuestions[actualIndex], newQuestions[actualMoveIndex]] = [newQuestions[actualMoveIndex], newQuestions[actualIndex]];
    setQuestions(newQuestions);
};

const duplicateQuestion = (questionId) => {
    setQuestions((prevQuestions) => {
        const questionIndex = prevQuestions.findIndex(question => question.questionId === questionId);
        if (questionIndex === -1) return prevQuestions;

        const originalQuestion = prevQuestions[questionIndex];

        // Find the maximum questionId in the current list of questions
        const maxQuestionId = Math.max(...prevQuestions.map(question => question.questionId));
        const newQuestionId = maxQuestionId + 1;

        const newQuestion = { ...originalQuestion, questionId: newQuestionId };

        // Handle the copy label for the duplicated question
        const copyRegex = / \(Copy(?: (\d+))?\)$/;
        const copyMatch = originalQuestion.question.match(copyRegex);
        let newCopyLabel;
        if (copyMatch) {
            const copyNumber = copyMatch[1] ? parseInt(copyMatch[1], 10) + 1 : 2; // Start from 2 if it's a subsequent copy
            newCopyLabel = ` (Copy ${copyNumber})`;
        } else {
            newCopyLabel = ` (Copy)`;
        }

        newQuestion.question = originalQuestion.question.replace(copyRegex, '') + newCopyLabel;
        newQuestion.shortName = originalQuestion.shortName.replace(copyRegex, '') + newCopyLabel;

        // Insert the new question right after the original
        const updatedQuestions = [
            ...prevQuestions.slice(0, questionIndex + 1),
            newQuestion,
            ...prevQuestions.slice(questionIndex + 1),
        ];

        return updatedQuestions;
    });
};

// Editing question
const startEditing = (questionId) => {
    setEditingQuestionId(questionId);
    setAutomaticEncoding(false);
    setSelectedArea('');
    setSelectedCompetency('');
    setActivity('');
};

const saveEdits = (questionId, newQuestionData) => {
    setQuestions(prevQuestions =>
        prevQuestions.map(question =>
            question.questionId === questionId ? { ...question, ...newQuestionData } : question
        )
    );
    setEditingQuestionId(null);
};

const cancelEdit = () => {
    setEditingQuestionId(null);
};

const addOption = (questionId) => {
    setQuestions(prevQuestions =>
        prevQuestions.map(question =>
            question.questionId === questionId
                ? { ...question, options: [...question.options, { label: '', value: '' }] }
                : question
        )
    );
};

const removeOption = (questionId, optionIndex) => {
    setQuestions(prevQuestions =>
        prevQuestions.map(question =>
            question.questionId === questionId
                ? { ...question, options: question.options.filter((_, index) => index !== optionIndex) }
                : question
        )
    );
};

const handleOptionChange = (e, questionId, optionIndex) => {
    const newLabel = e.target.value;
    setQuestions(prevQuestions =>
        prevQuestions.map(question =>
            question.questionId === questionId
                ? {
                      ...question,
                      options: question.options.map((option, index) =>
                          index === optionIndex ? { ...option, label: newLabel, value: newLabel } : option
                      ),
                  }
                : question
        )
    );
};

const handleLearningTypeChange = (e, questionId) => {
  const newAdoptionType = e.target.value;
  setQuestions((prevQuestions) =>
    prevQuestions.map((question) =>
      question.questionId === questionId ? { ...question, adoptionType: newAdoptionType } : question
    )
  );
};

const handleCorrectAnswerChange = (event, questionId) => {
  const newCorrectAnswer = event.target.value;
  setQuestions(prevQuestions =>
    prevQuestions.map(question =>
      question.questionId === questionId ? { ...question, correctAnswer: newCorrectAnswer } : question
    )
  );
};


// Assume handleCompetencyChange is defined to update competencies manually
const handleCompetencyChange = (event) => {
    const { name, value } = event.target;
    // Update state based on the name of the Select component that triggered the change
    if (name === 'area') {
        setSelectedArea(value);
    } else if (name === 'competency') {
        setSelectedCompetency(value);
    } else if (name === 'activity') {
        setActivity(value);
        // Add the selected activity as a competency to the current question
        const updatedCompetencies = [...questions.find(question => question.questionId === editingQuestionId).competencies, value];
        setQuestions(questions.map(question => question.questionId === editingQuestionId ? { ...question, competencies: updatedCompetencies } : question));
    }
};

function getCompetencies(values) {
    switch (values.framework) {
      case "RCNUM POST-OBLIGATOIRE":
        return RCNumPostObligatoireElementaryCompetences[values.area] || [];
      case "RCNUM OBLIGATOIRE":
        return RCNumObligatoireElementaryCompetences[values.area] || [];
      case "DIGCOMPEDU":
        return digCompEduElementaryCompetences[values.area] || [];
      default:
        return digCompEduElementaryCompetences[values.area] || [];
    }
  }

  function getActivities(values) {
  switch (values.framework) {
    case "RCNUM POST-OBLIGATOIRE":
      return RCNumPostObligatoireActivities[values.competency] || [];
    case "RCNUM OBLIGATOIRE":
      return RCNumObligatoireActivities[values.competency] || [];
    case "DIGCOMPEDU":
      return digCompEduActivities[values.competency] || [];
    default:
      return digCompEduActivities[values.competency] || [];
  }
};
  
const findCompetencies = async (query, framework) => {
    console.log("framework", framework)
    try {
        if (framework) { 
            const token = localStorage.getItem("token");
            const response = await axios.post(
              `${BACKEND_URL}/query-embedding-faiss`,
              { query, collectionName: framework },
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            );
        return response.data;
    } else {
      console.log("Framework is not specified. Skipping the request.");
      return null; 
    }
    } catch (error) {
        console.error('Error sending query to backend:', error);
    }
};

const removeQuestion = (workshopName, questionId) => {
    setQuestions(prevQuestions => prevQuestions.filter(question => !(question.workshop === workshopName && question.questionId === questionId)));
};

const removeImage = async (workshopName, questionId) => {
    const question = questions.find(q => q.workshop === workshopName && q.questionId === questionId);
    if (question && question.imageUrl) {
        const filename = question.imageUrl.split('/').pop(); // Extract filename from URL
        try {
            const token = localStorage.getItem("token");
            await axios.delete(`${BACKEND_URL}/deleteImage/${filename}`, {
            headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            // Update state to remove image from UI
            setQuestions(prevQuestions =>
                prevQuestions.map(q =>
                    q.workshop === workshopName && q.questionId === questionId
                    ? { ...q, imageUrl: '' }
                    : q
                )
            );
        } catch (error) {
            console.error('Error deleting the image:', error);
        }
    }
};

const removeExplanation = (workshopName, questionId) => {
  setQuestions(prevQuestions =>
    prevQuestions.map(question =>
      question.workshop === workshopName && question.questionId === questionId
        ? { ...question, explanation: '' }
        : question
    )
  );
};

const removeCompetencyFromQuestion = (question, competency) => {
  const updatedQuestions = questions.map(q => {
    if (q.question === question) {
      return {
        ...q,
        competencies: q.competencies.filter(comp => comp !== competency)
      };
    }
    return q;
  });
  setQuestions(updatedQuestions);
};

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    // Adjust the position of the switch base (knob) here if needed
  },
  '& .MuiSwitch-switchBase .MuiSwitch-thumb': {
    // Adjust the position of the thumb (the part that slides) here if needed
    backgroundColor: 'white', // This can be any color that matches your design
    '&:before': {
      // Pseudo-element for the icon
      content: '"*"',
      position: 'absolute',
      top: '60%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '1.3rem', 
      color: 'lightgrey', // Default (off) color
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
    '&:before': {
      color: 'red', // Checked (on) color
    },
  },
  '& .MuiSwitch-track': {
    // Adjust the track styling here if needed
  },
}));

const handleImageUpload = async (event, workshopName, questionId) => {
  const file = event.target.files[0];
    if (file) {
    // Check the file type
    const allowedTypes = ['image/png', 'image/gif', 'image/jpeg'];
    if (!allowedTypes.includes(file.type)) {
      alert('Only PNG, GIF, and JPG files are allowed');
      return;
    }

    // Check the file size (200KB limit)
    const maxSizeInBytes = 200 * 1024; // 200KB
    if (file.size > maxSizeInBytes) {
      alert('File size should not exceed 200KB');
      return;
    }

    const formData = new FormData();
    formData.append('image', file);

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BACKEND_URL}/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const newImageUrl = response.data.url;
      setQuestions(prevQuestions => prevQuestions.map(question => {
        // Update the condition to check for both workshopName and questionId
        if (question.questionId === questionId && question.workshop === workshopName) {
          return { ...question, imageUrl: newImageUrl };
        } else {
          return question;
        }
      }));
    } catch (error) {
      console.error('Error uploading the image:', error);
    }
  }
  event.target.value = null; // Reset the input field
};

const handleMandatoryChange = (event, questionId) => {
  setQuestions((prevQuestions) =>
    prevQuestions.map((question) =>
      question.questionId === questionId
        ? { ...question, isMandatory: event.target.checked }
        : question
    )
  );
};

const handleAutomaticEncodingChange = async (event) => {
    const isAutomaticEncodingEnabled = event.target.checked;
    setAutomaticEncoding(isAutomaticEncodingEnabled);

    // Only proceed if automatic encoding is enabled and a question is currently being edited
    if (isAutomaticEncodingEnabled && editingQuestionId !== null) {
        const updatedQuestion = questions.find(question => question.questionId === editingQuestionId);
        
        console.log("udpatedQuestion", updatedQuestion)

        if (updatedQuestion) {
            try {
                // Simulate the logic from saveEdits for updating competencies
                const competencies = await findCompetencies(updatedQuestion.question, updatedQuestion.framework);
                if (competencies && competencies.length > 0) {
                    const updatedCompetencies = competencies.map(comp => `${updatedQuestion.framework} ${comp}`);
                    // Update the question with the new competencies
                    setQuestions(prevQuestions => prevQuestions.map(question =>
                        question.questionId === editingQuestionId ? { ...question, competencies: updatedCompetencies } : question
                    ));
                    console.log("Competencies automatically updated for question:", editingQuestionId);
                } else {
                    console.log("No competencies found for automatic recoding.");
                }
            } catch (error) {
                console.error("Failed to automatically update competencies for question:", editingQuestionId, error);
            }
        }
    }
};

const handleDeleteCompetency = (questionId, competencyToDelete) => {
  setQuestions(questions.map(question => {
    if (question.questionId === questionId) {
      return {
        ...question,
        competencies: question.competencies.filter(competency => competency !== competencyToDelete),
      };
    }
    return question;
  }));
};

const sendSurveyData = async (data) => {
try {
    const token = localStorage.getItem("token");
    const response = await axios.put(
        `${BACKEND_URL}/monitorings/${currentMonitoringServerId}/assessments/${currentAssessmentServerId}/survey`,
        data,
        {
        headers: {
            Authorization: `Bearer ${token}`
        }
        }
    );
    return response.data;
} catch (error) {
    throw new Error('Failed to submit the survey: ' + error.message);
}
};

// Event handler for changing the state of helpWithAI
const handleSplitWorkshopsChange = (event) => {
    setSplitWorkshops(event.target.checked);

    if (event.target.checked === false) {
        setQuestions(prevQuestions => {
            return prevQuestions.map(question => {
                return { ...question, workshop: "" };
            });
        });
        setWorkshops([]);
    }
};

const handleReset = () => {
    setQuestions([]);
    setWorkshops([]);
    setSplitWorkshops(false);
};
    
   return (
            
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                minHeight="100vh"
                ml="10px"
                backgroundColor="white"
            >  

            <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    minHeight="5vh"
                    sx={{
                        backgroundColor: "#fff",
                        width: {
                            xs: "90vw",
                            md: "75vw",
                        },
                    }}
                >

                    <Typography ml ="10px" mb="20px" variant="h3" fontWeight="bold">
                    Evaluate {assessmentType}
                </Typography>
                    
                </Box>                                            
                
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    minHeight="80vh"
                    sx={{
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                        borderRadius: "15px",
                        backgroundColor: "#fff",
                        width: {
                            xs: "90vw",
                            md: "75vw",
                        },
                    }}
                >

                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        minHeight="80vh"
                        sx={{
                            backgroundColor: "#fff",
                            width: {
                                xs: "90vw",
                                md: "75vw",
                            },
                        }}
                    >
                        
                        <Box
                            flexDirection="column"
                            display="flex"
                            sx={{
                                backgroundColor: "#fff",
                                width: {
                                    xs: "45vw",
                                    md: "37vw",
                                },
                                height: '78vh',
                                overflowY: 'auto',
                            }}
                        >
                            <Formik
                                    initialValues={{}} 
                                    onSubmit={async (values, { setSubmitting }) => {
                                    const surveyData = {
                                        questions: questions.map((question, index) => {
                                            return {
                                            questionId: index,
                                            shortName: question.shortName,  
                                            correctAnswer: question.correctAnswer,
                                            explanation: question.explanation,
                                            imageUrl: question.imageUrl,
                                            question: question.question,
                                            workshop: question.workshop, 
                                            questionType: question.questionType,
                                            learningType: question.learningType,
                                            isMandatory: question.isMandatory,
                                            framework: question.framework,
                                            competencies: question.competencies,  
                                            choices: question.options.map(option => option.label),
                                            };
                                        }),
                                    };
                        
                                        try {
                                            await sendSurveyData(surveyData, currentMonitoringServerId, currentAssessmentServerId);
                                            navigate('/dashboard'); 
                                        } catch (error) {
                                            console.error('Failed to submit the survey:', error);
                                        } finally {
                                            setSubmitting(false);
                                        }
                                    }}
                                >
                                {({ setFieldValue, handleSubmit }) => (
                                <Form>
                                    {Object.entries(questions.reduce((acc, question) => {
                                        if (!acc[question.workshop]) {
                                            acc[question.workshop] = [];
                                        }
                                        acc[question.workshop].push(question);
                                        return acc;
                                    }, {})).map(([workshopName, workshopQuestions]) => (
                                    <div key={workshopName}>
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                            {workshopName !== "undefined" && (
                                                <Typography variant="h4" fontWeight="bold" color="rgb(102,102,102)" m="15px 10px 0px 0px"> 
                                                    {workshopName}
                                                </Typography>
                                            )}
                                        </Box>

                                        {workshopQuestions.map((question, questionIndex) => (
                                            <Box key={questionIndex} display="flex" alignItems="center">
                                                {editingQuestionId === question.questionId ? (
                                                    // Edit Interface
                                                    <Box 
                                                        component="div" 
                                                        sx={{ 
                                                            width: '80%', 
                                                            p: 2,
                                                            margin: "20px", 
                                                            //border: '1px solid black', 
                                                            borderRadius: '8px', 
                                                            backgroundColor: 'rgb(245,245,245)',
                                                            boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.1)'
                                                        }}
                                                    >
                                                        <Typography mb="20px" variant="h4" fontWeight="bold" sx={{color: "rgb(102,102,102)"}}>Edit question</Typography>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Question Title"
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        style: { backgroundColor: 'white' }
                                                                    }}
                                                                    value={question.question}
                                                                    onChange={e => setQuestions(prevQuestions =>
                                                                        prevQuestions.map(q =>
                                                                            q.questionId === question.questionId ? { ...q, question: e.target.value } : q
                                                                        )
                                                                    )}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Short Name"
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        style: { backgroundColor: 'white' }
                                                                    }}
                                                                    value={question.shortName}
                                                                    onChange={e => setQuestions(prevQuestions =>
                                                                        prevQuestions.map(q =>
                                                                            q.questionId === question.questionId ? { ...q, shortName: e.target.value } : q
                                                                        )
                                                                    )}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <FormControl fullWidth variant="outlined" sx={{ 
                                                                    '.MuiOutlinedInput-root': { 
                                                                    backgroundColor: 'white', 
                                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: 'primary.main', 
                                                                    },
                                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: 'primary.light',
                                                                    },
                                                                    } 
                                                                }}>
                                                                    <InputLabel id="learningType">Learning Type</InputLabel>
                                                                    <Select
                                                                    labelId="learningType"
                                                                    id="learningType"
                                                                    value={question.adoptionType || ''}
                                                                    label="Learning Type"
                                                                    onChange={(event) => handleLearningTypeChange(event, question.questionId)}
                                                                    >
                                                                    <MenuItem value={'Knowledge'}>Knowledge</MenuItem>
                                                                    <MenuItem value={'Skill'}>Skill</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>

                                                            {question.questionType !== 'text' && (

                                                            <>
                                                            
                                                                <Grid container spacing={2} style={{ marginLeft: '10px', marginTop: '10px' }}> 
                                                                {question.options.map((option, index) => (
                                                                    <Grid item xs={6} key={index}> 
                                                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                                                            <TextField
                                                                            fullWidth
                                                                            label={`Option ${index + 1}`}
                                                                            variant="outlined"
                                                                            InputProps={{
                                                                                style: { backgroundColor: 'white' }
                                                                            }}
                                                                            value={option.label}
                                                                            onChange={(e) => handleOptionChange(e, question.questionId, index)}
                                                                            />
                                                                            <IconButton onClick={() => removeOption(question.questionId, index)}>
                                                                            <Delete />
                                                                            </IconButton>
                                                                        </Box>
                                                                    </Grid>
                                                                ))}
                                                                </Grid> 
                                                        

                                                                <Grid container spacing={2} style={{ marginLeft: '10px', marginTop: '10px', justifyContent: "center" }}>
                                                                    <Grid item xs={6}>
                                                                        <Button variant="outlined" sx={{ backgroundColor: 'white'}} onClick={() => addOption(question.questionId)} fullWidth>Add Option</Button>
                                                                    </Grid>
                                                                </Grid>

                                                           

                                                            <Grid item xs={12}>
                                                                <FormControl fullWidth variant="outlined" sx={{ 
                                                                    '.MuiOutlinedInput-root': { 
                                                                    backgroundColor: 'white', 
                                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: 'primary.main', 
                                                                    },
                                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: 'primary.light',
                                                                    },
                                                                    } 
                                                                }}>
                                                                    <InputLabel id="selectedCorrectAnswer">Choose the correct answer</InputLabel>
                                                                    <Select
                                                                        labelId="selectedCorrectAnswer"
                                                                        id="selectedCorrectAnswer"
                                                                        value={question.correctAnswer || ''}
                                                                        label="Choose the correct answer"
                                                                        onChange={(event) => handleCorrectAnswerChange(event, question.questionId)}
                                                                        >
                                                                        {question.options.length > 0 && 
                                                                            question.options.map((option, index) => 
                                                                            (option.label !== "" && (
                                                                                <MenuItem key={index} value={option.label}>
                                                                                {option.label}
                                                                                </MenuItem>
                                                                            ))
                                                                            )
                                                                        }
                                                                        </Select>
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Explanation"
                                                                    variant="outlined"
                                                                    multiline
                                                                    rows={4}
                                                                    InputProps={{
                                                                        style: { backgroundColor: 'white' }
                                                                    }}
                                                                    value={question.explanation}
                                                                    onChange={e => setQuestions(prevQuestions =>
                                                                        prevQuestions.map(q =>
                                                                            q.questionId === question.questionId ? { ...q, explanation: e.target.value } : q
                                                                        )
                                                                    )}
                                                                />
                                                            </Grid>

                                                            </>

                                                            )}

                                                            <Grid item xs={12}>
                                                                <Box display="flex" justifyContent="center">
                                                                    <FormControlLabel
                                                                        control={<Switch checked={automaticEncoding} onChange={handleAutomaticEncodingChange} />}
                                                                        label="Automatically recode competencies"
                                                                    />
                                                                </Box>
                                                            </Grid>

                                                            <Grid item xs={12}>

                                                                <Typography sx={{ mb: "15px" }}>Add competencies manually :</Typography>
                                                                   
                                                                <FormControl fullWidth variant="outlined" sx={{ 
                                                                    '.MuiOutlinedInput-root': { 
                                                                    backgroundColor: 'white', 
                                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: 'primary.main', 
                                                                    },
                                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: 'primary.light',
                                                                    },
                                                                    } 
                                                                }}>
                                                                    <InputLabel id="selectedArea">Choose a competency area</InputLabel>
                                                                    <Select
                                                                        labelId="selectedArea"
                                                                        id="selectedArea"
                                                                        label="Choose a competency area"
                                                                        value={selectedArea}
                                                                        onChange={handleCompetencyChange}
                                                                        name="area"
                                                                        sx={{ mb: "15px" }}
                                                                    >
                                                                    {(traineeCompetenceAreas[questions.find(question => question.questionId === editingQuestionId)?.framework] || []).map((area, index) => (
                                                                        <MenuItem key={index} value={area}>{area}</MenuItem>
                                                                    ))}                                                                  
                                                                    </Select>
                                                                </FormControl>

                                                                {selectedArea && (
                                                                    <FormControl fullWidth variant="outlined" sx={{ 
                                                                    '.MuiOutlinedInput-root': { 
                                                                    backgroundColor: 'white', 
                                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: 'primary.main', 
                                                                    },
                                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: 'primary.light',
                                                                    },
                                                                    } 
                                                                }}>
                                                                        <InputLabel id="selectedCompetency">Choose a competency</InputLabel>
                                                                        <Select
                                                                            labelId="selectedCompetency"
                                                                            id="selectedCompetency"
                                                                            label="Choose a competency"
                                                                            value={selectedCompetency}
                                                                            onChange={handleCompetencyChange}
                                                                            name="competency"
                                                                            sx={{ mb: "15px" }} 
                                                                        >
                                                                        {getCompetencies({ area: selectedArea, framework: questions.find(question => question.questionId === editingQuestionId)?.framework }).map((competency, index) => (
                                                                            <MenuItem key={index} value={competency}>{competency}</MenuItem>
                                                                        ))}                                                                    
                                                                        </Select>
                                                                    </FormControl>
                                                                )}

                                                                {selectedCompetency && (
                                                                    <FormControl fullWidth variant="outlined" sx={{ 
                                                                    '.MuiOutlinedInput-root': { 
                                                                    backgroundColor: 'white', 
                                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: 'primary.main', 
                                                                    },
                                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: 'primary.light',
                                                                    },
                                                                    } 
                                                                }}>
                                                                        <InputLabel id="selectedActivity">Choose an activity</InputLabel>
                                                                        <Select
                                                                            labelId="selectedActivity"
                                                                            id="selectedActivity"
                                                                            label="Choose an activity"
                                                                            value={activity}
                                                                            onChange={handleCompetencyChange}
                                                                            name="activity"
                                                                        >
                                                                        {getActivities({ competency: selectedCompetency, framework: questions.find(question => question.questionId === editingQuestionId)?.framework }).map((activity, index) => (
                                                                            <MenuItem key={index} value={activity}>{activity}</MenuItem>  
                                                                        ))}
                                                                       </Select>
                                                                    </FormControl>
                                                                )}
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <Box
                                                                    sx={{
                                                                    display: 'flex',
                                                                    flexWrap: 'wrap',
                                                                    gap: 0.5,
                                                                    maxWidth: '100%', 
                                                                    overflow: 'hidden'
                                                                    }}
                                                                >
                                                                {question.competencies.map((competency, index) => (
                                                                    <Chip
                                                                    key={index}
                                                                    label={competency}
                                                                    onDelete={() => handleDeleteCompetency(question.questionId, competency)}
                                                                    />
                                                                ))}
                                                                </Box>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <Box display="flex" justifyContent="space-between">
                                                                     <Button
                                                                        type="button"
                                                                        variant="contained"
                                                                        onClick={() => saveEdits(question.questionId, question)}                                
                                                                        sx={{
                                                                        backgroundColor: "#F7941E",
                                                                        borderRadius: "50px",
                                                                        color: "black",
                                                                        "&:hover": {
                                                                            backgroundColor: "#D17A1D",
                                                                        },
                                                                        }}
                                                                    >
                                                                        <Typography variant="h5">Save</Typography>
                                                                    </Button>
                                                                    <Button
                                                                        type="button"
                                                                        variant="contained"
                                                                        onClick={cancelEdit}                             
                                                                        sx={{
                                                                        backgroundColor: "#F7941E",
                                                                        borderRadius: "50px",
                                                                        color: "black",
                                                                        "&:hover": {
                                                                            backgroundColor: "#D17A1D",
                                                                        },
                                                                        }}
                                                                    >
                                                                        <Typography variant="h5">Cancel</Typography>
                                                                    </Button>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                ) : (
                                                    // Existing Display
                                                    <SurveyQuestion
                                                        imageUrl={question.imageUrl}
                                                        question={question.question}
                                                        shortName={question.shortName}
                                                        fieldName={question.fieldName}
                                                        options={question.options}
                                                        type={question.questionType}
                                                        correctAnswer={question.correctAnswer}
                                                        explanation={question.explanation}
                                                        competencies={question.competencies}
                                                        removeCompetency={removeCompetencyFromQuestion}
                                                        removeImage={() => removeImage(workshopName, question.questionId)}
                                                        removeExplanation={() => removeExplanation(workshopName, question.questionId)}
                                                        setFieldValue={setFieldValue}
                                                        titleFontSize="18px"
                                                        optionFontSize="14px"
                                                        disabled={true}
                                                    />
                                                )}

                                                {/* Additional controls (up, down, delete, edit, copy, mandatory) */}
                                                <Box display="flex" flexDirection="column">
                                                    <StyledSwitch
                                                        checked={question.isMandatory}
                                                        onChange={(e) => handleMandatoryChange(e, question.questionId)}
                                                    />
                                                   <IconButton onClick={() => document.getElementById(`imageUpload-${question.questionId}`).click()}>
                                                    <AddPhotoAlternate />
                                                    <input
                                                        id={`imageUpload-${question.questionId}`}
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => handleImageUpload(e, workshopName, question.questionId)}
                                                        accept="image/*"
                                                    />
                                                    </IconButton>                                
                                                    <IconButton onClick={() => duplicateQuestion(question.questionId)}>
                                                        <ContentCopy />
                                                    </IconButton>
                                                    <IconButton onClick={() => startEditing(question.questionId)}>
                                                        <Edit />
                                                    </IconButton>
                                                    <IconButton onClick={() => removeQuestion(workshopName, question.questionId)}>
                                                        <Delete />
                                                    </IconButton>
                                                    <IconButton onClick={() => moveQuestion(workshopName, question.questionId, 'up')}>
                                                        <ArrowUpward />
                                                    </IconButton>
                                                    <IconButton onClick={() => moveQuestion(workshopName, question.questionId, 'down')}>
                                                        <ArrowDownward />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        ))}
                                    </div>
                                ))}

                                                                   
                                    <Box mt={5} display="flex" justifyContent="space-between" m="20px">
                                        {questions.length > 0 && (
                                            <>
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    onClick={handleReset}                                       
                                                    sx={{
                                                    backgroundColor: "#F7941E",
                                                    borderRadius: "50px",
                                                    color: "black",
                                                    "&:hover": {
                                                        backgroundColor: "#D17A1D",
                                                    },
                                                    width: "100px",
                                                    }}
                                                >
                                                    <Typography variant="h5">RESET</Typography>
                                                </Button>
                                                                                                
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    onClick={handleSubmit}
                                                    sx={{
                                                    backgroundColor: "#F7941E",
                                                    borderRadius: "50px",
                                                    color: "black",
                                                    "&:hover": {
                                                        backgroundColor: "#D17A1D",
                                                    },
                                                    }}
                                                >
                                                    <Typography variant="h5">VALIDATE</Typography>
                                                </Button>
                                            </>
                                        )}
                                    </Box>

                                </Form>
                                )}


                            </Formik>
                        </Box>

                        <Box
                            flexDirection="column"
                            display="flex"
                            sx={{
                                backgroundColor: "#fff",
                                width: {
                                    xs: "45vw",
                                    md: "37vw",
                                },
                                height: '78vh',
                                overflowY: 'auto',
                            }}
                        >

                            <Box
                                flexDirection="column"
                                display="flex"
                                sx={{
                                    backgroundColor: "#fff",
                                    marginTop: "20px",
                                    paddingLeft: "20px",
                                }}
                            >
                            <FormControlLabel
                            control={<Switch checked={splitWorkshops} onChange={handleSplitWorkshopsChange} />}
                            label="I have several workshops"
                            /> 
                           </Box>
    
                        {splitWorkshops && (

                        <Box
                            flexDirection="column"
                            display="flex"
                            sx={{
                                backgroundColor: "#fff"
                             }}
                        >

                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                ml="20px"
                                mt="20px"
                            >
                            <Typography mb="20px" variant="h4" fontWeight="bold" sx={{color: "rgb(102,102,102)"}}>Add a workshop</Typography>
                            
                            </Box>
                               <AddWorkshop setQuestions={setQuestions} initialQuestions={initialQuestions} workshops={workshops} setWorkshops={setWorkshops}/>
                            </Box>

                         )}

                        <Box
                            flexDirection="column"
                            display="flex"
                            sx={{
                                backgroundColor: "#fff",
                            }}
                        >

                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                ml="20px"
                                mt="20px"
                            >

                            <Typography mb="20px" variant="h4" fontWeight="bold" sx={{color: "rgb(102,102,102)"}}>Create questions</Typography>
                            
                            </Box>

                            <AddLearningQuestions 
                                setQuestions= {setQuestions}
                                questions={questions} 
                                assessmentType={assessmentType} 
                                splitWorkshops={splitWorkshops} 
                                workshops={workshops} />
                            </Box>

                        
                    </Box>
                </Box>
            </Box>
        </Box>
);

};

export default AddLearning;