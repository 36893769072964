export const predefinedQuestionsTraineeCharacteristicsIt = [
  {
    questionId: '0',
    question: "In che misura sei motivato a partecipare a questa formazione?",
    shortName: "Motivazione",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    options: [
      { value: "Per niente motivato", label: "Per niente motivato" },
      { value: "Poco motivato", label: "Poco motivato" },
      { value: "Abbastanza poco motivato", label: "Abbastanza poco motivato" },
      { value: "Né motivato né non motivato", label: "Né motivato né non motivato" },
      { value: "Abbastanza motivato", label: "Abbastanza motivato" },
      { value: "Molto motivato", label: "Molto motivato" },
      { value: "Estremamente motivato", label: "Estremamente motivato" },
    ],
  },
  {
    questionId: '1',
    question: "In che misura ritieni che la tecnologia sia benefica per il tuo lavoro?",
    shortName: "Benefici della tecnologia",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    options: [
      { value: "Per niente benefica", label: "Per niente benefica" },
      { value: "Poco benefica", label: "Poco benefica" },
      { value: "Abbastanza poco benefica", label: "Abbastanza poco benefica" },
      { value: "Né benefica né dannosa", label: "Né benefica né dannosa" },
      { value: "Abbastanza benefica", label: "Abbastanza benefica" },
      { value: "Molto benefica", label: "Molto benefica" },
      { value: "Estremamente benefica", label: "Estremamente benefica" },
    ],
  },
  {
    questionId: '2',
    question: "In che misura le opinioni degli altri influenzano la tua decisione di partecipare a questa formazione?",
    shortName: "Influenza dei pari",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    options: [
      { value: "Per niente influenzato", label: "Per niente influenzato" },
      { value: "Poco influenzato", label: "Poco influenzato" },
      { value: "Abbastanza poco influenzato", label: "Abbastanza poco influenzato" },
      { value: "Né influenzato né non influenzato", label: "Né influenzato né non influenzato" },
      { value: "Abbastanza influenzato", label: "Abbastanza influenzato" },
      { value: "Molto influenzato", label: "Molto influenzato" },
      { value: "Estremamente influenzato", label: "Estremamente influenzato" },
    ],
  },
  {
    questionId: '3',
    question: "In che misura l'iscrizione a questa formazione è stata una scelta personale piuttosto che un obbligo?",
    shortName: "Scelta personale",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    options: [
      { value: "Completamente un obbligo", label: "Completamente un obbligo" },
      { value: "Più un obbligo", label: "Più un obbligo" },
      { value: "Abbastanza un obbligo", label: "Abbastanza un obbligo" },
      { value: "Né una scelta personale né un obbligo", label: "Né una scelta personale né un obbligo" },
      { value: "Abbastanza una scelta personale", label: "Abbastanza una scelta personale" },
      { value: "Più una scelta personale", label: "Più una scelta personale" },
      { value: "Completamente una scelta personale", label: "Completamente una scelta personale" },
    ],
  },
  {
    questionId: '4',
    question: "In che misura ti senti capace di utilizzare le tecnologie informatiche per svolgere i tuoi compiti?",
    shortName: "Confidenza tecnologica",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    options: [
      { value: "Per niente capace", label: "Per niente capace" },
      { value: "Poco capace", label: "Poco capace" },
      { value: "Abbastanza poco capace", label: "Abbastanza poco capace" },
      { value: "Né capace né incapace", label: "Né capace né incapace" },
      { value: "Abbastanza capace", label: "Abbastanza capace" },
      { value: "Molto capace", label: "Molto capace" },
      { value: "Estremamente capace", label: "Estremamente capace" },
    ],
  },
];

export const predefinedQuestionsTrainingCharacteristicsIt = [

  {
    questionId: '0',
    question: "Come valuteresti la capacità del formatore di coinvolgere il pubblico?",
    shortName: "Impegno",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    options: [
      { value: "Per niente coinvolgente", label: "Per niente coinvolgente" },
      { value: "Poco coinvolgente", label: "Poco coinvolgente" },
      { value: "Abbastanza poco coinvolgente", label: "Abbastanza poco coinvolgente" },
      { value: "Né coinvolgente né noioso", label: "Né coinvolgente né noioso" },
      { value: "Abbastanza coinvolgente", label: "Abbastanza coinvolgente" },
      { value: "Molto coinvolgente", label: "Molto coinvolgente" },
      { value: "Estremamente coinvolgente", label: "Estremamente coinvolgente" }
    ]
  },

  {
    questionId: '1',
    question: "Come valuteresti la capacità del formatore di gestire il tempo?",
    shortName: "Gestione del tempo",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    options: [
      { value: "Gestione del tempo molto scarsa", label: "Gestione del tempo molto scarsa" },
      { value: "Gestione del tempo scarsa", label: "Gestione del tempo scarsa" },
      { value: "Gestione del tempo abbastanza scarsa", label: "Gestione del tempo abbastanza scarsa" },
      { value: "Gestione del tempo media", label: "Gestione del tempo media" },
      { value: "Buona gestione del tempo", label: "Buona gestione del tempo" },
      { value: "Ottima gestione del tempo", label: "Ottima gestione del tempo" },
      { value: "Eccellente gestione del tempo", label: "Eccellente gestione del tempo" }
    ]
  },

  {
    questionId: '2',
    question: "Come valuteresti la rilevanza dei metodi di insegnamento del formatore?",
    shortName: "Metodi di insegnamento",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    options: [
      { value: "Per niente adatti", label: "Per niente adatti" },
      { value: "Poco adatti", label: "Poco adatti" },
      { value: "Abbastanza inadeguati", label: "Abbastanza inadeguati" },
      { value: "Neutro", label: "Né adatti né inadeguati" },
      { value: "Abbastanza adatti", label: "Abbastanza adatti" },
      { value: "Molto adatti", label: "Molto adatti" },
      { value: "Estremamente adatti", label: "Estremamente adatti" }
    ]
  },

  {
    questionId: '3',
    question: "Come valuteresti la rilevanza dell'ambiente di apprendimento?",
    shortName: "Ambiente di apprendimento",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    options: [
      { value: "Per niente adatto", label: "Per niente adatto" },
      { value: "Poco adatto", label: "Poco adatto" },
      { value: "Abbastanza inadeguato", label: "Abbastanza inadeguato" },
      { value: "Neutro", label: "Né adatto né inadeguato" },
      { value: "Abbastanza adatto", label: "Abbastanza adatto" },
      { value: "Molto adatto", label: "Molto adatto" },
      { value: "Estremamente adatto", label: "Estremamente adatto" }
    ]
  },

  {
    questionId: '4',
    question: "Come valuteresti la rilevanza degli strumenti pedagogici?",
    shortName: "Rilevanza degli strumenti",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    options: [
      { value: "Per niente adatti", label: "Per niente adatti" },
      { value: "Poco adatti", label: "Poco adatti" },
      { value: "Abbastanza inadeguati", label: "Abbastanza inadeguati" },
      { value: "Neutro", label: "Né adatti né inadeguati" },
      { value: "Abbastanza adatti", label: "Abbastanza adatti" },
      { value: "Molto adatti", label: "Molto adatti" },
      { value: "Estremamente adatti", label: "Estremamente adatti" }
    ]
  },

  {
    questionId: '5',
    question: "Come valuteresti la capacità del formatore di adattare i suoi metodi di insegnamento al pubblico?",
    shortName: "Pedagogia",
    workshop: "",
    fieldName: "q6",
    questionType: "radio-ordered",
    options: [
      { value: "Per niente adatto", label: "Per niente adatto" },
      { value: "Raramente adatto", label: "Raramente adatto" },
      { value: "Abbastanza inadeguato", label: "Abbastanza inadeguato" },
      { value: "Neutro", label: "Né adatto né inadeguato" },
      { value: "Abbastanza adatto", label: "Abbastanza adatto" },
      { value: "Solitamente adatto", label: "Solitamente adatto" },
      { value: "Sempre adatto", label: "Sempre adatto" }
    ]
  }
]

export const predefinedQuestionsOrganizationalConditionsIt = [
  {
    questionId: '0',
    question: "A quale livello stimi la tua necessità di attrezzature necessarie per integrare efficacemente le risorse formative nella tua pratica?",
    shortName: "Risorse materiali",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    organizationalType: "Risorse materiali",
    options: [
      { "value": "Molto alto", "label": "Molto alto" },
      { "value": "Alto", "label": "Alto" },
      { "value": "Piuttosto alto", "label": "Piuttosto alto" },
      { "value": "Medio", "label": "Medio" },
      { "value": "Piuttosto basso", "label": "Piuttosto basso" },
      { "value": "Basso", "label": "Basso" },
      { "value": "Molto basso", "label": "Molto basso" }
    ]
  },
  {
    questionId: '1',
    question: "A quale livello stimi la tua necessità di tempo disponibile per integrare le risorse formative nella tua pratica?",
    shortName: "Risorse temporali",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    organizationalType: "Risorse temporali",
    options: [
      { "value": "Molto alto", "label": "Molto alto" },
      { "value": "Alto", "label": "Alto" },
      { "value": "Piuttosto alto", "label": "Piuttosto alto" },
      { "value": "Medio", "label": "Medio" },
      { "value": "Piuttosto basso", "label": "Piuttosto basso" },
      { "value": "Basso", "label": "Basso" },
      { "value": "Molto basso", "label": "Molto basso" }
    ]
  },
  {
    questionId: '2',
    question: "A quale livello stimi la tua necessità di supporto dalla tua gerarchia per integrare le risorse formative nella tua pratica?",
    shortName: "Supporto gerarchico",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    organizationalType: "Supporto gerarchico",
    options: [
      { "value": "Molto alto", "label": "Molto alto" },
      { "value": "Alto", "label": "Alto" },
      { "value": "Piuttosto alto", "label": "Piuttosto alto" },
      { "value": "Medio", "label": "Medio" },
      { "value": "Piuttosto basso", "label": "Piuttosto basso" },
      { "value": "Basso", "label": "Basso" },
      { "value": "Molto basso", "label": "Molto basso" }
    ]
  },
  {
    questionId: '3',
    question: "A quale livello stimi la tua necessità di supporto dai tuoi colleghi per integrare le risorse formative nella tua pratica?",
    shortName: "Supporto dei colleghi",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    organizationalType: "Supporto dei colleghi",
    options: [
      { "value": "Molto alto", "label": "Molto alto" },
      { "value": "Alto", "label": "Alto" },
      { "value": "Piuttosto alto", "label": "Piuttosto alto" },
      { "value": "Medio", "label": "Medio" },
      { "value": "Piuttosto basso", "label": "Piuttosto basso" },
      { "value": "Basso", "label": "Basso" },
      { "value": "Molto basso", "label": "Molto basso" }
    ]
  },
  {
    questionId: '4',
    question: "A quale livello stimi la tua necessità di supporto tecnico per integrare le risorse formative nella tua pratica?",
    shortName: "Supporto tecnico",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    organizationalType: "Supporto tecnico",
    options: [
      { "value": "Molto alto", "label": "Molto alto" },
      { "value": "Alto", "label": "Alto" },
      { "value": "Piuttosto alto", "label": "Piuttosto alto" },
      { "value": "Medio", "label": "Medio" },
      { "value": "Piuttosto basso", "label": "Piuttosto basso" },
      { "value": "Basso", "label": "Basso" },
      { "value": "Molto basso", "label": "Molto basso" }
    ]
  },
  {
    questionId: '5',
    question: "A quale livello stimi la tua necessità di mentorship (ad esempio, con un collega più esperto) per integrare le risorse formative nella tua pratica?",
    shortName: "Mentorship",
    workshop: "",
    fieldName: "q6",
    questionType: "radio-ordered",
    organizationalType: "Mentorship",
    options: [
      { "value": "Molto alto", "label": "Molto alto" },
      { "value": "Alto", "label": "Alto" },
      { "value": "Piuttosto alto", "label": "Piuttosto alto" },
      { "value": "Medio", "label": "Medio" },
      { "value": "Piuttosto basso", "label": "Piuttosto basso" },
      { "value": "Basso", "label": "Basso" },
      { "value": "Molto basso", "label": "Molto basso" }
    ]
  }
]

export const predefinedQuestionsImmediateReactionsIt = [
  {
    questionId: '0',
    question: "Quanto ti è piaciuto il workshop?",
    shortName: "Interesse Percepito",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    options: [
      { value: "Non mi è piaciuto affatto", label: "Non mi è piaciuto affatto" },
      { value: "Non molto piacevole", label: "Non molto piacevole" },
      { value: "Abbastanza piacevole", label: "Abbastanza piacevole" },
      { value: "Neutrale", label: "Neutrale" },
      { value: "Piuttosto piacevole", label: "Piuttosto piacevole" },
      { value: "Molto piacevole", label: "Molto piacevole" },
      { value: "Estremamente piacevole", label: "Estremamente piacevole" }
    ]
  },
  {
    questionId: '1',
    question: "Quanto hai trovato utile il workshop?",
    shortName: "Utilità Percepita",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    options: [
      { value: "Per niente utile", label: "Per niente utile" },
      { value: "Leggermente utile", label: "Leggermente utile" },
      { value: "Moderatamente utile", label: "Moderatamente utile" },
      { value: "Neutrale", label: "Neutrale" },
      { value: "Piuttosto utile", label: "Piuttosto utile" },
      { value: "Molto utile", label: "Molto utile" },
      { value: "Estremamente utile", label: "Estremamente utile" }
    ]
  },
  {
    questionId: '2',
    question: "Quanto ti risulta facile integrare ciò che hai visto in un'attività d'aula?",
    shortName: "Facilità di Uso Percepita",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    options: [
      { value: "Per niente facile", label: "Per niente facile" },
      { value: "Abbastanza difficile", label: "Abbastanza difficile" },
      { value: "Moderatamente facile", label: "Moderatamente facile" },
      { value: "Neutrale", label: "Neutrale" },
      { value: "Piuttosto facile", label: "Piuttosto facile" },
      { value: "Molto facile", label: "Molto facile" },
      { value: "Estremamente facile", label: "Estremamente facile" }
    ]
  },
  {
    questionId: '3',
    question: "Quanto ti senti sicuro nell'integrare ciò che hai visto in un'attività d'aula?",
    shortName: "Auto-efficacia",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    options: [
      { value: "Per niente sicuro", label: "Per niente sicuro" },
      { value: "Leggermente sicuro", label: "Leggermente sicuro" },
      { value: "Moderatamente sicuro", label: "Moderatamente sicuro" },
      { value: "Neutrale", label: "Neutrale" },
      { value: "Piuttosto sicuro", label: "Piuttosto sicuro" },
      { value: "Molto sicuro", label: "Molto sicuro" },
      { value: "Estremamente sicuro", label: "Estremamente sicuro" }
    ]
  },
  {
    questionId: '4',
    question: "In che misura intendi utilizzare ciò che hai visto per condurre un'attività d'aula?",
    shortName: "Intenzione Comportamentale",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    options: [
      { value: "Non intendo utilizzarlo affatto", label: "Non intendo utilizzarlo affatto" },
      { value: "Intendo utilizzarlo leggermente", label: "Intendo utilizzarlo leggermente" },
      { value: "Intendo utilizzarlo moderatamente", label: "Intendo utilizzarlo moderatamente" },
      { value: "Neutrale", label: "Neutrale" },
      { value: "Intendo utilizzarlo abbastanza", label: "Intendo utilizzarlo abbastanza" },
      { value: "Intendo utilizzarlo molto", label: "Intendo utilizzarlo molto" },
      { value: "Intendo utilizzarlo decisamente", label: "Intendo utilizzarlo decisamente" }
    ]
  }
];

export const predefinedQuestionsSustainabilityConditionsIt = [
  {
    questionId: '0',
    question: "Quanto è compatibile il materiale presentato con i vostri metodi di insegnamento precedenti?",
    shortName: "Compatibilità",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    options: [
      { value: "Per niente compatibile", label: "Per niente compatibile" },
      { value: "Poco compatibile", label: "Poco compatibile" },
      { value: "Moderatamente compatibile", label: "Moderatamente compatibile" },
      { value: "Neutro", label: "Neutro" },
      { value: "Abbastanza compatibile", label: "Abbastanza compatibile" },
      { value: "Molto compatibile", label: "Molto compatibile" },
      { value: "Estremamente compatibile", label: "Estremamente compatibile" }
    ]
  },
  {
    questionId: '1',
    question: "Come valutate i progressi dei vostri studenti con i nuovi materiali?",
    shortName: "Progresso degli studenti",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    options: [
      { value: "Peggiorato notevolmente", label: "Peggiorato notevolmente" },
      { value: "Peggiorato leggermente", label: "Peggiorato leggermente" },
      { value: "Rimasto invariato", label: "Rimasto invariato" },
      { value: "Migliorato leggermente", label: "Migliorato leggermente" },
      { value: "Migliorato moderatamente", label: "Migliorato moderatamente" },
      { value: "Migliorato notevolmente", label: "Migliorato notevolmente" }
    ]
  },
  {
    questionId: '2',
    question: "Come valutate la vostra fiducia nell'uso delle nuove risorse?",
    shortName: "Autoefficacia",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    options: [
      { value: "Per niente sicuro", label: "Per niente sicuro" },
      { value: "Poco sicuro", label: "Poco sicuro" },
      { value: "Moderatamente sicuro", label: "Moderatamente sicuro" },
      { value: "Neutro", label: "Neutro" },
      { value: "Abbastanza sicuro", label: "Abbastanza sicuro" },
      { value: "Molto sicuro", label: "Molto sicuro" },
      { value: "Estremamente sicuro", label: "Estremamente sicuro" }
    ]
  },
  {
    questionId: '3',
    question: "Quanto è mentalmente impegnativo implementare le nuove risorse?",
    shortName: "Carico cognitivo",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    options: [
      { value: "Estremamente impegnativo", label: "Estremamente impegnativo" },
      { value: "Molto impegnativo", label: "Molto impegnativo" },
      { value: "Abbastanza impegnativo", label: "Abbastanza impegnativo" },
      { value: "Moderatamente impegnativo", label: "Moderatamente impegnativo" },
      { value: "Poco impegnativo", label: "Poco impegnativo" },
      { value: "Per niente impegnativo", label: "Per niente impegnativo" }
    ]
  },
  {
    questionId: '4',
    question: "In che misura le nuove risorse aumentano il vostro carico di lavoro?",
    shortName: "Impatto sul carico di lavoro",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    options: [
      { value: "Aumentato massicciamente", label: "Aumentato massicciamente" },
      { value: "Aumentato notevolmente", label: "Aumentato notevolmente" },
      { value: "Aumentato leggermente", label: "Aumentato leggermente" },
      { value: "Neutro", label: "Neutro" },
      { value: "Diminuito moderatamente", label: "Diminuito moderatamente" },
      { value: "Diminuito leggermente", label: "Diminuito leggermente" },
      { value: "Per niente aumentato", label: "Per niente aumentato" },
    ]
  },
  {
    questionId: '5',
    question: "Quanto tempo è passato prima che implementaste i nuovi materiali dopo la formazione?",
    shortName: "Tempo di implementazione",
    workshop: "",
    fieldName: "q6",
    questionType: "radio-ordered",
    options: [
      { value: "Non ancora implementato", label: "Non ancora implementato" },
      { value: "Più di 3 mesi", label: "Più di 3 mesi" },
      { value: "Entro 3 mesi", label: "Entro 3 mesi" },
      { value: "Entro un mese", label: "Entro un mese" },
      { value: "Entro una settimana", label: "Entro una settimana" },
      { value: "Immediatamente", label: "Immediatamente" },
    ]
  }
];

export const predefinedQuestionsStudentCharacteristicsIt = [
  {
    questionId: '0',
    question: "A quale fascia d'età appartieni?",
    shortName: "Età",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-unordered",
    options: [
      { value: "Meno di 10 anni", label: "Meno di 10 anni" },
      { value: "10-15 anni", label: "10-15 anni" },
      { value: "16-20 anni", label: "16-20 anni" },
      { value: "21-25 anni", label: "21-25 anni" },
      { value: "26-30 anni", label: "26-30 anni" },
      { value: "Oltre 31 anni", label: "Oltre 31 anni" }
    ]
  },
  {
    questionId: '1',
    question: "Qual è il tuo principale campo di studio o interesse?",
    shortName: "Specializzazione",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-unordered",
    options: [
      { value: "Scienze naturali", label: "Scienze naturali" },
      { value: "Arte", label: "Arte" },
      { value: "Tecnologia", label: "Tecnologia" },
      { value: "Matematica", label: "Matematica" },
      { value: "Scienze umanistiche", label: "Scienze umanistiche" },
      { value: "Altro", label: "Altro" }
    ]
  },
  {
    questionId: '2',
    question: "A quale livello di istruzione ti trovi attualmente?",
    shortName: "Livello di istruzione",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-unordered",
    options: [
      { value: "Scuola elementare", label: "Scuola elementare" },
      { value: "Scuola secondaria", label: "Scuola secondaria" },
      { value: "Laurea triennale", label: "Laurea triennale" },
      { value: "Laurea magistrale/Dottorato", label: "Laurea magistrale/Dottorato" }
    ]
  },
  {
    questionId: '3',
    question: "Quanto spesso usi strumenti digitali per studiare o nel tempo libero?",
    shortName: "Uso di strumenti digitali",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-unordered",
    options: [
      { value: "Mai", label: "Mai" },
      { value: "Raramente", label: "Raramente" },
      { value: "A volte", label: "A volte" },
      { value: "Spesso", label: "Spesso" },
      { value: "Sempre", label: "Sempre" }
    ]
  },
  {
    questionId: '4',
    question: "Credi che certi generi siano più adatti all'informatica?",
    shortName: "Stereotipi di genere",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-unordered",
    options: [
      { value: "Sì, gli uomini", label: "Sì, gli uomini" },
      { value: "Sì, le donne", label: "Sì, le donne" },
      { value: "No, tutti i generi sono ugualmente adatti", label: "No, tutti i generi sono ugualmente adatti" },
      { value: "Non ho un'opinione in merito", label: "Non ho un'opinione in merito" }
    ]
  }
];
