import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Radio, Typography, Box } from '@mui/material';
import { Field } from 'formik';
import './MatrixQuestion.css';

const MatrixQuestion = ({
  items,
  options,
  fieldName,
  selectedAnswers,
  handleAnswerSelect,
  isOptionDisabled,
  matrixFullWidth = false
}) => {
  const getFontSize = () => {
    const baseSize = 18; // base font size
    const reductionFactor = 0.8; // factor to reduce the size
    const columns = options.length;
    return `${Math.max(baseSize * reductionFactor ** (columns - 1), 12)}px`; // ensure minimum font size of 12px
  };

  const fontSize = getFontSize();

  return (
    <Box sx={{ overflowX: 'auto', width: '100%', maxWidth: matrixFullWidth ? '100%' : '580px' }}>
      <Table className="table-container" sx={{ minWidth: '600px' }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '220px' }}></TableCell>
            {options.map((option, index) => (
              <TableCell key={index} align="center">
                <Typography variant="body1" style={{ fontSize }}>{option.label}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, itemIndex) => (
            <TableRow key={itemIndex}>
              <TableCell style={{ width: '220px' }}>
                <Typography variant="body1" style={{ fontSize }}>{item.label}</Typography>
              </TableCell>
              {options.map((option, optionIndex) => (
                <TableCell key={optionIndex} align="center">
                  <Field
                    as={Radio}
                    type="radio"
                    name={`${fieldName}-${itemIndex}`}
                    value={option.value}
                    checked={selectedAnswers[itemIndex] === option.value}
                    onChange={() => handleAnswerSelect(itemIndex, option.value)}
                    disabled={isOptionDisabled}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default MatrixQuestion;