import { Typography, Box, IconButton, Select, MenuItem, Avatar, Popover, Button } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Topbar = ({ title, logo, boxShadow }) => {
  const navigate = useNavigate();
  const [openLogoutPopover, setOpenLogoutPopover] = useState(false); // State for logout popover
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for popover
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en');

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('selectedLanguage', language); 
    setSelectedLanguage(language);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  const handleOpenLogoutPopover = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenLogoutPopover(true);
  };

  const handleCloseLogoutPopover = () => {
    setOpenLogoutPopover(false);
  };

  let initials = ""; // default initials
  let isLoggedIn = false; // track if user is logged in

  let token = localStorage.getItem('token');
  
    // Extract and capitalize initials from user's name
  if (token) {
    const parts = token.split('.');
    const payload = parts[1];
    const decodedPayload = atob(payload); // Note: atob is deprecated and should be replaced in secure contexts
    const payloadObject = JSON.parse(decodedPayload);

    if (payloadObject && payloadObject.firstName && payloadObject.lastName) {
      // Ensure the first letter is capitalized
      const firstNameInitial = payloadObject.firstName.charAt(0).toUpperCase();
      const lastNameInitial = payloadObject.lastName.charAt(0).toUpperCase();
      initials = `${firstNameInitial}${lastNameInitial}`;
      isLoggedIn = true;
    }
  }

  return (
    <Box display="flex"
     justifyContent="space-between"
     alignItems="center"
     p={2}
      style={{
    boxShadow: boxShadow,
  }}>

      <Box mt={2}>
        {/* LOGO  */}
        <img
        alt=""
        src={logo}
        style={{
            cursor: "pointer", 
          borderRadius: "1%", 
          maxWidth: "300px",
          minWidth: "300px",
          marginBottom: "20px"
        }}
        />
      
      {/* TITLE */}
      <Typography variant="h3" fontWeight="bold"> { title } </Typography>

      </Box>

      {/* ICONS */}
      <Box display="flex" alignItems="center">

        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton> */}
        {isLoggedIn ? (
          <React.Fragment>
            <IconButton onClick={handleOpenLogoutPopover}>
              <Box display="flex" flexDirection="row">
                <Avatar>{initials}</Avatar>
              </Box>
            </IconButton>
            <Popover
              open={openLogoutPopover}
              onClose={handleCloseLogoutPopover}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2}>
                <Box display="flex" justifyContent="flex-end" flexDirection="column">
                  <Button onClick={handleLogout} color="error">Se déconnecter</Button>
                </Box>
              </Box>
            </Popover>
          </React.Fragment>
        ) : (
          <IconButton onClick={() => navigate('/signin')}>
            <Box display="flex" flexDirection="row">
              <PersonOutlinedIcon style={{ marginRight: '8px' }} />
              <Typography style={{ marginRight: '8px' }}>Se connecter</Typography>
            </Box>
          </IconButton>
        )}

         <Select
          value={selectedLanguage}
          onChange={(e) => {
              // Handle the language change event
              handleLanguageChange(e.target.value);
          }}
          size="small"
          sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
        >
          <MenuItem value="en">
            <span className="fi fi-gb"></span>      
          </MenuItem>
          <MenuItem value="fr">
            <span className="fi fi-fr"></span>    
          </MenuItem>
          <MenuItem value="de">
             <span className="fi fi-de"></span>      
         </MenuItem>
         <MenuItem value="it">
           <span className="fi fi-it"></span>    
         </MenuItem>
        </Select>
        
      </Box>
    </Box>
  );
};

export default Topbar;
