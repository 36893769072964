/**
 * Main Function:
 * - The Logbooks component serves as a centralized interface for users to view and manage their training activity logs. It is designed to provide a comprehensive overview of logged activities related to various training sessions or educational courses. This component integrates functionalities for displaying activity timelines and adding new logs, enhancing the user's ability to track and reflect on their training progress.
 * 
 * Frontend Operations:
 * - Leverages React's useState hook for state management, specifically for handling the array of logs, the current monitoring ID, and the current user ID. This allows for dynamic updates and interactions within the component based on user actions.
 * - Incorporates a Sidebar and Topbar for a consistent navigation and interface layout, ensuring users have easy access to other parts of the application.
 * - Utilizes Material-UI components to construct the user interface, providing a visually appealing and user-friendly experience. This includes the use of Typography for text elements and Box for layout and styling purposes.
 * - Integrates custom components such as CustomTimeline for displaying the logs in a chronological timeline format and AddLog for adding new logs to the timeline. These components work together to offer a seamless user experience for managing training logs.
 * 
 * Notable Features:
 * - The component's layout is designed to support easy navigation between viewing logs and adding new logs, facilitating a fluid workflow for users documenting their training activities.
 * - Dynamic data handling capabilities enable the component to adapt to changes in logs, user IDs, and monitoring IDs, ensuring the display and functionalities remain current with user interactions.
 * - Provides a scalable framework for extending the logbook functionalities, such as filtering logs by date, activity type, or other criteria, offering potential for future enhancements.
 * 
 */

import React, { useState } from 'react';
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { Typography, Box } from "@mui/material";
import CustomTimeline from "../../components/CustomTimeline";
import AddLog from "../../components/AddLog";

const Logbooks = () => {
  
const [logs, setLogs] = useState([]);
const [currentMonitoringId, setCurrentMonitoringId] = useState('');
const [currentUserId, setCurrentUserId] = useState('');

  return (
    <Box display="flex" style={{ height: '100vh', overflow: 'auto' }}>
      <Sidebar />
      <Box  display="flex" flex="1" flexDirection="column">
        <Box mt="10px" ml="10px">
          <Topbar title="My Logbooks" />
        </Box>
        <Box display="flex" flexWrap="nowrap">
          <Box display="flex" flexDirection="column" flex="1" maxWidth="50vw" maxHeight="80vh" m="20px" alignItems="center" style={{overflowY: 'auto'}}>
            <Typography mt="15px" variant="h3">My Training Activity</Typography>
            <CustomTimeline
             logs={logs}
             setLogs={setLogs}
             currentMonitoringId={currentMonitoringId}
             currentUserId={currentUserId}
              />
          </Box>
          <Box  flex="1">
            <AddLog 
             logs={logs}
             setLogs={setLogs}
             currentMonitoringId={currentMonitoringId}
             setCurrentMonitoringId={setCurrentMonitoringId}
             currentUserId={currentUserId}
             setCurrentUserId={setCurrentUserId}  />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Logbooks;
