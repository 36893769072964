/**
 * Main Function:
 * - The Dashboard component serves as the central hub for users to manage monitorings and assessments within a system designed to create and track educational or training assessments. It offers an overview of all monitorings and their associated assessments, providing functionalities such as viewing, editing, and sharing assessments.
 * 
 */

import React, { useState, useEffect, useRef } from "react";
import { InputLabel, Box, Button, Typography, Menu, MenuItem, IconButton, Select, Checkbox, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Sidebar from "../../scenes/global/Sidebar";
import Topbar from "../../scenes/global/Topbar";
import Footer from "../../scenes/global/Footer";
import { DataGrid } from '@mui/x-data-grid';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TextField from '@mui/material/TextField';
import AddIcon from "@mui/icons-material/Add";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SwipeRightAltIcon from '@mui/icons-material/SwipeRightAlt';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import jwt_decode from "jwt-decode";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import {QRCodeCanvas} from 'qrcode.react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { FRONTEND_URL, BACKEND_URL } from "../../config";
import { saveAs } from 'file-saver';

const ITEM_HEIGHT = 48;

console.log(FRONTEND_URL, BACKEND_URL)

const Dashboard = () => {

  const { t } = useTranslation('translation');
  const [lng, setLng] = useState(i18n.language);

  const [monitorings, setMonitorings] = useState([]);
  const [selectedMonitoring, setSelectedMonitoring] = useState(null);

  // A table where each value is a table with all the assessments for a given monitoring
  const [assessmentsTable, setAssessmentsTable] = useState([]);
  
  // Current Ids
  const [currentMonitoringServerId, setCurrentMonitoringServerId] = useState(null)
  const [currentAssessmentServerId, setCurrentAssessmentServerId] = useState(null)
  const [selectedAssessmentsServerIds, setSelectedAssessmentsServerIds] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(null)
  
  // Sharing assessments
  const [isOpen, setIsOpen] = useState(false); // isOpen true is used to open an assessment -> get the QR code
  const qrCodeRef = useRef(null); // the qrCodeReference
  const largeQRCodeRef = useRef(null); // the largeQrCodeReference
  const [openAssessmentsCount, setOpenAssessmentsCount] = useState(0);
  const [selectedAssessmentIds, setSelectedAssessmentIds] = useState([]);
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [generatedCode, setGeneratedCode] = useState('');
  const [isLinked, setIsLinked] = useState(true);

  const selectedAssessmentTable = selectedMonitoring
    ? assessmentsTable[selectedMonitoring.id - 1]
    : null;

  useEffect(() => {
    const handleLanguageChange = (newLng) => {
      setLng(newLng);
    };

    // Attach the event listener
    i18n.on('languageChanged', handleLanguageChange);

    // Clean up on component unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

useEffect(() => {
  const fetchMonitoringsAndAssessments = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log('No token found');
      return;
    }
    const decodedToken = jwt_decode(token);
    setCurrentUserId(decodedToken._id);

    try {
      const response = await axios.get(`${BACKEND_URL}/monitorings/${decodedToken._id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
      console.log("response", response.data);

      const transformMonitorings = (monitorings) => {
        return monitorings.map((item, index) => ({
          ...item,
          id: index + 1,
          creationDate: new Date(item.creationDate),
          lastModification: item.lastModificationDate ? new Date(item.lastModificationDate) : new Date(item.creationDate),
          options: ['Delete'],
          assessments: item.assessments.map((assessment, idx) => ({
            ...assessment,
            id: idx + 1,  
            _id: assessment._id,
          })),
        }));
      };

      const monitoringsArray = response.data.monitorings;
      const transformedMonitorings = transformMonitorings(monitoringsArray);
      setMonitorings(transformedMonitorings);

      // Determine the monitoring with the latest modification date
      let latestMonitoring = null;
      if (transformedMonitorings.length > 0) {
        latestMonitoring = transformedMonitorings.reduce((latest, current) => {
          return new Date(latest.lastModification) > new Date(current.lastModification) ? latest : current;
        });
      }

      // Set the selectedMonitoring to the latest
      setSelectedMonitoring(latestMonitoring);

      // Update currentMonitoringServerId with the server ID of the latest monitoring
      {latestMonitoring && (
        setCurrentMonitoringServerId(latestMonitoring._id)
      )}

      // Handle assessments data
      const assessments = transformedMonitorings.reduce((acc, monitoring, index) => {
        const transformedAssessments = monitoring.assessments.map((assessment, idx) => {
          let options;
          switch (assessment.status) {
            case 'Draft':
              options = ['Edit', 'Preview', 'Open', 'Make a copy', 'Delete'];
              break;
            case 'Open':
              options = ['Close','Preview','Edit','Make a copy', 'Delete'];
              break;
            case 'Close':
              options = ['Open', 'Make a copy', 'Delete'];
              break;
          }

          return {
            ...assessment,
            id: idx + 1,
            creationDate: new Date(assessment.creationDate),
            lastModification: assessment.lastModificationDate ? new Date(assessment.lastModificationDate) : new Date(assessment.creationDate),
            options: options,
          };
        });

        // Using the index to match with monitoring id
        acc.push({ id: index + 1, data: transformedAssessments });
        return acc;
      }, []);

      // Set assessments data into the state
      setAssessmentsTable(assessments);

      // Set the number of open assessments to display or not the qr code and links for all assessments
      const allAssessments = assessments.reduce((acc, curr) => acc.concat(curr.data), []);
      const count = allAssessments.filter(assessment => assessment.status === 'Open').length;
      setOpenAssessmentsCount(count);

    } catch (error) {
      console.error(error);
    }
  }
  fetchMonitoringsAndAssessments();
}, []);


useEffect(() => {
  if (selectedMonitoring) {
    setSelectedAssessmentsServerIds([]);
    setSelectedAssessmentIds([]);
  }
}, [selectedMonitoring]);



  const updateAssessmentsTable = async (id, newAssessments) => {
  setAssessmentsTable((prevTables) =>
    prevTables.map((table) =>
      table.id === id ? { ...table, data: newAssessments } : table
    )
  );
};

const generateQRCodeValue = () => {
    try {
        if (selectedMonitoring && selectedMonitoring.assessments && selectedAssessmentIds.length > 0) {
            const filteredAssessmentIds = selectedAssessmentIds.map(id => {
                const assessment = selectedMonitoring.assessments.find(a => a.id === id);
                return assessment ? assessment._id : null;
            }).filter(id => id !== null);

            const assessmentsQuery = filteredAssessmentIds.map(id => `assessment[]=${id}`).join('&');
            const token = localStorage.getItem("token");
            const decodedToken = jwt_decode(token);
            const sandbox = decodedToken.sandbox;

            return `${FRONTEND_URL}/completeSurvey?user=${currentUserId}&monitoring=${currentMonitoringServerId}&${assessmentsQuery}&link=${isLinked}&lng=${lng}&sandbox=${sandbox}`;
        }
    } catch (error) {
        console.error('Error generating QR code value:', error);
        return null;
    }
};

const handleDownloadPaperVersion = async () => {
  try {
    if (selectedMonitoring && selectedMonitoring.assessments && selectedAssessmentIds.length > 0) {
      const filteredAssessmentIds = selectedAssessmentIds.map(id => {
        const assessment = selectedMonitoring.assessments.find(a => a.id === id);
        return assessment ? assessment._id : null;
      }).filter(id => id !== null);

      const token = localStorage.getItem("token");
      const decodedToken = jwt_decode(token);
      const sandbox = decodedToken.sandbox;

      const response = await axios.post(`${BACKEND_URL}/export/pdfPaperVersion`, {
        currentUserId: currentUserId,
        monitoringId: currentMonitoringServerId,
        assessmentIds: filteredAssessmentIds,
        lng: lng,
        link: isLinked,
        sandbox: sandbox
      }, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const file = new Blob([response.data], { type: 'application/pdf' });

      saveAs(file, 'report.pdf');
    }
  } catch (error) {
    console.error('Error exporting PDF:', error);
  }
};


// Generate a consistent 6-character alphanumeric code based on the assessment IDs
const generateUniqueAlphanumericCode = () => {
  try {
    if (selectedMonitoring && selectedMonitoring.assessments && selectedAssessmentIds.length > 0) {
      const filteredAssessmentIds = selectedMonitoring.assessments
        .filter(assessment => selectedAssessmentIds.includes(assessment.id))
        .map(filteredAssessment => filteredAssessment._id);

      console.log("selectedMonitoring", selectedMonitoring.assessments);


      console.log("filteredAssessmentIds", filteredAssessmentIds);

      if (filteredAssessmentIds.length === 0) {
        console.error("No assessments matched the selected IDs.");
        return '';
      }

      // Sort and join assessment IDs into one string
      const concatenatedIds = filteredAssessmentIds.sort().join('');

      // Calculate checksum by summing character codes and incorporating a multiplier
      let checksum = 0;
      for (let i = 0; i < concatenatedIds.length; i++) {
        checksum = (checksum * 31 + concatenatedIds.charCodeAt(i)) & 0xFFFFFFFF; // Hash-like calculation
      }

      // Convert checksum to base-36 for a short, alphanumeric representation
      const base36String = (checksum >>> 0).toString(36).toUpperCase();

      // Ensure it's six characters long by repeating characters or truncating
      if (base36String.length < 6) {
        return base36String.padEnd(6, base36String);
      }
      return base36String.slice(0, 6);
    }
    return 'error';
 } catch (error) {
    console.error('Error generating code:', error);
  }
};

// Toggle visibility of the code and generate a new one if it is not visible
useEffect(() => {
  setGeneratedCode(generateUniqueAlphanumericCode());
  setIsCodeVisible(false);

}, [selectedAssessmentIds]);

// Button click handler to toggle visibility
const handleToggleCodeVisibility = () => {
  setIsCodeVisible(!isCodeVisible);
};

const handleDownloadQR = () => {
    if (qrCodeRef.current) {
      const canvas = qrCodeRef.current.querySelector('canvas');
      if (canvas) {
        const image = canvas.toDataURL("image/png");
        const link = document.createElement('a');
        link.download = "QRCode.png";
        link.href = image;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  //Copy the QR code to the clipboard
  
  const handleCopyToClipboard = () => {
    const qrValue = generateQRCodeValue();
    navigator.clipboard.writeText(qrValue);
  };

const getSelectedAssessmentServerIds = () => {
  // Check if selectedMonitoring and its assessments are defined
  if (!selectedMonitoring || !selectedMonitoring.assessments) {
    return [];
  }

  // Map the selected assessment IDs to their server IDs (_id)
  return selectedAssessmentIds.map(id => {
    // Find the assessment in the selectedMonitoring.assessments array by comparing the index (id) with the provided id
    const assessment = selectedMonitoring.assessments.find((assessment, index) => (index + 1) === id);
    return assessment ? assessment._id : null;
  }).filter(serverId => serverId != null);
};

const handleIconClick = () => {
  setIsLinked(!isLinked);
};



return (
  <Box display="flex" backgroundColor="white" style={{ height: '100vh' }}>
    <Sidebar />
    <Box display="flex" flex={1} flexDirection="column" justifyContent="space-between">
      <Box ml="10px" >
        <Topbar title={t('dashboard.title')} />
      </Box>

          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px" ml="20px" mr="20px">
            <Box gridColumn="span 9" gridRow="1" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              {selectedMonitoring && (
                <>
                  <Typography variant="h3" fontWeight="bold" m="10px">
                    {t('dashboard.title_assessments_table')} {selectedMonitoring.name}
                  </Typography>
                  <Box flex={1} display="flex"> {/* Update this line */}
                    <AssessmentsTable
                      selectedAssessmentTable={selectedAssessmentTable}
                      updateAssessmentsTable={updateAssessmentsTable}
                      currentMonitoringServerId={currentMonitoringServerId}
                      currentAssessmentServerId={currentAssessmentServerId}
                      setCurrentAssessmentServerId={setCurrentAssessmentServerId}
                      currentUserId={currentUserId}
                      setIsOpen={setIsOpen}
                      setOpenAssessmentsCount={setOpenAssessmentsCount}
                      selectedAssessmentIds={selectedAssessmentIds}
                      setSelectedAssessmentIds={setSelectedAssessmentIds}
                    />
                  </Box>
                </>
              )}
            </Box>
          <Box gridColumn="span 3" sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h3" fontWeight="bold" m="10px">
              {t('dashboard.subtitle1')}
            </Typography>
            <Box
              sx={{
                backgroundColor: 'white',
                height: '345px',
                border: '1px solid rgb(224,224,224)',
                borderRadius: '4px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                overflowY: 'auto',
              }}
            >
            {selectedAssessmentIds.length > 0 && (
                  <Box sx={{ textAlign: 'center', p: '20px', width: '100%', height: '100%' }}>
                    <Typography variant="h6">
                      {t('dashboard.share_open_assessments_together')}
                    </Typography>
                    <div style={{ margin: '10px 0' }}></div>
                    {selectedAssessmentIds.map((id, index) => {
                      const assessmentName = selectedAssessmentTable.data.find(assessment => assessment.id === id)?.name;
                      return (
                        <Typography key={index}>
                          - {assessmentName || 'Unknown Assessment'}
                        </Typography>
                      );
                    })}
                  <Box sx={{ mt: 2, alignItems: 'center' }}>
                    <div ref={largeQRCodeRef} style={{ display: 'flex', justifyContent: 'center' }}>
                      <QRCodeCanvas value={generateQRCodeValue()} size={160} />
                    </div>
                    <div ref={qrCodeRef} style={{ display: 'none' }}>
                      <QRCodeCanvas value={generateQRCodeValue()} size={1024} includeMargin />
                    </div>
                    <Box mt={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Tooltip title={t('dashboard.download_pdf')}>
                        <IconButton color="inherit" onClick={handleDownloadPaperVersion}>
                          <PictureAsPdfIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('dashboard.download_qr')}>
                        <IconButton color="inherit" onClick={handleDownloadQR}>
                          <QrCodeScannerIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('dashboard.copy_link')}>
                        <IconButton color="inherit" onClick={handleCopyToClipboard}>
                          <InsertLinkIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={isLinked ? t('dashboard.linked_on') : t('dashboard.linked_off')}>
                        <IconButton color="inherit" onClick={handleIconClick}>
                          {isLinked ? (
                            <SwipeRightAltIcon sx={{ color: 'green' }} />
                          ) : (
                            <SwipeRightAltIcon sx={{ color: 'red' }} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </Box>

                    <Box gridColumn="span 3" sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Button onClick={handleToggleCodeVisibility}>
                      {isCodeVisible ? (
                        <Typography variant="h4">
                          {generatedCode}
                        </Typography>
                      ) : (
                        t('dashboard.share_code_reporting_grades')
                      )}
                    </Button>
                  </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

        <Box gridColumn="span 12" gridRow="2">
          <Typography variant="h3" fontWeight="bold" m="10px">
            {t('dashboard.subtitle2')}
          </Typography>
          <MonitoringsTable
            setSelectedMonitoring={setSelectedMonitoring}
            selectedMonitoring={selectedMonitoring}
            assessmentsTables={assessmentsTable}
            setAssessmentsTable={setAssessmentsTable}
            currentMonitoringServerId={currentMonitoringServerId}
            setCurrentMonitoringServerId={setCurrentMonitoringServerId}
            currentUserId={currentUserId}
            monitorings={monitorings}
            setMonitorings={setMonitorings}
          />
        </Box>
      </Box>
      <Footer />
    </Box>
  </Box>
);

};

export default Dashboard;

const MonitoringsTable = ({ 
  selectedMonitoring,
  setSelectedMonitoring,
  assessmentsTables,
  setAssessmentsTable,
  currentMonitoringServerId,
  setCurrentMonitoringServerId,
  monitorings,
  setMonitorings }) => {
  const [editingCell, setEditingCell] = useState(null);
  const [editingCellValue, setEditingCellValue] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [newMonitoringName, setNewMonitoringName] = useState('');
  const [newMonitoringDescription, setNewMonitoringDescription] = useState('');
  const { t } = useTranslation('translation');
    const [sortModel, setSortModel] = useState([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = async (monitoringId) => {

  // DELETE on server
  try {
    const token = localStorage.getItem("token");
    await axios.delete(`${BACKEND_URL}/monitoring/${currentMonitoringServerId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    console.log("Monitoring deleted successfully");
  } catch (err) {
    console.error(err);
  }

  // Filter out the monitoring to be deleted
  const filteredMonitorings = monitorings.filter((monitoring) => monitoring.id !== monitoringId);

  // Re-assign IDs
  const newMonitorings = filteredMonitorings.map((monitoring, index) => ({
    ...monitoring,
    id: index + 1, // Here we are reassigning the ID
  }));

  // Set the state with the new monitorings array
  setMonitorings(newMonitorings);

  // Filter out the assessmentTable to be deleted
  const filteredTables = assessmentsTables.filter((table) => table.id !== monitoringId);

  // Re-assign IDs for assessmentTables too
  const newTables = filteredTables.map((table, index) => ({
    ...table,
    id: index + 1, // Here we are reassigning the ID
  }));

  // Set the state with the new assessmentsTables array
  setAssessmentsTable(newTables);

  // If the selected monitoring is the one being deleted, deselect it
  if (selectedMonitoring && selectedMonitoring.id === monitoringId) {
    setSelectedMonitoring(null);
  }
};

  const handleAddMonitoring = async () => {

  // Create a new empty `assessments`, an objet where all the assessments of a given monitoring are stored
  const newAssessments = {
    id: assessmentsTables.length + 1,
    data: [],
  };

  // Add this new empty `assessments` to the assessmentsTable
  setAssessmentsTable((assessmentsTable) => [...assessmentsTable, newAssessments]);

  const token = localStorage.getItem("token");
  const decodedToken = jwt_decode(token);

  // Create new monitoring object
  const newMonitoring = {
    id: monitorings.length + 1,
    userId: decodedToken._id,
    name: newMonitoringName,
    description: newMonitoringDescription,
    creationDate: new Date(), // set creation date to current date
    lastModification: new Date(), // set last modification to current date
    options: ['Delete'],
  };

  // Add new monitoring to rows
  setMonitorings((monitorings) => [...monitorings, newMonitoring]);

  let serverMonitoringId;
  // send POST request to server
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(`${BACKEND_URL}/monitoring`, newMonitoring, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

    // Set current monitoring server id
    serverMonitoringId = res.data._id
  } catch (error) {
    console.error(error);
  }

  setCurrentMonitoringServerId((serverMonitoringId))

  // Reset the input fields
  setNewMonitoringName('');
  setNewMonitoringDescription('');

  // Close the dialog
  handleClose();
};

  const handleSave = async (monitoringId, field) => {
  // Find the row by id
  const updatedMonitorings = monitorings.map((row) => {
    if (row.id === monitoringId) {
      return {
        ...row,
        [field]: editingCellValue,
      };
    }
    return row;
  });

  // Update the selected monitoring's name
  if (selectedMonitoring && selectedMonitoring.id === monitoringId && field === 'name') {
    setSelectedMonitoring({
      ...selectedMonitoring,
      [field]: editingCellValue,
    });
  }

  // Save the changes to your state
  setMonitorings(updatedMonitorings);

  // Update the server-side data
  try {
    const rowToUpdate = updatedMonitorings.find(row => row.id === monitoringId);
    const token = localStorage.getItem("token");
    await axios.put(`${BACKEND_URL}/updateEdited/monitorings/${currentMonitoringServerId}`, rowToUpdate, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    } catch (err) {
    console.error(err);
  }

  // Clear the editing state
  setEditingCell(null);
  setEditingCellValue('');
};

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'name', 
      headerName: t('table_monitorings.name'), 
      width: 300,
      renderCell: (params) => (
        editingCell?.id === params.id && editingCell?.field === 'name' ? (
          <ClickAwayListener onClickAway={() => handleSave(params.id, 'name')}>
            <TextField 
              defaultValue={params.value} 
              onChange={(e) => setEditingCellValue(e.target.value)}
              autoFocus
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === 'Enter') {
                  handleSave(params.id, 'name');
                }
              }}
            />
          </ClickAwayListener>
        ) : (
          <div onClick={() => {
            setEditingCell({id: params.id, field: 'name'});
            setEditingCellValue(params.value);
          }}>
            {params.value}
          </div>
        )
      )
    },
    {
      field: 'description', 
      headerName: t('table_monitorings.description'),
      flex: 1, 
      renderCell: (params) => (
        editingCell?.id === params.id && editingCell?.field === 'description' ? (
          <ClickAwayListener onClickAway={() => handleSave(params.id, 'description')}>
            <TextField 
              defaultValue={params.value} // Changed from value to defaultValue
              onChange={(e) => setEditingCellValue(e.target.value)}
              autoFocus
              onKeyDown={(e) => {
                e.stopPropagation(); // Stop the event from bubbling up to the DataGrid's handler
                if (e.key === 'Enter') {
                  handleSave(params.id, 'description');
                }
              }}
              style={{ width: '100%' }}
            />
          </ClickAwayListener>
        ) : (
          <div onClick={() => {
            setEditingCell({id: params.id, field: 'description'});
            setEditingCellValue(params.value);
          }}>
            {params.value}
          </div>
        )
      )
    },

    { field: 'creationDate', headerName: t('table_monitorings.creation_date'), type: 'date', width: 130 },
    { field: 'lastModification', headerName: t('table_monitorings.last_modification'), type: 'date', width: 130 },

    {
      field: 'actions',
      headerName: t('table_monitorings.actions'),
      sortable: false,
      width: 80,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <ThreeDotsMenu
            options={params.row.options}
            onDelete={() => handleDelete(params.row.id)}
          />
        );
      },
    },
  ];

  return (
    <Box sx={{ height: 300, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <DataGrid
        rows={monitorings}
        columns={columns}
        pageSize={3}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        onRowClick={async (rowParams) => {
          setSelectedMonitoring(rowParams.row);
          // Convert local date string to Date object and format as ISO string
          const convertedDate = rowParams.row.creationDate.toISOString().replace('Z', '+00:00');

          try {
            
const token = localStorage.getItem("token");
        // Ensure the date is URL encoded before making the request
        const response = await axios.get(
          `${BACKEND_URL}/monitorings/findbydate/${encodeURIComponent(convertedDate)}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );     
         setCurrentMonitoringServerId(response.data._id);
          } catch (err) {
            console.error(err);
          }
        }}  
        style={{ height: '90%' }}
        sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
        }}
      />
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'flex-end', 
          padding: '10px',
          borderTop: '1px solid rgba(224, 224, 224, 1)'
        }}
      >
        <Button
          onClick={handleClickOpen}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{ 
            color: "black",
            backgroundColor: "#F7941E",
            borderRadius: "50px",
            "&:hover": {
              backgroundColor: "#D17A1D"
            }
          }}
        >
          {t('table_assessments.button_new_monitoring')}
        </Button>
      </Box>
      <Dialog open={openDialog} onClose={handleClose}>
      <DialogTitle variant="h3">{t('new_monitoring.create_new_monitoring')}</DialogTitle>
        <DialogContent>
          <TextField
            value={newMonitoringName}
            autoFocus
            size="small"
            margin="dense"
            id="name"
            label={t('new_monitoring.name')}
            type="text"
            fullWidth
            onChange={(e) => setNewMonitoringName(e.target.value)}

          />
          <TextField
            value={newMonitoringDescription}
            margin="dense"
            id="description"
            label={t('new_monitoring.description')}
            type="text"
            fullWidth
            onChange={(e) => setNewMonitoringDescription(e.target.value)}

          />
          {/* Include more fields as necessary */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('new_monitoring.cancel')}</Button>
          <Button onClick={handleAddMonitoring}>{t('new_monitoring.create')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const AssessmentsTable = ({ 
  selectedAssessmentTable,
  updateAssessmentsTable,
  currentMonitoringServerId,
  currentAssessmentServerId, 
  setCurrentAssessmentServerId,
  setIsOpen,
  setOpenAssessmentsCount,
  selectedAssessmentIds,
  setSelectedAssessmentIds}) => {

  const [editingCell, setEditingCell] = useState(null);
  const [editingCellValue, setEditingCellValue] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [newAssessmentDay, setNewAssessmentDay] = useState(null);
  const [newAssessmentName, setNewAssessmentName] = useState('');
  const [newAssessmentType, setNewAssessmentType] = useState('');
  const [error, setError] = useState(null);
  const [assessments, setAssessments] = useState([]);
  const { t } = useTranslation('translation');
  const [sortModel, setSortModel] = useState([
    {
      field: 'day',
      sort: 'desc',
    },
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    setAssessments(selectedAssessmentTable.data);
  }, [selectedAssessmentTable]);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = async (assessmentsId) => {

  // Check for responses tied to the assessment
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${BACKEND_URL}/responses/assessment/${currentAssessmentServerId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    const responses = response.data;

    if (responses && responses.length > 0) {
      const token = localStorage.getItem("token");
      // DELETE responses on server if they exist
      await axios.delete(
        `${BACKEND_URL}/responses/assessment/${currentAssessmentServerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      console.log("Assessment responses deleted successfully");
    } else {
      console.log("No responses tied to this assessment");
    }

  } catch (err) {
    console.error("Error fetching or deleting responses:", err);
  }

  // DELETE assessment on server
  try {
    const token = localStorage.getItem("token");
    // DELETE assessments on server
    await axios.delete(
      `${BACKEND_URL}/monitorings/${currentMonitoringServerId}/assessment/${currentAssessmentServerId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    console.log("Assessment deleted successfully");
  } catch (err) {
    console.error("Error deleting assessment:", err);
  }

  const filteredAssessments = assessments.filter((assessment) => assessment.id !== assessmentsId);

  // Re-assign IDs
  const newAssessments = filteredAssessments.map((assessment, index) => ({
    ...assessment,
    id: index + 1,
  }));

  setAssessments(newAssessments);
  updateAssessmentsTable(selectedAssessmentTable.id, newAssessments);
};


 const handleAddAssessment = async () => {
  let success = true;
  setError(null);

  if (!newAssessmentDay || !newAssessmentType || !newAssessmentName) {
    setError('This field cannot be empty.');
    success = false;
    return;
  }

  const existingAssessment = assessments.find(assessment => {
    return Number(assessment.day) === Number(newAssessmentDay) && assessment.name === newAssessmentName;
  });
    
  if (existingAssessment) {
    setError(`An assessment with this name already exists on day ${newAssessmentDay}.`);
    success = false;
    return;
  }
  
  else {
    // Create new assessment object
    const newAssessment = {
      id: (assessments ? assessments.length : 0) + 1,
      day: newAssessmentDay,
      name: newAssessmentName,
      type: newAssessmentType,
      status: "Draft", 
      creationDate: new Date(), 
      lastModification: new Date(),
      options: ['Edit', 'Preview', 'Open', 'Make a copy', 'Delete'],
    };

    // Add new assessment to the assessments
    const newAssessments = [...assessments, newAssessment];
    setAssessments(newAssessments);

    // Update the global assessmentsTable
    updateAssessmentsTable(selectedAssessmentTable.id, newAssessments);

    // Make a PUT request to the server
    try {
      const token = localStorage.getItem("token");
      // Send only the newAssessment object
      const res = await axios.put(`${BACKEND_URL}/monitoring/${currentMonitoringServerId}`, newAssessment, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });    
      console.log("New assessment created");
      // Set current monitoring server id
      setCurrentAssessmentServerId(res.data.newServerAssessmentId); 
    } catch (error) {
      console.error(error);
      setError('There was a problem saving the assessment.');
      success = false;
    }

    // Reset the input fields if the process was successful
    if (success) {
      setNewAssessmentName('');
      setNewAssessmentType('');
      handleClose();
    }
  }
};


const handleSave = async (assessmentId, field) => {
    // Check for a duplicate name only if the field being edited is 'name'
    if (field === 'name') {
        // Find the assessment that is being edited
        const editedAssessment = assessments.find(a => a.id === assessmentId);
        const dayOfEditedAssessment = editedAssessment.day;

        // Check if there is any other assessment with the same name and day (excluding the current one)
        const isDuplicateName = assessments.some(a => 
            a.id !== assessmentId && 
            a.day === dayOfEditedAssessment && 
            a.name === editingCellValue
        );

        // If duplicate is found, show an alert and return early
        if (isDuplicateName) {
            alert(`An assessment with the name "${editingCellValue}" already exists on day ${dayOfEditedAssessment}.`);
            return;
        }
    }

    // Find the assessment by id and update the specified field
    const updatedAssessments = assessments.map(assessment => {
        if (assessment.id === assessmentId) {
            return { ...assessment, [field]: editingCellValue };
        }
        return assessment;
    });

    // Save the updated assessments to state
    setAssessments(updatedAssessments);

    // Update the global assessmentsTables state
    updateAssessmentsTable(selectedAssessmentTable.id, updatedAssessments);

    // Update the server-side data
    try {
        const rowToUpdate = updatedAssessments.find(row => row.id === assessmentId);
        const token = localStorage.getItem("token");
        await axios.put(`${BACKEND_URL}/updateEdited/monitorings/${currentMonitoringServerId}/assessments/${currentAssessmentServerId}`, rowToUpdate, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    } catch (err) {
        console.error('Error updating assessment:', err);
    }

    // Clear the editing state
    setEditingCell(null);
    setEditingCellValue(null);
};

const handleEdit = (props) => {
  // Change the status to 'Draft' using handleStatusChange
  handleStatusChange(props.id, 'Draft');

  // Redirect to createSurvey page 
  navigate('/createSurvey', {
    state: {
      assessmentType: props.type,
      assessmentName: props.name,
      currentAssessmentServerId: currentAssessmentServerId,
      currentMonitoringServerId: currentMonitoringServerId,
    },
  });
};


const handlePreview = (props) => {
  // navigate to the previewSurvey page
  navigate('/previewSurvey', {
    state: {
      currentAssessmentServerId: currentAssessmentServerId,
      currentMonitoringServerId: currentMonitoringServerId,
    },
  });
};

const handleStatusChange = async (assessmentId, newStatus) => {
  // Find the assessment by id
  const updatedAssessments = assessments.map((assessment) => {
    if (assessment.id === assessmentId) {
      let options;
      switch (newStatus) {
        case 'Draft':
          options = ['Edit', 'Preview', 'Open', 'Make a copy', 'Delete'];
          break;
        case 'Open':
          options = ['Close', 'View', 'Edit', 'Make a copy', 'Delete'];
          break;
        case 'Close':
          options = ['Open', 'Make a copy', 'Delete'];
          break;
      }

      return {
        ...assessment,
        status: newStatus,
        options: options,
      };
    }
    return assessment;
  });

  // Save the changes to the local assessments state
  setAssessments(updatedAssessments);

  // Update the global assessmentsTables state
  updateAssessmentsTable(selectedAssessmentTable.id, updatedAssessments);

  // Update the server
  try {
    const token = localStorage.getItem("token");
    const res = await axios.put(`${BACKEND_URL}/monitorings/${currentMonitoringServerId}/assessments/${currentAssessmentServerId}/status`, {
    status: newStatus,
    }, {
      headers: {
          Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.error(error);
  }
};

const handleTerminate = (props) => {
  // Close the access to the questionnaire
  setIsOpen(true)
  handleStatusChange(props.id, 'Close');

  setOpenAssessmentsCount(assessments.filter(assessment => assessment.status === 'Open').length-1)

};

const handleOpen = (props) => {

setIsOpen(true)
setOpenAssessmentsCount(assessments.filter(assessment => assessment.status === 'Open').length+1)
handleStatusChange(props.id, 'Open');


};

const handleCopy = async (assessmentId) => {
  const assessmentToCopy = assessments.find(assessment => assessment._id === assessmentId._id);

  if (assessmentToCopy) {
    // Create a new assessment object with necessary modifications for the server
    const copiedAssessment = {
      ...assessmentToCopy,
      id: assessments.length + 1,
      name: `${assessmentToCopy.name} (copy)`,
      status: 'Draft',
      creationDate: new Date(),
      lastModification: new Date(),
    };

    delete copiedAssessment._id;

    console.log("copiedAssessment",copiedAssessment)

    // Add this copied assessment to your array of assessments
    const newAssessments = [...assessments, copiedAssessment];
    setAssessments(newAssessments);
    updateAssessmentsTable(selectedAssessmentTable.id, newAssessments);

    try {
      const token = localStorage.getItem("token");
      // Make a PUT request to add the new assessment to the monitoring on the server
      const response = await axios.put(`${BACKEND_URL}/monitoring/${currentMonitoringServerId}`, copiedAssessment, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      // Handle response and update the local state
      if (response.data) {
        // Extract the new assessment's ID from the response
        const newServerAssessmentId = response.data.newServerAssessmentId;

       // Create a new assessment object with the 'id' property for the Data Grid
      const newAssessment = {
        ...copiedAssessment,
        options: ['Preview', 'Edit', 'Open', 'Make a copy', 'Delete']
      };

      console.log("newAssessment", newAssessment)

        // Add the new assessment to the assessments array
        const newAssessments = [...assessments, newAssessment];
        setAssessments(newAssessments);
        updateAssessmentsTable(selectedAssessmentTable.id, newAssessments);
      }
    } catch (error) {
      console.error('Error copying assessment:', error);
      // Handle the error appropriately
    }
  }
};

const handleCheckboxChange = (event, assessmentId) => {
    event.stopPropagation();
    if (event.target.checked) {
        setSelectedAssessmentIds(prevIds => [...prevIds, assessmentId]);
    } else {
        setSelectedAssessmentIds(prevIds => prevIds.filter(id => id !== assessmentId));
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.1 },
    {
    field: 'day',
    headerName: t('table_assessments.day'),
    flex: 0.3,
    renderCell: (params) => (
      editingCell?.id === params.id && editingCell?.field === 'day' ? (
        <ClickAwayListener onClickAway={() => handleSave(params.id, 'day')}>
          <TextField 
            value={editingCellValue} 
            onChange={(e) => {
                const value = parseInt(e.target.value, 0);
                if (value >= 0) {
                  setEditingCellValue(e.target.value);
                }
              }}
              autoFocus
              type="number"
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === 'Enter') {
                handleSave(params.id, 'day');
              }
            }}
          />
        </ClickAwayListener>
      ) : (
          <div onClick={() => {
            setEditingCell({id: params.id, field: 'day'});
            setEditingCellValue(params.value);
          }}>
            {params.value}
          </div>
        )
      )
    },
     { 
      field: 'name', 
      headerName: t('table_assessments.name'), 
      flex: 1,
      renderCell: (params) => (
        editingCell?.id === params.id && editingCell?.field === 'name' ? (
          // Only show the editable TextField when the field is 'name'
          <ClickAwayListener onClickAway={() => handleSave(params.id, 'name')}>
            <TextField 
              defaultValue={params.value} // Changed from value to defaultValue
              onChange={(e) => setEditingCellValue(e.target.value)}
              autoFocus
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === 'Enter') {
                  handleSave(params.id, 'name');
                }
              }}
            />
          </ClickAwayListener>
        ) : (
          <div onClick={() => {
            setEditingCell({id: params.id, field: 'name'});
            setEditingCellValue(params.value);
          }}>
            {params.value}
          </div>
        )
      ) 
    },

    { field: 'type', headerName: t('table_assessments.type'), flex: 0.5 },
    {
      field: 'status',
      headerName: t('table_assessments.status'),
      flex: 0.3,
      renderCell: (params) => {
        let color;
        switch(params.value) {
          case 'Draft':
            color = 'grey';
            break;
          case 'Open':
            color = 'rgb(140, 187, 74)';
            break;
          case 'Close':
            color = 'black';
            break;
          default:
            color = 'black';
            break;
        }
        return (
          <Button 
            variant="outlined" 
            size="small"
            style={{
              color: "white", 
              borderColor: color,
              backgroundColor: color,
              borderRadius: '20px',
              padding: '5px 10px',
            }}
          >
            <Typography style={{ fontSize: '0.7rem', padding: '0 5px' }}>
              {params.value}
            </Typography>
          </Button>
        );
      },
    },
    { field: 'creationDate', headerName: t('table_assessments.creation_date'), type: 'date', flex: 0.4 },
    { field: 'lastModification', headerName: t('table_assessments.last_modification'), type: 'date', flex: 0.4 },
    {
        field: 'checkbox',
        headerName: t('table_assessments.share'),
        flex: 0.2,
        renderCell: (params) => (
        <Checkbox
            checked={selectedAssessmentIds.includes(params.row.id)}
            onChange={(event) => handleCheckboxChange(event, params.row.id)}
            disabled={params.row.status === 'Draft'}
        />
      )
    },
    {
      field: 'actions',
      headerName: t('table_assessments.actions'),
      sortable: false,
      flex: 0.1,
      disableClickEventBubbling: true,
      renderCell: (params) => {
      return (
        <ThreeDotsMenu
            options={params.row.options}
            onDelete={() => handleDelete(params.row.id)}
            onEdit={() => handleEdit(params.row)}
            onPreview={() => handlePreview(params.row)}
            onView={() => handlePreview(params.row)}
            onOpen={() => handleOpen(params.row)}
            onCopy={() => handleCopy(params.row)}
            onTerminate={() => handleTerminate(params.row)}
        />
        );

      },
    },
  ];


  return (
    <Box sx={{ height: "400px", width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <DataGrid
        GridLinesVisibility="None"
        rows={selectedAssessmentTable ? selectedAssessmentTable.data : []}
        columns={columns}
        pageSize={5}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        onRowClick={async (rowParams) => {
          // Convert local date string to Date object and format as ISO string
          const convertedDate = rowParams.row.creationDate.toISOString().replace('Z', '+00:00');

          try {
            const token = localStorage.getItem("token");
            // Ensure the date is URL encoded before making the request
            const res = await axios.get(`${BACKEND_URL}/monitorings/${currentMonitoringServerId}/findbydate/${encodeURIComponent(convertedDate)}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
           });
            setCurrentAssessmentServerId(res.data._id);

          } catch (err) {
            console.error(err);
          }
          
          if (rowParams.row.status === 'Open') {
            setIsOpen(true); // close the QR code popup when a new row is clicked
          }
          else {
            setIsOpen(false); 
          }

        }}  
        sx={{
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        
        }
        }}
        style={{ height: '90%' }}
      />
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'flex-end', 
          padding: '10px',
          borderTop: '1px solid rgba(224, 224, 224, 1)'
        }}
      >
        <Button
          onClick={handleClickOpen}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{ 
            color: "black",
            backgroundColor: "#F7941E",
            borderRadius: "50px",
            "&:hover": {
              backgroundColor: "#D17A1D"
            }
          }}
        >
          {t('table_assessments.button_new_assessment')}
        </Button>
      </Box>
        <Dialog
          open={openDialog}
          onClose={handleClose}
        >        
        <DialogTitle variant="h3">{t('new_assessment.create_new_assessment')}</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center">
            <Typography>{t('new_assessment.day')} &nbsp; </Typography>
            <TextField 
              id="day" 
              type="number"
              autoFocus
              size="small"
              style={{ width: "70px" }}
              margin="dense"
              inputProps={{ min: "0" }}
              onChange={(e) => {
                  const value = parseInt(e.target.value, 0);
                  if (value >= 0) {
                      setNewAssessmentDay(value);
                      
                  } else {
                      setNewAssessmentDay(0);
                  }
              }} 
            />
          </Box>
          
          <Box mb="20px" mt="20px">
            <TextField
            value={newAssessmentName}
            autoFocus
            size="small"
            margin="dense"
            id="name"
            label={t('new_assessment.name')}
            type="text"
            fullWidth
            onChange={(e) => setNewAssessmentName(e.target.value)}

          />
          </Box>
          <Box mb="5px"><InputLabel id="type-label">{t('new_assessment.type_assessment')}</InputLabel></Box>
          <Select 
            value={newAssessmentType}
            margin="dense"
            size="small"
            id="type"
            labelId="type-label"
            fullWidth
            onChange={(e) => setNewAssessmentType(e.target.value)}
          >
            <MenuItem value="Trainee characteristics">{t('table_assessments.trainee_characteristics')}</MenuItem>
            <MenuItem value="Training characteristics">{t('table_assessments.training_characteristics')}</MenuItem>
            <MenuItem value="Immediate reactions">{t('table_assessments.immediate_reactions')}</MenuItem>
            <MenuItem value="Learning">{t('table_assessments.learning')}</MenuItem>
            <MenuItem value="Organizational conditions">{t('table_assessments.organizational_conditions')}</MenuItem>
            <MenuItem value="Behavioral changes">{t('table_assessments.behavioral_changes')}</MenuItem>
            <MenuItem value="Sustainability conditions">{t('table_assessments.sustainability_conditions')}</MenuItem>
            <MenuItem value="Student characteristics">{t('table_assessments.student_characteristics')}</MenuItem>
            <MenuItem value="Student learning outcomes">{t('table_assessments.student_learning_outcomes')}</MenuItem>
          </Select>

          {error && 
            <Box color="red" mt="15px">
              <Typography>{error}</Typography>
            </Box>
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('new_assessment.cancel')}</Button>
          <Button onClick={handleAddAssessment}>{t('new_assessment.create')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const ThreeDotsMenu = ({ options, onDelete, onEdit, onPreview, onOpen, onCopy, onTerminate  }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);  // new state for the confirmation dialog
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
  switch (option) {
    case 'Delete':
      setOpenConfirm(true);
      break;
    case 'Edit':
      onEdit();
      break;
    case 'Preview':
      onPreview();
      break;
    case 'View':
      onPreview();
      break;
    case 'Open':
      onOpen();
      handleClose();
      break;
    case 'Make a copy':
      onCopy();
      handleClose();
      break;
    case 'Close':
      onTerminate();
      handleClose();
      break;
    default:
      handleClose();
  }
};

  const handleConfirmDelete = () => {
    onDelete();  // call the onDelete function passed from the parent component
    setOpenConfirm(false);  // close the confirmation dialog
    handleClose();  // close the menu
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}

      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleOptionClick(option, onDelete)}
            sx={{
              color: option === 'Delete' ? 'red' : 'inherit',
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      {/* confirmation dialog */}
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
      >
        <DialogTitle variant="h3">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to proceed? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirm(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};