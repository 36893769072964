import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { Box,  TextField, Button, Select, MenuItem, InputLabel, Typography, FormControl, IconButton, useTheme } from "@mui/material";
import { Delete } from "@mui/icons-material";
import React from "react";

const AddQuestionSchema = (splitWorkshops) => {
  let schema = {
    question: Yup.string()
      .min(5, "The question must be at least 5 characters long")
      .required("The question is required"),
    shortName: Yup.string()
      .min(2, "The short name must be at least 2 characters long")
      .max(40, "The short name must be at most 40 characters long")
      .required("The short name is required"),
  };

  if (splitWorkshops) {
    schema.workshop = Yup.string().required("The workshop is required");
  }

  return Yup.object().shape(schema);
};

const AddQuestion = ({ setQuestions, questions, assessmentType, workshops, splitWorkshops}) => {

  const initialValues = { 
    questionId: "", 
    question: "",
    shortName: "",
    questionType: "text",
    organizationalType: "",
    isMandatory: false,
    workshop: "",
    options: ["",""],
    items: ["",""]};

  const handleSubmit = (values, { resetForm }) => {

    let newQuestionId = questions.reduce((maxId, question) => Math.max(maxId, parseInt(question.questionId, 10)), 0) + 1;

    const newQuestion = {
      questionId: newQuestionId.toString(),
      question: values.question,
      shortName: values.shortName,  
      questionType: values.questionType,
      organizationalType: values.organizationalType,
      isMandatory: values.isMandatory,
      workshop: values.workshop,
      options: values.questionType === "text"
        ? []
        : values.options.map((option, index) => ({ value: `${index + 1}`, label: option })),
      items: values.questionType === "text"
        ? []
        : values.items.map((item, index) => ({ value: `${index + 1}`, label: item })),
    };

    setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
    resetForm();
  };
  const styles = {
    formGroup: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "1rem",
    },
    invalidFeedback: {
      color: "red",
      marginTop: "0.25rem",
    },
  };

  return (
    <Box ml="20px" mr="20px">
      <Box
        sx={{
          p: "10px", 
          borderRadius:"4px",
        }}
      >
        <Formik
        initialValues={initialValues}
        validationSchema={AddQuestionSchema(splitWorkshops)}
        onSubmit={handleSubmit}
      >
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <Form>
          <div style={styles.formGroup}>
              {splitWorkshops && (
              
              <FormControl fullWidth>
                  <InputLabel id="selectedWorkshop">Choose the workshop</InputLabel>
                  <Select
                    labelId="selectedWorkshop"
                    id="selectedWorkshop"
                    name="workshop"
                    value={values.workshop}
                    label="Choose the workshop"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.workshop && errors.workshop ? true : false}
                    sx={{ mb:"15px" }}
                  >
                    {workshops.map((workshop, index) => (
                    <MenuItem key={index} value={workshop}>
                        {workshop}
                    </MenuItem>
                ))}
                  </Select>
                </FormControl>
              )}

              <TextField
                id="question"
                label="Enter your question"
                value={values.question}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.question && errors.question ? true : false}
                sx={{mb:"15px"}}
                helperText={touched.question && errors.question ? errors.question : ""}
              />

                <TextField
                  id="shortName"
                  label="Enter a short name for your question"
                  value={values.shortName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.shortName && errors.shortName ? true : false}
                  sx={{mb:"15px"}}
                  helperText={touched.shortName && errors.shortName ? errors.shortName : ""}
                />

                {assessmentType === "Organizational conditions" && (
                  <FormControl fullWidth>
                    <InputLabel id="organizationalType">Organizational Type</InputLabel>
                    <Select
                      labelId="organizationalType"
                      id="organizationalType"
                      name="organizationalType"
                      value={values.organizationalType}
                      label="Organizational Type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.organizationalType && errors.organizationalType ? true : false}
                      sx={{mb:"15px"}}
                    >
                    <MenuItem value="material resources">Material Resources</MenuItem>
                    <MenuItem value="temporal resources">Temporal Resources</MenuItem>
                    <MenuItem value="hierarchical support">Hierarchical Support</MenuItem>
                    <MenuItem value="colleague support">Colleague Support</MenuItem>
                    <MenuItem value="technical support">Technical Support</MenuItem>
                    <MenuItem value="mentoring">Mentoring</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                    </Select>
                </FormControl>
              )}

              <FormControl fullWidth>
                <InputLabel id="questionType">Question Type</InputLabel>
                <Select
                  labelId="questionType"
                  id="questionType"
                  name="questionType"
                  value={values.questionType}
                  label="Question Type"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.questionType && errors.questionType ? true : false}
                  sx={{mb:"15px"}}
                >
                  <MenuItem value="text">Text</MenuItem>
                  <MenuItem value="radio-ordered">Ordered Choices</MenuItem>
                  <MenuItem value="radio-unordered">Unordered Choices</MenuItem>
                  <MenuItem value="checkbox">Checkboxes</MenuItem>
                  <MenuItem value="matrix-radio-ordered">Matrix Ordered Choices</MenuItem>
                </Select>
              </FormControl>
              </div>

              {values.questionType === "matrix-radio-ordered" && (
                <>        
                  <h4>Items</h4>
                  <FieldArray name="items">
                    {({ remove, push }) => (
                      <>
                        {values.items.map((item, index) => (
                          <div key={index} style={styles.formGroup}>
                            <div style={styles.inputGroup}>
                              <TextField
                                id={`items.${index}`}
                                name={`items.${index}`}
                                placeholder={`Item ${index + 1}`}
                                value={item}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.items &&
                                  touched.items[index] &&
                                  errors.items &&
                                  errors.items[index]
                                    ? true
                                    : false
                                }
                                helperText={
                                  touched.items &&
                                  touched.items[index] &&
                                  errors.items &&
                                  errors.items[index]
                                    ? errors.items[index]
                                    : ''
                                }
                              />
                              <IconButton onClick={() => remove(index)}>
                                      <Delete />
                              </IconButton>
                            </div>
                          </div>
                        ))}

                         <Button
                                onClick={() => push('')}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#F7941E",
                                    borderRadius: "50px",
                                    color: "black",
                                    "&:hover": {
                                        backgroundColor: "#D17A1D",
                                    },
                                }}
                            >
                                <Typography variant="h5">ADD ITEM</Typography>
                            </Button>
                      </>
                    )}
                  </FieldArray>
                </>
              )}


              {values.questionType !== "text" && (
                <>        
                  <h4>Options to answer</h4>
                  <FieldArray name="options">
                    {({ remove, push }) => (
                      <>
                        {values.options.map((option, index) => (
                          <div key={index} style={styles.formGroup}>
                            <div style={styles.inputGroup}>
                              <TextField
                                id={`options.${index}`}
                                name={`options.${index}`}
                                placeholder={`Option ${index + 1}`}
                                value={option}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.options &&
                                  touched.options[index] &&
                                  errors.options &&
                                  errors.options[index]
                                    ? true
                                    : false
                                }
                                helperText={
                                  touched.options &&
                                  touched.options[index] &&
                                  errors.options &&
                                  errors.options[index]
                                    ? errors.options[index]
                                    : ''
                                }
                              />
                              <IconButton onClick={() => remove(index)}>
                                      <Delete />
                              </IconButton>
                            </div>
                          </div>
                        ))}

                         <Button
                                onClick={() => push('')}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#F7941E",
                                    borderRadius: "50px",
                                    color: "black",
                                    "&:hover": {
                                        backgroundColor: "#D17A1D",
                                    },
                                }}
                            >
                                <Typography variant="h5">ADD OPTION</Typography>
                            </Button>
                      </>
                    )}
                  </FieldArray>

                  <Box sx={{mt:"15px"}}>
                     <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    backgroundColor: "#F7941E",
                                    borderRadius: "50px",
                                    color: "black",
                                    "&:hover": {
                                        backgroundColor: "#D17A1D",
                                    },
                                }}
                            >
                                <Typography variant="h5">ADD</Typography>
                            </Button>
                  </Box>
                </>
              )}

              {(values.questionType === "text") && (
                <Box sx={{mt:"15px"}}>
                  <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    backgroundColor: "#F7941E",
                                    borderRadius: "50px",
                                    color: "black",
                                    "&:hover": {
                                        backgroundColor: "#D17A1D",
                                    },
                                }}
                            >
                                <Typography variant="h5">ADD</Typography>
                            </Button>
                </Box>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  )
};

export default AddQuestion;
