import React, { useState } from 'react';
import { Alert } from '@mui/material';
import { Box, Typography, TextField, Button, Paper } from "@mui/material";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import axios from "axios";
import { BACKEND_URL } from "../../config";

const Frameworks = () => {

    return (
        <Box display="flex" style={{ height: '100vh', overflow: 'auto' }}>
            <Sidebar />
            <Box display="flex" flex="1" flexDirection="column">
                <Box mt="10px" ml="10px">
                    <Topbar title="Frameworks" />
                </Box>

                </Box>
        </Box>
    );
}

export default Frameworks;
