import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stepper, Step, StepLabel, Typography, Box, Button, Paper, TextField } from '@mui/material';
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import axios from "axios";
import { BACKEND_URL } from "../../config";
import dtc_model_details_en from "../../assets/dtc_model_details_en.svg"; 
import dtc_model_en from "../../assets/dtc_model_en.svg"; 
import models_en from "../../assets/kirkpatrick_guskey_models_en.svg";
import monitorings_en from "../../assets/monitorings_en.png";


const steps = [
    "Getting Started",
    "A bit of theory",
    "A quick tour of the app",
    "Your first training monitoring",
    "A more advanced monitoring"
];

const Tutorial = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [feedback, setFeedback] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
    const handleBack = () => setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
    const handleStepClick = (stepIndex) => setActiveStep(stepIndex);


    // Feedback submission
    const submitFeedback = async () => {
        setIsSubmitting(true);
        try {
            await axios.post(`${BACKEND_URL}/feedback`, { feedback });
            setFeedback("");
        } catch (error) {
            console.error("Error submitting feedback:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <>
                        <Typography variant="h3" fontWeight="bold" mb={2}>Getting Started</Typography>
                        <Typography variant="h4">
                            Welcome to the Digital Training Companion documentation! This tutorial will introduce you to the main functionalities that you can use to monitor your training sessions. By the end of this tutorial, you'll be able to:
                        </Typography>
                        <Box display="flex" gap="10px" marginTop="20px">
                            {/* Inner box with styles */}
                            <Box
                                display="flex"
                                flexDirection="column"
                                width="100%"
                                color="black"
                                sx={{
                                    border: "1px solid rgb(226,227,229)",
                                    backgroundColor: "rgb(245,246,248)",
                                    borderRadius: "20px",
                                    padding: "10px",
                                }}
                                aria-label="explanation"
                            >
                                {/* Text content */}
                                <Typography style={{ fontSize: 18 }}>
                                <ul>
                                    <li>Gain a comprehensive understanding of the theoretical model used in this application.</li>
                                    <li>Create and manage monitorings and assessments.</li>
                                    <li>Share your assessments with your trainees.</li>
                                    <li>Visualize your session-to-session results.</li>
                                    <li>Export raw data and generate detailed PDF reports.</li>
                                    <li>Track the progress of your trainees over time.</li>
                                    <li>Maintain a logbook to document observations and planned improvements for training sessions.</li>
                                </ul>
                                </Typography>
                            </Box>
                        </Box>
                    </>
                );
            case 1:
                return (
                    <>
                        <Typography variant="h3" fontWeight="bold" mb={2}>A bit of theory</Typography>
                        <Box mb={4}  textAlign={"justify"}>
                            <Typography style={{ fontSize: 18 }} paragraph>
                                How thoroughly do you evaluate your training programs? How do you ensure your training is effective when trainees return to their roles? If they struggle to apply what they've learned, how can you support them? Ultimately, how can you prove that your training positively impacts the field?
                            </Typography>
                            <Typography style={{ fontSize: 18 }} paragraph>
                                To answer these questions, you need to assess various evaluation levels throughout the training sessions and beyond, not just participants' satisfaction immediately after a session. The Kirkpatrick model is a widely recognized framework that focuses on four of these levels: reaction, learning, behavior, and results. 
                                
                                Another researcher, Guskey, later adapted this model for education. These models offer a comprehensive and longitudinal view of training effectiveness and provide insights needed to make informed improvements.
                            </Typography>
                            <Box display="flex" flexDirection="column" alignItems="center" mt={3} mb={3}>
                                <img
                                alt=""
                                src={models_en}
                                style={{
                                cursor: "pointer", 
                                borderRadius: "1%", 
                                maxWidth: "800px",
                                minWidth: "800px",
                                }}
                                />
                            </Box>
                            <Typography style={{ fontSize: 18 }} paragraph>
                                However, such models of evaluation are often neglected. Most assessments focus solely on immediate participant satisfaction due to the time and effort required to collect data and a lack of dedicated tools. For instance, in the corporate world, 20% of companies don't evaluate training at all, while 80% only measure satisfaction. Meanwhile, executives indicate that business leaders see the lack of concrete measures as a recurring challenge, weakening the effectiveness of their training programs. In education, similar trends exist: studies show that most assessments focus only on participant perceptions and satisfaction while neglecting learning and practical application.
                            </Typography>
                            <Typography style={{ fontSize: 18 }} paragraph>
                                This gap underscores the importance of a robust and ongoing evaluation process that captures training effectiveness on multiple levels.
                            </Typography>
                            <Typography style={{ fontSize: 18 }} paragraph>
                                To provide the necessary information, we've developed a revised version of Guskey's model and this web application. Our model includes nine levels that help you organize and collect quickly the right information from training sessions to the concrete results achieved by trainees in the field. It gives you the ability to gather accurate data to support trainees when applying their training in real-world scenarios and helps you collect crucial information to continuously improve your training programs.
                            </Typography>
               
                                <Box display="flex" mt={3} mb={3} flexDirection="column" alignItems="center">
                                    <img
                                    alt=""
                                    src={dtc_model_en}
                                    style={{
                                    cursor: "pointer", 
                                    borderRadius: "1%", 
                                    maxWidth: "800px",
                                    minWidth: "800px",
                                    }}
                                    />
                                </Box>
                                <Typography style={{ fontSize: 18 }} paragraph>
                                    For each level, specific indicators need to be measured, reflecting those typically found to influence the subsequent levels. The application offers standard indicators for certain levels. These indicators are not exhaustive and can be adjusted to your specific training conditions and needs.
                                </Typography>
                                <Box display="flex" mt={3} mb={3} flexDirection="column" alignItems="center">
                                    <img
                                    alt=""
                                    src={dtc_model_details_en}
                                    style={{
                                    cursor: "pointer", 
                                    borderRadius: "1%", 
                                    maxWidth: "900px",
                                    minWidth: "900px",
                                    }}
                                    />
                        
                            </Box>
                        </Box>
                    </>
                );
            case 2:
                return (
                    <>
                        <Typography variant="h3" fontWeight="bold" mb={3}>A quick tour of the app</Typography>
                        <Typography variant="h4" mb={2}>
                            Before we begin, let's take a quick tour of the application. On the menu to the left, you'll find three feature sections:
                       </Typography>
                       <Typography style={{ fontSize: 18 }} mb={2}>
                       <ul>
                            <li>The DESIGN section.</li>
                            <li>The MONITOR section.</li>
                            <li>The RESOURCES section.</li>
                        </ul>
                         </Typography>
                        <Typography variant="h4" fontWeight="bold" mt={3} mb={2}>
                            The DESIGN section 
                       </Typography>
                       <Typography style={{ fontSize: 18 }} paragraph>
                        The DESIGN section includes {" "}
                        <span
                            onClick={() => window.open('/dashboard', '_blank')} 
                            style={{ cursor: 'pointer', color: 'blue' }}
                        >
                            My Monitorings
                        </span>
                         , the management page for your monitorings and assessments. Before diving into the specific features of this page, let's define these two terms, as they are fundamental to using the application effectively.
                       </Typography>
                       <Typography style={{ fontSize: 18 }} paragraph>
                        A <b>monitoring</b> is a collection of assessments. It can include one or more assessments from the various levels of the previously described model. Thus, a monitoring encompasses all assessments that can be conducted for a given training program.
                       </Typography>
                       <Box display="flex" mt={4} mb={4} flexDirection="column" alignItems="center">
                            <img
                            alt=""
                            src={monitorings_en}
                            style={{
                            cursor: "pointer", 
                            borderRadius: "1%", 
                            maxWidth: "1500px",
                            }}
                            />
                        </Box>
                        <Typography style={{ fontSize: 18 }} paragraph>
                        An <b>assessment</b> is a collection of assessments. It can include one or more assessments from the various levels of the previously described model. Thus, a monitoring encompasses all assessments that can be conducted for a given training program.
                       </Typography>
                        <Box display="flex" mt={4} mb={4} flexDirection="column" alignItems="center">
                            <img
                            alt=""
                            src={monitorings_en}
                            style={{
                            cursor: "pointer", 
                            borderRadius: "1%", 
                            maxWidth: "1500px",
                            }}
                            />
                        </Box>
                    </>
                );
            case 3:
                return (
                    <>
                        <Typography variant="h5">Using Advanced Features</Typography>
                        <Typography>To make the most out of your data:</Typography>
                        <ul>
                            <li>Configure custom settings in the "Settings" panel.</li>
                            <li>Utilize the "Report" generator for comprehensive summaries.</li>
                        </ul>
                    </>
                );
            case 4:
                return (
                    <>
                        <Typography variant="h5">Completing Your Workflow</Typography>
                        <Typography>Finalize your work and move forward:</Typography>
                        <ul>
                            <li>Review your data and results.</li>
                            <li>Export the final reports for sharing.</li>
                            <li>Provide feedback or suggestions to help us improve.</li>
                        </ul>
                        <TextField
                            fullWidth
                            multiline
                            minRows={3}
                            placeholder="We value your feedback..."
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            sx={{ marginBottom: '10px' }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={submitFeedback}
                            sx={{
                                backgroundColor: '#F7941E',
                                borderRadius: '50px',
                                color: 'black',
                                '&:hover': { backgroundColor: '#D17A1D' }
                            }}
                        >
                            {isSubmitting ? "Submitting..." : "Submit Feedback"}
                        </Button>
                    </>
                );
            default:
                return <Typography>Unknown Step</Typography>;
        }
    };

    return (
        <Box display="flex" style={{ height: '100vh' }}>
            <Sidebar />
            <Box display="flex" flex="1" flexDirection="column">
                <Box mt="10px" ml="10px">
                    <Topbar title="Tutorial" />
                </Box>

                <Box m="20px">
                    <Paper elevation={2} sx={{ padding: '20px', borderRadius: '10px' }}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label, index) => (
                                <Step key={index} active={activeStep === index} onClick={() => handleStepClick(index)}>
                                    <StepLabel
                                        sx={{
                                            cursor: 'pointer',
                                            '& .MuiStepIcon-root': {
                                                color: activeStep === index ? '#F7941D' : '#D3D3D3',
                                            }
                                        }}
                                    >
                                        {label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                        <Box mt="20px">
                            {renderStepContent(activeStep)}
                        </Box>

                        <Box mt="20px" display="flex" justifyContent="space-between">
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                variant="contained"
                                sx={{ 
                                    backgroundColor: '#F7941E',
                                    borderRadius: '50px',
                                    color: 'black',
                                    '&:hover': { backgroundColor: '#D17A1D' }
                                }}
                            >
                                Back
                            </Button>

                            <Button
                                onClick={activeStep === steps.length - 1 ? handleBack : handleNext}
                                variant="contained"
                                sx={{ 
                                    backgroundColor: '#F7941E',
                                    borderRadius: '50px',
                                    color: 'black',
                                    '&:hover': { backgroundColor: '#D17A1D' }
                                }}
                            >
                                {activeStep === steps.length - 1 ? "Start Again" : "Next"}
                            </Button>
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
};

export default Tutorial;