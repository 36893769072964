import React, { useState, useCallback, useMemo } from 'react';
import { Box, Button, Radio, Checkbox, FormControl, FormLabel, FormControlLabel, RadioGroup, Typography, TextField, Slider, IconButton } from '@mui/material';
import { Field } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import MatrixQuestion from './MatrixQuestion';
import './MatrixQuestion.css';

const SurveyQuestion = ({
  imageUrl = "",
  question,
  shortName = "",
  options = [],
  items = [],
  fieldName,
  type,
  isMandatory = false,
  competencies = [],
  min = 0,
  max = 10,
  titleFontSize = '24px',
  optionFontSize = '18px',
  disabled = false,
  correctAnswer,
  explanation,
  displayCorrectAnswer = true,
  removeCompetency,
  removeImage,
  removeExplanation,
  setFieldValue,
  matrixFullWidth
}) => {
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isAnswered, setIsAnswered] = useState(false);

  const isOptionDisabled = useMemo(() => {
    return disabled || (explanation && explanation.trim() !== '' && isAnswered);
  }, [disabled, explanation, isAnswered]);

  const handleAnswerSelect = useCallback((event, option) => {
    if (!isOptionDisabled) {
      setSelectedAnswer(option);
      setIsAnswered(true);
      setFieldValue(fieldName, option);
    }
  }, [isOptionDisabled, fieldName, setFieldValue]);

  const handleMatrixAnswerSelect = useCallback((itemIndex, optionValue) => {
    if (!isOptionDisabled) {
      const updatedAnswers = { ...selectedAnswers, [itemIndex]: optionValue };
      setSelectedAnswers(updatedAnswers);
      setIsAnswered(true);

      const answersArray = items.map((_, index) => updatedAnswers[index] || '');
      setFieldValue(fieldName, answersArray);
    }
  }, [isOptionDisabled, selectedAnswers, items, fieldName, setFieldValue]);

  const handleRemoveExplanation = useCallback(() => {
    removeExplanation(question, explanation);
  }, [removeExplanation, question, explanation]);

  const handleRemoveCompetency = useCallback((competency) => {
    removeCompetency(question, competency);
  }, [removeCompetency, question]);

  const isCorrectAnswer = useCallback((option) => {
    if (!displayCorrectAnswer || !correctAnswer || type === "checkbox") return false;
    return option.label === correctAnswer;
  }, [displayCorrectAnswer, correctAnswer, type]);

  const getOptionStyle = useCallback((option) => {
    if (disabled && correctAnswer && isCorrectAnswer(option)) {
      return {
        backgroundColor: "#dff0d8",
        borderColor: "#d6e9c6",
      };
    }
    
    if (displayCorrectAnswer && isAnswered) {
      if (option.value === correctAnswer) {
        return { color: 'green' };
      } else if (option.value === selectedAnswer && option.value !== correctAnswer) {
        return { color: 'red' };
      }
    }
    return {};
  }, [disabled, correctAnswer, isCorrectAnswer, displayCorrectAnswer, isAnswered, selectedAnswer]);

  const isAnswerCorrect = useMemo(() => {
    return selectedAnswer === correctAnswer;
  }, [selectedAnswer, correctAnswer]);

  const explanationColor = useMemo(() => {
    if (!isAnswered || !explanation) return 'green';
    return isAnswerCorrect ? 'green' : 'red';
  }, [isAnswered, explanation, isAnswerCorrect]);

  return (
    <Box margin="20px" display="flex" flexDirection="column" width="95%" alignItems="center">
      {imageUrl && (
        <Box display="flex" alignItems="center">
          <img src={imageUrl} alt="Question" style={{ marginBottom: '20px', maxWidth: '250px', maxHeight: '250px'}} />
          {disabled && (
            <IconButton onClick={removeImage}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      )}
      <FormControl component="fieldset" fullWidth disabled={disabled}>
        <FormLabel sx={{ fontSize: titleFontSize, m: "0px 10px 10px 0px", fontWeight: "bold"}}>
          {question}
          {isMandatory && <span style={{ color: 'red' }}> *</span>}
          {disabled && shortName && (
            <>
              {" - "}
              <span style={{ fontStyle: 'italic' }}>{shortName}</span>
            </>
          )}
        </FormLabel>

        {type === "radio-unordered" || type === "radio-ordered" ? (
          <RadioGroup 
            aria-label={fieldName} 
            name={fieldName}
            onChange={(event) => handleAnswerSelect(event, event.target.value)}
          >
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option.value}
                control={<Field as={Radio} type="radio" name={fieldName} />}
                label={
                  <Typography
                    sx={{
                      fontSize: optionFontSize,
                      ...getOptionStyle(option)
                    }}
                  >
                    {option.label}
                  </Typography>
                }
                disabled={isOptionDisabled}
              />
            ))}
          </RadioGroup>
        ) : type === "checkbox" ? (
          options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Field as={Checkbox} type="checkbox" name={fieldName} />}
              label={
                <Typography sx={{ 
                  fontSize: optionFontSize,
                  ...getOptionStyle(option)
                }}>
                  {option.label}
                </Typography>
              }
              disabled={isOptionDisabled}
            />
          ))
        ) : type === "slider" ? (
          <Field name={fieldName}>
            {({ field, form }) => (
              <Slider
                value={field.value || min}
                onChange={(e, newValue) => {
                  if (!isOptionDisabled) {
                    form.setFieldValue(field.name, newValue);
                    setIsAnswered(true);
                  }
                }}
                valueLabelDisplay="auto"
                min={min}
                max={max}
                sx={{ m: "0px 0px 0px 0px"}}
                disabled={isOptionDisabled}
              />
            )}
          </Field>
        ) : type === "matrix-radio-ordered" ? (
          <div className="table-wrapper">
            <MatrixQuestion
              items={items}
              options={options}
              fieldName={fieldName}
              selectedAnswers={selectedAnswers}
              handleAnswerSelect={handleMatrixAnswerSelect}
              isOptionDisabled={isOptionDisabled}
              matrixFullWidth={matrixFullWidth}
            />
          </div>
        ) : type === "text" && (
          <Field name={fieldName}>
            {({ field, form }) => (
              <TextField
                {...field}
                multiline
                rows={3}
                sx={{ m: "0px 20px 0px 0px", width: '100%' }}
                disabled={isOptionDisabled}
                value={disabled ? "" : field.value}
                onChange={(e) => {
                  form.setFieldValue(field.name, e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    const cursorPosition = e.target.selectionStart;
                    const currentValue = e.target.value;
                    const newValue = 
                      currentValue.substring(0, cursorPosition) + '\n' + 
                      currentValue.substring(cursorPosition);
                    
                    form.setFieldValue(field.name, newValue);
                    
                    setTimeout(() => {
                      e.target.selectionStart = e.target.selectionEnd = cursorPosition + 1;
                    }, 0);
                  }
                }}
              />
            )}
          </Field>
        )}

        <Box display="flex" gap="10px" marginTop="20px">
          {(disabled && explanation) || (isAnswered && explanation) ? (
            <Box display="flex" maxWidth="95%">
              <Button 
                variant="outlined"
                size="small"
                style={{
                  color: explanationColor,
                  borderColor: explanationColor,  
                  backgroundColor: "white",  
                  borderRadius: '20px',
                  padding: '5px 10px',
                }}
                aria-label="explanation"
              >
                <TipsAndUpdatesIcon style={{ color: explanationColor, marginRight: '5px' }} />
                <Typography style={{ fontSize: optionFontSize, color: explanationColor }}>
                  {explanation}
                </Typography>
              </Button>
              {disabled && (
                <IconButton size="small" onClick={handleRemoveExplanation}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              )}
            </Box>
          ) : null}
        </Box>

        <Box display="flex" flexWrap="wrap" gap="10px" marginTop="20px">
          {competencies.map((competency, index) => (
            <Box key={index} display="flex" alignItems="center">
              <Button 
                variant="outlined"
                size="small"
                style={{
                  color: "rgb(102,102,102)", 
                  borderColor: "lightgrey",  
                  backgroundColor: "white",  
                  borderRadius: '20px',
                  padding: '5px 10px',
                }}>
                <Typography style={{ fontSize: '0.7rem', padding: '0 5px' }}>
                  {competency}
                </Typography>
              </Button>
              <IconButton size="small" onClick={() => handleRemoveCompetency(competency)}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>
          ))}
        </Box>

      </FormControl>
    </Box>
  );
};

export default SurveyQuestion;