/**
 * Main Function:
 * - The AddWorkshop component facilitates the addition of new workshop titles to a collection. It is primarily designed to enhance the user interface by allowing dynamic user input for creating or appending workshop titles. The component leverages the Formik library for form handling and Yup for input validation, ensuring a robust and user-friendly experience.
 * 
 * Frontend Operations:
 * - Utilizes Formik for managing form state, handling form submissions, and providing validation functionalities. This simplifies the process of capturing user input and performing subsequent operations based on the form data.
 * - Implements Yup validation schema to enforce input constraints such as minimum and maximum character lengths and uniqueness within the existing workshop titles, thereby ensuring data integrity and preventing duplicates.
 * - Employs Material-UI components for constructing the user interface, offering a consistent look and feel that aligns with modern design standards. This includes TextFields for input fields and Buttons for submission, styled with custom Material-UI sx props for visual customization.
 * - Manages conditional logic to differentiate between the initial addition of a workshop title and subsequent additions. This logic facilitates appropriate updates to both the workshops collection and associated questions, depending on whether it's the user's first submission.
 * - Integrates responsive feedback for form validation, displaying error messages directly within the form to inform users of any violations of the input requirements.
 * 
 * Notable Features:
 * - The component introduces a stateful interaction pattern where the initial state influences subsequent operations. For instance, the first workshop title addition triggers a different behavior in terms of how questions are updated compared to subsequent additions.
 * - Dynamically updates a set of workshops and associated questions based on user input, enhancing the application's interactivity and functionality.
 * - Provides a visually appealing form interface with responsive feedback, leveraging Material-UI's theming and styling capabilities to enhance the user experience.
 * - Incorporates detailed validation feedback through Yup, guiding users to input valid workshop titles and improving the overall usability of the form.
 * 
 */


import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Box, TextField, Button, Typography } from "@mui/material";
import React, {useState} from "react";

const AddWorkshop = ({ setQuestions, initialQuestions, workshops, setWorkshops}) => {

    console.log("workshops?", workshops)

    const initialValues = { workshop: "" };
    const [isFirstTime, setIsFirstTime] = useState(!workshops.length);

    console.log("firstTime?", !workshops.length)

    // Define the Yup validation schema inside the component
    const AddWorkshopSchema = Yup.object().shape({
        workshop: Yup.string()
            .min(2, "The short name must be at least 2 characters long")
            .max(40, "The short name must be at most 40 characters long")
            .required("The workshop title is required")
            .notOneOf(workshops, "This workshop title already exists"), // Ensure unique titles
    });

    const handleSubmit = (values, { resetForm }) => {
  const newWorkshopTitle = values.workshop;

  // Update the set of workshops
  setWorkshops(prevWorkshops => {
      // If this workshop does not already exist in the set, add it
      if (!prevWorkshops.includes(newWorkshopTitle)) {
          return [...prevWorkshops, newWorkshopTitle];
      }
      return prevWorkshops;
  });

  // Determine the next questionId by finding the maximum questionId in the current list and adding one
  const nextQuestionId = initialQuestions.reduce((maxId, question) => Math.max(maxId, parseInt(question.questionId)), -1) + 1;

  if (isFirstTime) {
    // If it's the first time, just update the first set of questions.
    setQuestions(prevQuestions => 
      prevQuestions.map((question, index) => 
        (index < initialQuestions.length || initialQuestions.length === 0) 
        ? { ...question, workshop: newWorkshopTitle } 
        : question
      )
    );
    setIsFirstTime(false);
  } else {
    // If it's not the first time, append a new set of initialQuestions with updated questionIds.
    const newQuestions = initialQuestions.map((question, index) => ({
      ...question,
      questionId: String(nextQuestionId + index), // Update questionId
      workshop: newWorkshopTitle
    }));

    setQuestions(prevQuestions => [...prevQuestions, ...newQuestions]);
  }

  resetForm();
};



  const styles = {
    formGroup: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "1rem",
    },
    invalidFeedback: {
      color: "red",
      marginTop: "0.25rem",
    },
  };

  return (
    <Box ml="20px" mr="20px">
      <Box
        sx={{
          borderRadius:"4px",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={AddWorkshopSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form>
              <div style={styles.formGroup}>
                <TextField
                  id="workshop"
                  label="Enter a title"
                  value={values.workshop}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.workshop && errors.workshop ? true : false}
                  sx={{mb:"15px"}}
                  helperText={touched.workshop && errors.workshop ? errors.workshop : ""}
                />
              </div>

              <Box sx={{mt:"15px"}}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#F7941E",
                    borderRadius: "50px",
                    color: "black",
                    "&:hover": {
                        backgroundColor: "#D17A1D",
                    },
                  }}
                >
                  <Typography variant="h5">ADD</Typography>
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  )
};

export default AddWorkshop;
