import { ResponsiveBar } from "@nivo/bar";

const BarChartReports = ({ data }) => {

  console.log(data)
  const redYellowGreenPalette = [
    'rgb(200, 50, 50)',    // Less intense red
    'rgb(220, 70, 70)',    // Soft red
    'rgb(240, 120, 80)',   // Soft orange-red
    'rgb(252, 180, 100)',  // Light orange
    'rgb(254, 224, 144)',  // Pale yellow-orange
    'rgb(255, 255, 191)',  // Light yellow
    'rgb(217, 239, 139)',  // Pale green-yellow
    'rgb(166, 217, 106)',  // Light green
    'rgb(130, 190, 110)',  // Soft green
    'rgb(80, 170, 90)'     // Less intense green
  ];

  const pastelBlue = 'rgb(173, 216, 230)'; // Normal pastel blue
  const brightPastelBlue = 'rgb(193, 236, 250)'; // Brighter version of pastel blue

  const reversedData = [...data].reverse();
  const maxChoices = data.reduce((max, item) => Math.max(max, item.choices.length), 0);
  const keys = Array.from({ length: maxChoices }, (_, index) => index + 1);

  const getScaleLabel = (id, indexValue) => {
    const questionData = data.find(item => item.shortName === indexValue);
    return questionData && questionData.choices ? questionData.choices[id - 1] : id;
  }

  const getFullQuestion = (indexValue) => {
    const questionData = data.find(item => item.shortName === indexValue);
    return questionData ? questionData.question : '';
  }

  const getCompetencies = (indexValue) => {
    const questionData = data.find(item => item.shortName === indexValue);
    return questionData ? questionData.competencies : [];
  }

  const colorBy = (bar) => {
    const questionData = data.find(item => item.shortName === bar.data.shortName);
    if (!questionData) return 'grey';

    const isMCQ = questionData.correctAnswer !== undefined;

    if (isMCQ) {
        const correctAnswerIndex = questionData.choices.indexOf(questionData.correctAnswer);
        return parseInt(bar.id, 10) === correctAnswerIndex + 1 ? redYellowGreenPalette[9] : redYellowGreenPalette[0];
    }

    const isUnordered = questionData.type === 'radio-unordered' || questionData.type === 'checkbox';
    if (isUnordered) {
      return parseInt(bar.id, 10) % 2 === 0 ? pastelBlue : brightPastelBlue;
    }

    const numChoices = questionData ? questionData.choices.length : 1;
    const choiceIndex = parseInt(bar.id, 10) - 1;
    const responseLabel = getScaleLabel(bar.id, bar.data.shortName);

    if (responseLabel === "Non applicable" || responseLabel === "NA") {
        return 'grey';
    }

    if (numChoices === 2) {
        return choiceIndex === 0 ? redYellowGreenPalette[1] : redYellowGreenPalette[redYellowGreenPalette.length - 3];
    } else {
        const paletteIndex = Math.floor(choiceIndex / (numChoices - 1) * (redYellowGreenPalette.length - 1));
        return redYellowGreenPalette[paletteIndex];
    }
  };

  const getTotalResponses = (questionData) => {
    let totalResponses = 0;
    if (questionData) {
      for (let i = 1; i <= questionData.choices.length; i++) {
        const count = questionData[i];
        if (count !== undefined && !isNaN(count)) {
          totalResponses += count;
        }
      }
    }
    return totalResponses;
  };

  const truncateLabel = (label, maxLength) => {
    if (typeof label !== 'string') return '';
    return label.length > maxLength ? label.slice(0, maxLength) + '...' : label;
  };

  return (
    <ResponsiveBar
      data={reversedData}
      keys={keys}
      indexBy="shortName"
      margin={{ top: 15, right: 50, bottom: 15, left: 150 }}
      layout="horizontal"
      groupMode="stacked"
      padding={0.1}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={colorBy}
      borderColor={{ from: 'bar', modifiers: [] }}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: false,
        legendPosition: "middle",
        legendOffset: -40,
        format: value => truncateLabel(value, 25),
      }}
      enableLabel={true}
      labelSkipWidth={1}
      labelSkipHeight={1}
      labelTextColor="black"
      role="application"
      tooltip={({ id, value, indexValue }) => {
        const questionData = data.find(item => item.shortName === indexValue);
        const totalValidResponses = getTotalResponses(questionData);
        let percentage = totalValidResponses > 0 ? (value / totalValidResponses * 100).toFixed(2) + '%' : '';

        return (
          <div
            style={{
              background: 'white',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              maxWidth: '300px',
              whiteSpace: 'normal',
              zIndex: 1000,
            }}
          >
            <strong style={{ display: 'block', marginBottom: '5px' }}>
              {getFullQuestion(indexValue)}
            </strong>
            <span style={{ display: 'block', marginBottom: '5px' }}>
              {getScaleLabel(id, indexValue)}: {value} ({percentage})
            </span>
            {questionData.competencies && (
              <em style={{ display: 'block', fontStyle: 'italic' }}>
                {getCompetencies(indexValue).map((competency, index) => (
                  <div key={index}>{competency}</div>
                ))}
              </em>
            )}
          </div>
        );
      }}
      barAriaLabel={e => e.id}
    />
  );
};

export default BarChartReports;
