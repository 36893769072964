import { Box, Button, Typography, IconButton, FormControlLabel, Switch, TextField, Grid  } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SurveyQuestion from './SurveyQuestion';
import { Formik, Form } from 'formik';
import { Delete, ArrowUpward, ArrowDownward, Edit, ContentCopy } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from "react";
import AddQuestion from "./AddQuestion";
import AddWorkshop from "./AddWorkshop";
import { predefinedQuestionsImmediateReactionsEn } from "../data/PredefinedQuestionsEn";
import { predefinedQuestionsImmediateReactionsFr } from "../data/PredefinedQuestionsFr";
import { predefinedQuestionsImmediateReactionsDe } from "../data/PredefinedQuestionsDe";
import { predefinedQuestionsImmediateReactionsIt } from "../data/PredefinedQuestionsIt";
import axios from "axios";
import i18n from 'i18next';
import jwt_decode from "jwt-decode";
import { BACKEND_URL } from "../config"; 

const AddImmediateReactions = ({ assessmentName }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { assessmentType, currentAssessmentServerId, currentMonitoringServerId } = location.state || {};

    const getQuestionsForLanguage = (lang) => {
        switch (lang) {
            case 'fr':
                return predefinedQuestionsImmediateReactionsFr;
            case 'de':
                return predefinedQuestionsImmediateReactionsDe;
            case 'it':
                return predefinedQuestionsImmediateReactionsIt;
            case 'en':
            default:
                return predefinedQuestionsImmediateReactionsEn;
        }
    };

    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    const [initialQuestions, setInitialQuestions] = useState(getQuestionsForLanguage(currentLanguage));
    const [questions, setQuestions] = useState([]);
    const [workshops, setWorkshops] = useState([]);
    const [splitWorkshops, setSplitWorkshops] = useState(false);
    const [editingQuestionId, setEditingQuestionId] = useState(null); 


    useEffect(() => {
        const handleLanguageChange = (lang) => {
            setCurrentLanguage(lang);
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);

   useEffect(() => {
    const fetchExistingData = async () => {
        try {
                const token = localStorage.getItem("token");
                const decodedToken = jwt_decode(token);
                const sandbox = decodedToken.sandbox;
                const response = await axios.get(`${BACKEND_URL}/survey`, {
                params: {
                currentAssessmentServerId: currentAssessmentServerId,
                currentMonitoringServerId: currentMonitoringServerId,
                sandbox: sandbox
                }
            });
            
            if (
                response.data &&
                response.data.survey &&
                Array.isArray(response.data.survey) &&
                response.data.survey.length > 0
            ) {
                // Check for workshops values and set splitWorkshops
                const workshopsArray = Array.from(new Set(response.data.survey
                .map(survey => survey.workshop)
                .filter(workshop => workshop)))
                if (Array.isArray(workshopsArray) && workshopsArray.length > 0) {
                    setSplitWorkshops(true);
                    setWorkshops(workshopsArray);
                
                    console.log("splitWorkShop",splitWorkshops)
                    console.log("Workshops",workshops)

                }

                const updatedQuestions = response.data.survey.map(question => ({
                    ...question,
                    options: question.choices.map(choice => ({ label: choice, value: choice })),
                    items: question.items ? question.items.map(item => ({ label: item, value: item })) : [],

                }));
                
                setQuestions(updatedQuestions);
                
            } else {

                setQuestions(getQuestionsForLanguage(currentLanguage));  
                      }
        } catch (error) {
            console.error('Error fetching existing survey data:', error);
        }
    };

    fetchExistingData();
}, [currentMonitoringServerId, currentAssessmentServerId]);

const duplicateQuestion = (questionId) => {
    setQuestions((prevQuestions) => {
        const questionIndex = prevQuestions.findIndex(question => question.questionId === questionId);
        if (questionIndex === -1) return prevQuestions;

        const originalQuestion = prevQuestions[questionIndex];

        // Find the maximum questionId in the current list of questions
        const maxQuestionId = Math.max(...prevQuestions.map(question => question.questionId));
        const newQuestionId = maxQuestionId + 1;

        const newQuestion = { ...originalQuestion, questionId: newQuestionId };

        // Handle the copy label for the duplicated question
        const copyRegex = / \(Copy(?: (\d+))?\)$/;
        const copyMatch = originalQuestion.question.match(copyRegex);
        let newCopyLabel;
        if (copyMatch) {
            const copyNumber = copyMatch[1] ? parseInt(copyMatch[1], 10) + 1 : 2; // Start from 2 if it's a subsequent copy
            newCopyLabel = ` (Copy ${copyNumber})`;
        } else {
            newCopyLabel = ` (Copy)`;
        }

        newQuestion.question = originalQuestion.question.replace(copyRegex, '') + newCopyLabel;
        newQuestion.shortName = originalQuestion.shortName.replace(copyRegex, '') + newCopyLabel;

        // Insert the new question right after the original
        const updatedQuestions = [
            ...prevQuestions.slice(0, questionIndex + 1),
            newQuestion,
            ...prevQuestions.slice(questionIndex + 1),
        ];

        return updatedQuestions;
    });
};

// Editing question
const startEditing = (questionId) => {
    setEditingQuestionId(questionId);
};

const saveEdits = (questionId, newQuestionData) => {
    setQuestions(prevQuestions =>
        prevQuestions.map(question =>
            question.questionId === questionId ? { ...question, ...newQuestionData } : question
        )
    );
    setEditingQuestionId(null);
};

const cancelEdit = () => {
    setEditingQuestionId(null);
};

const addItem = (questionId) => {
    setQuestions(prevQuestions =>
        prevQuestions.map(question =>
            question.questionId === questionId
                ? { ...question, items: [...question.items, { label: '', value: '' }] }
                : question
        )
    );
};

const removeItem = (questionId, itemIndex) => {
    setQuestions(prevQuestions =>
        prevQuestions.map(question =>
            question.questionId === questionId
                ? { ...question, items: question.items.filter((_, index) => index !== itemIndex) }
                : question
        )
    );
};

const handleItemChange = (e, questionId, itemIndex) => {
    const newLabel = e.target.value;
    setQuestions(prevQuestions =>
        prevQuestions.map(question =>
            question.questionId === questionId
                ? {
                      ...question,
                      items: question.items.map((item, index) =>
                          index === itemIndex ? { ...item, label: newLabel, value: newLabel } : item
                      ),
                  }
                : question
        )
    );
};

const addOption = (questionId) => {
    setQuestions(prevQuestions =>
        prevQuestions.map(question =>
            question.questionId === questionId
                ? { ...question, options: [...question.options, { label: '', value: '' }] }
                : question
        )
    );
};

const removeOption = (questionId, optionIndex) => {
    setQuestions(prevQuestions =>
        prevQuestions.map(question =>
            question.questionId === questionId
                ? { ...question, options: question.options.filter((_, index) => index !== optionIndex) }
                : question
        )
    );
};

const handleOptionChange = (e, questionId, optionIndex) => {
    const newLabel = e.target.value;
    setQuestions(prevQuestions =>
        prevQuestions.map(question =>
            question.questionId === questionId
                ? {
                      ...question,
                      options: question.options.map((option, index) =>
                          index === optionIndex ? { ...option, label: newLabel, value: newLabel } : option
                      ),
                  }
                : question
        )
    );
};

const moveQuestion = (workshopName, questionId, direction) => {
    // Filter questions for the specified workshop
    const workshopQuestions = questions.filter(question => question.workshop === workshopName);

    // Find the index of the question to be moved within the workshop
    const questionIndex = workshopQuestions.findIndex(question => question.questionId === questionId);
    if (questionIndex === -1) return; // question not found

    // Check bounds within the workshop's questions
    if ((direction === 'up' && questionIndex === 0) || (direction === 'down' && questionIndex === workshopQuestions.length - 1)) {
        return; // Can't move beyond array bounds within the same workshop
    }

    const moveIndex = direction === 'up' ? questionIndex - 1 : questionIndex + 1;

    // Find the actual indices of these questions in the main questions array
    const actualIndex = questions.findIndex(question => question.questionId === workshopQuestions[questionIndex].questionId && question.workshop === workshopName);
    const actualMoveIndex = questions.findIndex(question => question.questionId === workshopQuestions[moveIndex].questionId && question.workshop === workshopName);

    // Swap the questions in the main array
    const newQuestions = [...questions];
    [newQuestions[actualIndex], newQuestions[actualMoveIndex]] = [newQuestions[actualMoveIndex], newQuestions[actualIndex]];
    setQuestions(newQuestions);
};

const removeQuestion = (workshopName, questionId) => {
    setQuestions(prevQuestions => prevQuestions.filter(question => !(question.workshop === workshopName && question.questionId === questionId)));
};

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    // Adjust the position of the switch base (knob) here if needed
  },
  '& .MuiSwitch-switchBase .MuiSwitch-thumb': {
    // Adjust the position of the thumb (the part that slides) here if needed
    backgroundColor: 'white', // This can be any color that matches your design
    '&:before': {
      // Pseudo-element for the icon
      content: '"*"',
      position: 'absolute',
      top: '60%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '1.3rem', 
      color: 'lightgrey', // Default (off) color
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
    '&:before': {
      color: 'red', // Checked (on) color
    },
  },
  '& .MuiSwitch-track': {
    // Adjust the track styling here if needed
  },
}));

const handleMandatoryChange = (event, questionId) => {
  setQuestions((prevQuestions) =>
    prevQuestions.map((question) =>
      question.questionId === questionId
        ? { ...question, isMandatory: event.target.checked }
        : question
    )
  );
};

const sendSurveyData = async (data) => {
    try {
        const token = localStorage.getItem("token");
        const response = await axios.put(
            `${BACKEND_URL}/monitorings/${currentMonitoringServerId}/assessments/${currentAssessmentServerId}/survey`, 
            data,
            {
            headers: {
                Authorization: `Bearer ${token}`
            }
            }
        );
            return response.data;
    } catch (error) {
        throw new Error('Failed to submit the survey: ' + error.message);
     }
    };

    // Event handler for changing the state of splitWorkshops
    const handleSplitWorkshopsChange = (event) => {
        setSplitWorkshops(event.target.checked);

        if (event.target.checked === false) {
        setQuestions(initialQuestions);
        setWorkshops([]);
    
        }};

    const handleReset = () => {

        setQuestions(initialQuestions);
    }
    
   return (
    <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        minHeight="100vh"
        ml="10px"
        backgroundColor="white"
    >  
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minHeight="5vh"
            sx={{
                backgroundColor: "#fff",
                width: {
                    xs: "90vw",
                    md: "75vw",
                },
            }}
        >
            <Typography ml ="10px" mb="20px" variant="h3" fontWeight="bold">
                Evaluate {assessmentType}
            </Typography>
        </Box>                                            
        
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minHeight="80vh"
            sx={{
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                width: {
                    xs: "90vw",
                    md: "75vw",
                },
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                minHeight="80vh"
                sx={{
                    backgroundColor: "#fff",
                    width: {
                        xs: "90vw",
                        md: "75vw",
                    },
                }}
            >
                <Box
                    flexDirection="column"
                    display="flex"
                    sx={{
                        backgroundColor: "#fff",
                        width: {
                            xs: "45vw",
                            md: "37vw",
                        },
                        height: '78vh',
                        overflowY: 'auto',
                    }}
                >
                    <Formik
                        initialValues={{}} 
                        onSubmit={async (values, { setSubmitting }) => {
                            const surveyData = {
                                questions: questions.map((question, index) => {
                                    return {
                                        questionId: index,
                                        shortName: question.shortName,  
                                        question: question.question,
                                        workshop: question.workshop, 
                                        questionType: question.questionType,
                                        isMandatory: question.isMandatory,
                                        choices: (question.options || []).map(option => option.label),
                                        items: (question.items || []).map(item => item.label),
                                    };
                                }),
                            };

                            try {
                                await sendSurveyData(surveyData, currentMonitoringServerId, currentAssessmentServerId);
                                navigate('/dashboard'); 
                            } catch (error) {
                                console.error('Failed to submit the survey:', error);
                            } finally {
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({ setFieldValue, handleSubmit }) => (
                            <Form>
                                {
                                    // Group the questions by workshop.
                                    Object.entries(questions.reduce((acc, question) => {
                                        if (!acc[question.workshop]) {
                                            acc[question.workshop] = [];
                                        }
                                        acc[question.workshop].push(question);
                                        return acc;
                                    }, {})).map(([workshopName, workshopQuestions]) => (
                                        <div key={workshopName}>
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                {workshopName !== "undefined" && (
                                                    <Typography variant="h4" fontWeight="bold" color="rgb(102,102,102)" m="15px 10px 0px 0px"> 
                                                        {workshopName}
                                                    </Typography>
                                                )}
                                            </Box>
                                            {workshopQuestions.map((question, questionIndex) => (
                                                <Box key={questionIndex} display="flex" alignItems="center">
                                                    {editingQuestionId === question.questionId ? (
                                                        // Edit Interface
                                                        <Box 
                                                            component="div" 
                                                            sx={{ 
                                                                width: '100%', 
                                                                p: 2,
                                                                margin: "20px", 
                                                                borderRadius: '8px', 
                                                                backgroundColor: 'rgb(245,245,245)',
                                                                boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.1)'
                                                            }}
                                                        >
                                                            <Typography mb="20px" variant="h4" fontWeight="bold" sx={{color: "rgb(102,102,102)"}}>Edit question</Typography>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        fullWidth
                                                                        label="Question Title"
                                                                        variant="outlined"
                                                                        InputProps={{
                                                                            style: { backgroundColor: 'white' }
                                                                        }}
                                                                        value={question.question}
                                                                        onChange={e => setQuestions(prevQuestions =>
                                                                            prevQuestions.map(q =>
                                                                                q.questionId === question.questionId ? { ...q, question: e.target.value } : q
                                                                            )
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        fullWidth
                                                                        label="Short Name"
                                                                        variant="outlined"
                                                                        InputProps={{
                                                                            style: { backgroundColor: 'white' }
                                                                        }}
                                                                        value={question.shortName}
                                                                        onChange={e => setQuestions(prevQuestions =>
                                                                            prevQuestions.map(q =>
                                                                                q.questionId === question.questionId ? { ...q, shortName: e.target.value } : q
                                                                            )
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                {question.questionType !== 'text' && (
                                                                    <>
                                                                        <Grid container spacing={2} style={{ marginLeft: '10px', marginTop: '10px' }}>
                                                                            {question.options.map((option, index) => (
                                                                                <Grid item xs={6} key={index}>
                                                                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                                                                        <TextField
                                                                                            fullWidth
                                                                                            label={`Option ${index + 1}`}
                                                                                            variant="outlined"
                                                                                            InputProps={{
                                                                                                style: { backgroundColor: 'white' }
                                                                                            }}
                                                                                            value={option.label}
                                                                                            onChange={(e) => handleOptionChange(e, question.questionId, index)}
                                                                                        />
                                                                                        <IconButton onClick={() => removeOption(question.questionId, index)}>
                                                                                            <Delete />
                                                                                        </IconButton>
                                                                                    </Box>
                                                                                </Grid>
                                                                            ))}
                                                                        </Grid>

                                                                        <Grid container spacing={2} style={{ marginLeft: '10px', marginTop: '10px', justifyContent: "center" }}>
                                                                            <Grid item xs={6}>
                                                                                <Button variant="outlined" sx={{ backgroundColor: 'white'}} onClick={() => addOption(question.questionId)} fullWidth>Add Option</Button>
                                                                            </Grid>
                                                                        </Grid>

                                                                        {question.questionType === 'matrix-radio-ordered' && (
                                                                            <>
                                                                                <Grid container spacing={2} style={{ marginLeft: '10px', marginTop: '10px' }}>
                                                                                    {question.items.map((item, index) => (
                                                                                        <Grid item xs={6} key={index}>
                                                                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                                                                <TextField
                                                                                                    fullWidth
                                                                                                    label={`Item ${index + 1}`}
                                                                                                    variant="outlined"
                                                                                                    InputProps={{
                                                                                                        style: { backgroundColor: 'white' }
                                                                                                    }}
                                                                                                    value={item.label}
                                                                                                    onChange={(e) => handleItemChange(e, question.questionId, index)}
                                                                                                />
                                                                                                <IconButton onClick={() => removeItem(question.questionId, index)}>
                                                                                                    <Delete />
                                                                                                </IconButton>
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    ))}
                                                                                </Grid>

                                                                                <Grid container spacing={2} style={{ marginLeft: '10px', marginTop: '10px', justifyContent: "center" }}>
                                                                                    <Grid item xs={6}>
                                                                                        <Button variant="outlined" sx={{ backgroundColor: 'white'}} onClick={() => addItem(question.questionId)} fullWidth>Add Item</Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                                <Grid item xs={12}>
                                                                    <Box display="flex" justifyContent="space-between">
                                                                        <Button
                                                                            type="button"
                                                                            variant="contained"
                                                                            onClick={() => saveEdits(question.questionId, question)}                                
                                                                            sx={{
                                                                                backgroundColor: "#F7941E",
                                                                                borderRadius: "50px",
                                                                                color: "black",
                                                                                "&:hover": {
                                                                                    backgroundColor: "#D17A1D",
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Typography variant="h5">Save</Typography>
                                                                        </Button>
                                                                        <Button
                                                                            type="button"
                                                                            variant="contained"
                                                                            onClick={cancelEdit}                             
                                                                            sx={{
                                                                                backgroundColor: "#F7941E",
                                                                                borderRadius: "50px",
                                                                                color: "black",
                                                                                "&:hover": {
                                                                                    backgroundColor: "#D17A1D",
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Typography variant="h5">Cancel</Typography>
                                                                        </Button>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    ) : (
                                                        // Existing Display
                                                        <SurveyQuestion
                                                            question={question.question}
                                                            shortName={question.shortName}
                                                            fieldName={question.fieldName}
                                                            options={question.options}
                                                            items={question.items}
                                                            type={question.questionType}
                                                            setFieldValue={setFieldValue}
                                                            titleFontSize="18px"
                                                            optionFontSize="14px"
                                                            disabled={true}
                                                        />
                                                    )}

                                                    {/* Additional controls (up, down, delete, edit, copy, mandatory) */}
                                                    <Box display="flex" flexDirection="column">
                                                        <StyledSwitch
                                                            checked={question.isMandatory}
                                                            onChange={(e) => handleMandatoryChange(e, question.questionId)}
                                                        />                                    
                                                        <IconButton onClick={() => duplicateQuestion(question.questionId)}>
                                                            <ContentCopy />
                                                        </IconButton>
                                                        <IconButton onClick={() => startEditing(question.questionId)}>
                                                            <Edit />
                                                        </IconButton>
                                                        <IconButton onClick={() => removeQuestion(workshopName, question.questionId)}>
                                                            <Delete />
                                                        </IconButton>
                                                        <IconButton onClick={() => moveQuestion(workshopName, question.questionId, 'up')}>
                                                            <ArrowUpward />
                                                        </IconButton>
                                                        <IconButton onClick={() => moveQuestion(workshopName, question.questionId, 'down')}>
                                                            <ArrowDownward />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </div>
                                    ))}
                                <Box mt={5} display="flex" justifyContent="space-between" m="20px">
                                    <>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            onClick={handleReset}                                       
                                            sx={{
                                                backgroundColor: "#F7941E",
                                                borderRadius: "50px",
                                                color: "black",
                                                "&:hover": {
                                                    backgroundColor: "#D17A1D",
                                                },
                                                width: "100px",
                                            }}
                                        >
                                            <Typography variant="h5">RESET</Typography>
                                        </Button>

                                        {questions.length > 0 && (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                onClick={handleSubmit}
                                                sx={{
                                                    backgroundColor: "#F7941E",
                                                    borderRadius: "50px",
                                                    color: "black",
                                                    "&:hover": {
                                                        backgroundColor: "#D17A1D",
                                                    },
                                                }}
                                            >
                                                <Typography variant="h5">VALIDATE</Typography>
                                            </Button>
                                        )}
                                    </>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>

                <Box
                    flexDirection="column"
                    display="flex"
                    sx={{
                        backgroundColor: "#fff",
                        width: {
                            xs: "45vw",
                            md: "37vw",
                        },
                        height: '78vh',
                        overflowY: 'auto',
                    }}
                >
                    <Box
                        flexDirection="column"
                        display="flex"
                        sx={{
                            backgroundColor: "#fff",
                            marginTop: "20px",
                            paddingLeft: "20px",
                        }}
                    >
                        <FormControlLabel
                            control={<Switch checked={splitWorkshops} onChange={handleSplitWorkshopsChange} />}
                            label="I have several workshops"
                        /> 
                    </Box>
    
                    {splitWorkshops && (
                        <Box
                            flexDirection="column"
                            display="flex"
                            sx={{
                                backgroundColor: "#fff"
                            }}
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                ml="20px"
                                mt="20px"
                            >
                                <Typography mb="20px" variant="h4" fontWeight="bold" sx={{color: "rgb(102,102,102)"}}>Add a workshop</Typography>
                            </Box>
                            <AddWorkshop setQuestions={setQuestions} initialQuestions={initialQuestions} workshops={workshops} setWorkshops={setWorkshops}/>
                        </Box>
                    )}

                    <Box
                        flexDirection="column"
                        display="flex"
                        sx={{
                            backgroundColor: "#fff",
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            ml="20px"
                            mt="20px"
                        >
                            <Typography mb="20px" variant="h4" fontWeight="bold" sx={{color: "rgb(102,102,102)"}}>Create a new question</Typography>
                        </Box>

                        <AddQuestion 
                            setQuestions={setQuestions} 
                            questions={questions} 
                            assessmentType={assessmentType} 
                            splitWorkshops={splitWorkshops} 
                            workshops={workshops} 
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    </Box>
);

};

export default AddImmediateReactions;