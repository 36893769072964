/**
 * Main Function:
 * - The AddLog component allows users to add new logs to their monitoring activities. It is designed to capture detailed information about training activities, including descriptions, associated days, assessments, and log types. This component aims to facilitate the documentation and analysis of training or educational progress over time.
 * 
 * Frontend Operations:
 * - Utilizes Formik for form handling, providing a structured way to capture user input with built-in validation using a Yup validation schema. This ensures that all user submissions meet predefined criteria for each log entry field.
 * - Employs Material-UI components for the user interface, such as TextField for text inputs, Select for dropdown selections, and Button for form submission, ensuring a consistent and accessible user experience.
 * - Manages state with React's useState hook for local states like monitorings, currentMonitoring, uniqueDays, and also uses useEffect for side effects, such as fetching monitoring data on component mount or update.
 * - Incorporates conditional rendering and dynamic field updates based on user selections, enhancing the form's interactivity and relevance to specific monitoring contexts.
 * 
 * Backend Operations:
 * - Fetches monitoring data from a backend service upon component initialization or when the current user ID changes. This is performed through an axios GET request to `${BACKEND_URL}/monitorings/${currentUserId}`, using JWT tokens for authorization.
 * - Submits new log entries to the backend upon form submission. This involves constructing a log entry object from the form values and sending it via an axios POST request to `${BACKEND_URL}/logs/${currentMonitoringId}`, again using JWT for authorization.
 * - Dynamically retrieves and updates the list of unique days for assessments tied to a selected monitoring, facilitating accurate and relevant log entries associated with specific days and activities.
 * 
 */


import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Box,  TextField, Button, Select, MenuItem, InputLabel, Typography, FormControl, IconButton, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { BACKEND_URL } from "../config"; 

const AddLogSchema = Yup.object().shape({
  monitoring: Yup.string()
    .required("Monitoring is required"),
  description: Yup.string()
    .min(5, "Description must be at least 5 characters long")
    .max(100, "Description must be at maximum 100 characters long")
    .required("Description is required"),
  day: Yup.string()
    .required("Day is required"),
  assessment: Yup.string()
    .required("Assessment is required"),
  logType: Yup.string()  
    .required("Log type is required"),
  module: Yup.string()
});

const AddLog = ({ 
    logs, 
    setLogs, 
    currentMonitoringId, 
    setCurrentMonitoringId,
    currentUserId,
    setCurrentUserId }) => {

  const [monitorings, setMonitorings] = useState([]);
  const [currentMonitoring, setCurrentMonitoring] = useState('');
  const [uniqueDays, setUniqueDays] = useState([]);



  // Get all the monitorings of the current user when page loads
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log('No token found');
      return;
    }
    const decodedToken = jwt_decode(token);
    setCurrentUserId(decodedToken._id)

    const fetchMonitorings = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(
              `${BACKEND_URL}/monitorings/${decodedToken._id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            );

            if (response.data.monitorings) {
                const fetchedMonitorings = response.data.monitorings;
                setMonitorings(fetchedMonitorings);

                if (currentMonitoring !== '') {
                    const foundMonitoring = fetchedMonitorings.find(monitoring => monitoring.name === currentMonitoring);
                    if (foundMonitoring) {
                        setCurrentMonitoringId(foundMonitoring._id);

                        // Extract unique days from assessments
                        const daysFromAssessments = foundMonitoring.assessments.map(assessment => assessment.day);
                        const uniqueDaysFromAssessments = [...new Set(daysFromAssessments)];
                        setUniqueDays(uniqueDaysFromAssessments);
                    }
                }
              }

        } catch (error) {
            console.log(error);
        }
        };

    fetchMonitorings(); 
  }, [currentUserId, currentMonitoring]); 

  useEffect(() => {
    const fetchLogs = async () => {
        if(!currentMonitoringId) return; // add this line

        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(
              `${BACKEND_URL}/logs/${currentMonitoringId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            );
            if(response.data === "No logs found for this monitoring") { 
                setLogs([]); 
            } else {
                setLogs(response.data[0].logs);
            }
        } catch (error) {
            console.log(error);
            setLogs([]);  // In case of an error, you can choose to set logs to an empty array
        }
    };
    fetchLogs();
}, [currentMonitoringId]);


  const initialValues = { monitoring: "",
                          description: "",
                          day: "",
                          assessment:"",
                          logType: "" };

 const handleSubmit = async (values, { setSubmitting, resetForm }) => {
  
  const newLog = {
    description: values.description,
    day: values.day,
    assessment: values.assessment,
    logType: values.logType,
  };

  // Add the new log to the array first
  const updatedLogs = [...logs, newLog];

  // Then update the state
  setLogs(updatedLogs);
  resetForm({
    values: {
      monitoring: values.monitoring,  // Keep monitoring value
      description: "",
      day: "",
      assessment:"",
      logType: ""
    }
  });

  const serverLogs = {
    userId: currentUserId, 
    monitoringId: currentMonitoringId,
    logs: updatedLogs,
  };

  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${BACKEND_URL}/logs/${currentMonitoringId}`,
      serverLogs,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if(response.status === 200) {
      console.log('Log created successfully');
    } else {
      console.log('An error occurred while creating the log:', response);
    }
  } catch (error) {
    console.log('An error occurred while creating the log:', error);
  }
  
  setSubmitting(false);
};

  const styles = {
    formGroup: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "1rem",
    },
    invalidFeedback: {
      color: "red",
      marginTop: "0.25rem",
    },
  };
  

  return (
    <Box m="20px">
      <Box
      display="flex"
      flexDirection="column"
      p= "10px"
      borderRadius="4px"
      sx = {{width:"50%"}}>

        
        <Formik
            initialValues={initialValues}
            validationSchema={AddLogSchema}
            onSubmit={handleSubmit}
        >
        {({ values, errors, touched, handleChange, handleBlur }) => (
        <Form>
            <FormControl fullWidth>
                <InputLabel id="monitoring">Choose a monitoring</InputLabel>
                <Select
                    labelId="monitoring"
                    id="monitoring"
                    name="monitoring"
                    value={values.monitoring}
                    label="Choose a monitoring"
                    onChange={e => {
                        handleChange(e);
                        setCurrentMonitoring(e.target.value);
                    }}
                    onBlur={handleBlur}
                    error={touched.monitoring && errors.monitoring ? true : false}
                    sx={{mb:"30px"}}
                    >
                    {monitorings.map(monitoring => (
                        <MenuItem key={monitoring._id} value={monitoring.name}>
                            {monitoring.name}
                        </MenuItem>
                    ))}
                    </Select>
              </FormControl>

              <Typography mb="20px" variant="h3">Add an entry</Typography>

                <TextField
                  id="description"
                  label="Enter a description"
                  value={values.description}
                  fullWidth
                  multiline
                  rows={4}
                  sx={{mb:"15px"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.description && errors.description ? true : false}
                  helperText={touched.description && errors.description ? errors.description : ""}
                />
             
              <FormControl fullWidth>
                <InputLabel id="day">For session...</InputLabel>
                <Select
                  labelId="day"
                  id="day"
                  name="day"
                  value={values.day}
                  label="For session..."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.day && errors.day ? true : false}
                  sx={{mb:"15px"}}
                >
                  {uniqueDays.map(day => (
                    <MenuItem key={day} value={`Day ${day}`}>Day {day}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="assessment">Concerns...</InputLabel>
                <Select
                  labelId="assessment"
                  id="assessment"
                  name="assessment"
                  value={values.assessment}
                  label="Concerns..."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.assessment && errors.assessment ? true : false}
                  sx={{mb:"15px"}}
                >
                  <MenuItem value="General">General</MenuItem>
                  <MenuItem value="Trainee characteristics">Trainee characteristics</MenuItem>
                  <MenuItem value="Training characteristics">Training characteristics</MenuItem>
                  <MenuItem value="Immediate reactions">Immediate reactions</MenuItem>
                  <MenuItem value="Learning">Learning</MenuItem>
                  <MenuItem value="Organizational conditions">Organizational conditions</MenuItem>
                  <MenuItem value="Behavioral changes">Behavorial changes</MenuItem>
                  <MenuItem value="Student characteristics">Student characteristics</MenuItem>
                  <MenuItem value="Student learning outcomes">Student learning outcomes</MenuItem>
                </Select>
              </FormControl>

               <FormControl fullWidth>
                <InputLabel id="logType">Choose the type of log</InputLabel>
                <Select
                  labelId="logType"
                  id="logType"
                  name="logType"
                  value={values.logType}
                  label="Choose the type of log"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.logType && errors.logType ? true : false}
                  sx={{mb:"15px"}}
                >
                  <MenuItem value="observation">Observation</MenuItem>
                  <MenuItem value="change">Change</MenuItem>
                 
                </Select>
              </FormControl>
          

                <Box sx={{mt:"15px"}}>
                  <Button
                        type="submit"
                        variant="contained"
                        sx={{
                            backgroundColor: "#F7941E",
                            borderRadius: "50px",
                            color: "black",
                            "&:hover": {
                                backgroundColor: "#D17A1D",
                            },
                        }}
                    >
                        <Typography variant="h5">ADD</Typography>
                    </Button>
                </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  )
};

export default AddLog;
