import { Box, TextField, Button, Typography, Select, MenuItem } from "@mui/material";
import Alert from "@mui/material/Alert";
import React, { useState, useContext, useEffect } from "react";
import logo_dtc from "../../assets/logo.svg";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../contexts/AuthContext";
import { BACKEND_URL } from "../../config"; 
import { useTranslation } from 'react-i18next';

const Signin = () => {
  const [data, setData] = useState({ email: "", password: "" });
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  const { t } = useTranslation('translation');
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en');
  const [lng, setLng] = useState(i18n.language);

  useEffect(() => {
    const handleLanguageChange = (newLng) => {
      setLng(newLng);
    };

    // Attach the event listener
    i18n.on('languageChanged', handleLanguageChange);

    // Clean up on component unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('selectedLanguage', language); 
    setSelectedLanguage(language);
  };

  useEffect(() => {
    if (location.state && location.state.emailVerified) {
        setMessage({ type: "success", text: t('signin.email_success') });
    }
}, [location]);

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.post(`${BACKEND_URL}/signin`, data);
      localStorage.setItem("token", res.data.token);
      setIsAuthenticated(true);
      navigate("/dashboard");
    } catch (error) {
      if (error.response && error.response.status >= 400 && error.response.status <= 500) {
        setMessage({ type: "error", text: error.response.data.message });
      }
    }
  };

  const handleForgotPassword = async () => {
    try {
      const res = await axios.post(`${BACKEND_URL}/forgot-password`, { email: data.email });
      setMessage({ type: "success", text: t('signin.email_sent') });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setMessage({ type: "error", text: error.response.data.message });
      } else {
        setMessage({ type: "error", text: t('signin.email_error') });
      }
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="stretch"
        justifyContent="center"
        sx={{
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "15px",
          backgroundColor: "#fff",
          width: {
            xs: "90vw",
            md: "50vw",
          },
        }}
        component="form"
        onSubmit={handleSubmit}
      >
    
        <Box flexGrow={2} flexBasis={0} padding="60px">
          <Box display="flex" justifyContent="flex-end">
            <Select
                  value={selectedLanguage}
                  onChange={(e) => {
                      // Handle the language change event
                      handleLanguageChange(e.target.value);
                  }}
                  size="small"
                  sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                >
                  <MenuItem value="en">
                    <span className="fi fi-gb"></span>      
                  </MenuItem>
                  <MenuItem value="fr">
                    <span className="fi fi-fr"></span>    
                  </MenuItem>
                  <MenuItem value="de">
                    <span className="fi fi-de"></span>      
                </MenuItem>
                <MenuItem value="it">
                  <span className="fi fi-it"></span>    
                </MenuItem>
              </Select>
          </Box>
          <Box
            mb="20px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box>
              <img
                alt=""
                width="100%"
                height="100%"
                src={logo_dtc}
                style={{ cursor: "pointer", borderRadius: "0%" }}
              />
            </Box>
          </Box>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('signin.email')}
            name="email"
            autoComplete="email"
            value={data.email}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('signin.password')}
            type="password"
            id="password"
            autoComplete="current-password"
            value={data.password}
            onChange={handleChange}
          />
          <Box mt="10px" mb="10px">
            {message && <Alert severity={message.type}>{message.text}</Alert>}
          </Box>
          <Typography
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleForgotPassword()}
          >
            {t('signin.forgot_password')}

          </Typography>

          <Box mt={5} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#F7941E",
                borderRadius: "50px",
                color: "black",
                "&:hover": {
                  backgroundColor: "#D17A1D",
                },
              }}
            >
              <Typography variant="h5">{t('signin.signin')}</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Signin;
