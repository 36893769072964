/**
 * Main Function:
 * - The PreviewSurvey component is designed for previewing survey questions within a specific assessment, allowing for a comprehensive review before final submission or further editing. It showcases questions by workshops if applicable, offering a segmented view of the survey.
 * 
*/

import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import SurveyQuestion from '../../components/SurveyQuestion';
import logo_dtc from "../../assets/logo.svg";
import jwt_decode from "jwt-decode";
import { BACKEND_URL } from "../../config";

const MemoizedSurveyQuestion = React.memo(SurveyQuestion);

const PreviewSurvey = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentAssessmentServerId, currentMonitoringServerId } = location.state || {};
  const [surveyData, setSurveyData] = useState([]);
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const fetchSurveyData = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodedToken = jwt_decode(token);
        const sandbox = decodedToken.sandbox;
        const response = await axios.get(`${BACKEND_URL}/survey`, {
          params: {
            currentAssessmentServerId: currentAssessmentServerId,
            currentMonitoringServerId: currentMonitoringServerId,
            sandbox: sandbox
          }
        });

        const processedData = response.data.survey.map(question => ({
          ...question,
          choices: question.choices.map(choice => ({ value: choice, label: choice })),
          items: question.items ? question.items.map(item => ({ label: item, value: item })) : [],

        }));

        const initialValues = processedData.reduce((values, question) => {
          let defaultValue = "";
          if (["radio-ordered", "radio-unordered"].includes(question.questionType)) {
            defaultValue = question.choices[0].value;
          }
          return { ...values, [`q${question.questionId}`]: defaultValue };
        }, {});

        setSurveyData(processedData);
        setInitialValues(initialValues);
      } catch (error) {
        console.error('Error fetching survey data:', error);
      }
    };

    fetchSurveyData();
  }, [currentAssessmentServerId, currentMonitoringServerId]);

  const workshops = useMemo(() => {
    return surveyData.reduce((workshops, question) => {
      if (!workshops[question.workshop]) {
        workshops[question.workshop] = [];
      }
      workshops[question.workshop].push(question);
      return workshops;
    }, {});
  }, [surveyData]);

  return (
    <>
      <Box
        m="40px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box>
          <img
            alt=""
            width="100%"
            height="100%"
            src={logo_dtc}
            style={{ cursor: 'pointer', borderRadius: '0%' }}
          />
        </Box>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center">
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', borderRadius: '15px', padding: '20px', backgroundColor: '#fff', width: { xs: '90vw', md: '70vw' }}}>
          <Formik initialValues={initialValues}>
            {({ values, setFieldValue }) => (
              <Form>
                {Object.entries(workshops).map(([workshopName, questions]) => (
                  <Box key={workshopName}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      {workshopName !== "undefined" && (
                        <Typography variant="h2" fontWeight="bold" color="rgb(102,102,102)" m="15px 10px 0px 0px">
                          {workshopName}
                        </Typography>
                      )}
                    </Box>
                    {questions.map((question, index) => (
                      <MemoizedSurveyQuestion
                        key={index}
                        imageUrl={question.imageUrl}
                        question={question.question}
                        correctAnswer={question.correctAnswer}
                        explanation={question.explanation}
                        workshop={question.workshop}
                        fieldName={`q${question.questionId}`}
                        type={question.questionType}
                        isMandatory={question.isMandatory}
                        options={question.choices}
                        items={question.items}
                        setFieldValue={setFieldValue}
                        displayCorrectAnswer={false}
                        disabled={false}
                        matrixFullWidth={true}
                      />
                    ))}
                  </Box>
                ))}

                <Box mt={5} display="flex" justifyContent="center">
                  {surveyData.length > 0 && (
                    <Button onClick={() => navigate('/dashboard')} variant="contained" sx={{
                        backgroundColor: '#F7941E',
                        borderRadius: '50px',
                        color: 'black',
                        '&:hover': { backgroundColor: '#D17A1D' },
                    }}>
                      <Typography variant="h5">BACK</Typography>
                    </Button>
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default PreviewSurvey;
