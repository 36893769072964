export const predefinedQuestionsTraineeCharacteristicsEn = [
  {
    questionId: '0',
    question:
      "To what extent are you motivated to participate in this training?",
    shortName: "Motivation",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    options: [
      { value: "Not motivated at all", label: "Not motivated at all" },
      { value: "Not very motivated", label: "Not very motivated" },
      { value: "Somewhat unmotivated", label: "Somewhat unmotivated" },
      {
        value: "Neither motivated nor unmotivated",
        label: "Neither motivated nor unmotivated",
      },
      { value: "Somewhat motivated", label: "Somewhat motivated" },
      { value: "Very motivated", label: "Very motivated" },
      { value: "Extremely motivated", label: "Extremely motivated" },
    ],
  },
  {
    questionId: '1',
    question:
      "To what extent do you consider technology to be beneficial for your work?",
    shortName: "Technology's benefits",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    options: [
      { value: "Not beneficial at all", label: "Not beneficial at all" },
      { value: "Not very beneficial", label: "Not very beneficial" },
      { value: "Somewhat not beneficial", label: "Somewhat not beneficial" },
      {
        value: "Neither beneficial nor detrimental",
        label: "Neither beneficial nor detrimental",
      },
      { value: "Somewhat beneficial", label: "Somewhat beneficial" },
      { value: "Very beneficial", label: "Very beneficial" },
      { value: "Extremely beneficial", label: "Extremely beneficial" },
    ],
  },
  {
    questionId: '2',
    question:
      "To what extent do the opinions of others influence your decision to participate in this training?",
    shortName: "Peer influence",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    options: [
      { value: "Not influenced at all", label: "Not influenced at all" },
      { value: "Not very influenced", label: "Not very influenced" },
      { value: "Somewhat not influenced", label: "Somewhat not influenced" },
      {
        value: "Neither influenced nor not influenced",
        label: "Neither influenced nor not influenced",
      },
      { value: "Somewhat influenced", label: "Somewhat influenced" },
      { value: "Very influenced", label: "Very influenced" },
      { value: "Extremely influenced", label: "Extremely influenced" },
    ],
  },
  {
    questionId: '3',
    question:
      "To what extent was your enrollment in this training a personal choice rather than an obligation?",
    shortName: "Personal choice",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    options: [
      { value: "Completely an obligation", label: "Completely an obligation" },
      { value: "More of an obligation", label: "More of an obligation" },
      { value: "Somewhat an obligation", label: "Somewhat an obligation" },
      {
        value: "Neither a personal choice nor an obligation",
        label: "Neither a personal choice nor an obligation",
      },
      {
        value: "Somewhat a personal choice",
        label: "Somewhat a personal choice",
      },
      {
        value: "More of a personal choice",
        label: "More of a personal choice",
      },
      {
        value: "Completely a personal choice",
        label: "Completely a personal choice",
      },
    ],
  },
  {
    questionId: '4',
    question:
      "To what extent do you feel capable of using computer technologies to accomplish your tasks?",
    shortName: "Tech confidence",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    options: [
      { value: "Not capable at all", label: "Not capable at all" },
      { value: "Not very capable", label: "Not very capable" },
      { value: "Somewhat not capable", label: "Somewhat not capable" },
      {
        value: "Neither capable nor incapable",
        label: "Neither capable nor incapable",
      },
      { value: "Somewhat capable", label: "Somewhat capable" },
      { value: "Very capable", label: "Very capable" },
      { value: "Extremely capable", label: "Extremely capable" },
    ],
  },
];


export const predefinedQuestionsTrainingCharacteristicsEn = [

  {
    questionId: '0',
    question: "How would you rate the trainer's ability to engage the audience?",
    shortName: "Engagement",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    options: [
      { value: "Not engaging at all", label: "Not engaging at all" },
      { value: "Not very engaging", label: "Not very engaging" },
      { value: "Somewhat unengaging", label: "Somewhat unengaging" },
      { value: "Neither engaging nor unengaging", label: "Neither engaging nor unengaging" },
      { value: "Somewhat engaging", label: "Somewhat engaging" },
      { value: "Very engaging", label: "Very engaging" },
      { value: "Extremely engaging", label: "Extremely engaging" }
    ]
  },
  {
    questionId: '1',
    question: "How would you rate the trainer's ability to manage time?",
    shortName: "Time Management",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    options: [
      { value: "Very poor time management", label: "Very poor time management" },
      { value: "Poor time management", label: "Poor time management" },
      { value: "Somewhat poor time management", label: "Somewhat poor time management" },
      { value: "Average time management", label: "Average time management" },
      { value: "Good time management", label: "Good time management" },
      { value: "Very good time management", label: "Very good time management" },
      { value: "Excellent time management", label: "Excellent time management" }
    ]
  },

  {
    questionId: '2',
    question: "How would you rate the suitability of the trainer's teaching methods?",
    shortName: "Teaching Methods",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    options: [
      { value: "Not suitable at all", label: "Not suitable at all" },
      { value: "Not very suitable", label: "Not very suitable" },
      { value: "Somewhat unsuitable", label: "Somewhat unsuitable" },
      { value: "Neutral", label: "Neither suitable nor unsuitable" },
      { value: "Somewhat suitable", label: "Somewhat suitable" },
      { value: "Very suitable", label: "Very suitable" },
      { value: "Extremely suitable", label: "Extremely suitable" }
    ]
  },

  {
    questionId: '3',
    question: "How would you rate the suitability of the learning environment?",
    shortName: "Environment",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    options: [
      { value: "Not suitable at all", label: "Not suitable at all" },
      { value: "Not very suitable", label: "Not very suitable" },
      { value: "Somewhat unsuitable", label: "Somewhat unsuitable" },
      { value: "Neither suitable nor unsuitable", label: "Neither suitable nor unsuitable" },
      { value: "Somewhat suitable", label: "Somewhat suitable" },
      { value: "Very suitable", label: "Very suitable" },
      { value: "Extremely suitable", label: "Extremely suitable" }
    ]
  },

  {
    questionId: '4',
    question: "How would you rate the suitability of the instructional tools?",
    shortName: "Tool Suitability",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    options: [
      { value: "Not suitable at all", label: "Not suitable at all" },
      { value: "Not very suitable", label: "Not very suitable" },
      { value: "Somewhat unsuitable", label: "Somewhat unsuitable" },
      { value: "Neither suitable nor unsuitable", label: "Neither suitable nor unsuitable" },
      { value: "Somewhat suitable", label: "Somewhat suitable" },
      { value: "Very suitable", label: "Very suitable" },
      { value: "Extremely suitable", label: "Extremely suitable" }
    ]
  },

  {
    questionId: '5',
    question: "How would you rate the trainer's ability to adapt their teaching methods to suit the audience?",
    shortName: "Pedagogy",
    workshop: "",
    fieldName: "q6",
    questionType: "radio-ordered",
    options: [
      { value: "Not adapted at all", label: "Not adapted at all" },
      { value: "Rarely adapted", label: "Rarely adapted" },
      { value: "Somewhat unadapted", label: "Somewhat unadapted" },
      { value: "Neither adapted nor unadapted", label: "Neither adapted nor unadapted" },
      { value: "Somewhat adapted", label: "Somewhat adapted" },
      { value: "Usually adapted", label: "Usually adapted" },
      { value: "Always adapted", label: "Always adapted" }
    ]
  }

]

export const predefinedQuestionsOrganizationalConditionsEn = [
  {
    questionId: '0',
    question: "At what level do you estimate your need for necessary equipment to effectively integrate training resources into your practice?",
    shortName: "Material resources",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    organizationalType: "Material resources",
    options: [
      { "value": "Very high", "label": "Very high" },
      { "value": "High", "label": "High" },
      { "value": "Somewhat high", "label": "Somewhat high" },
      { "value": "Medium", "label": "Medium" },
      { "value": "Somewhat low", "label": "Somewhat low" },
      { "value": "Low", "label": "Low" },
      { "value": "Very low", "label": "Very low" }
    ]
  },
  {
    questionId: '1',
    question: "At what level do you estimate your need for available time to integrate training resources into your practice?",
    shortName: "Temporal resources",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    organizationalType: "Temporal resources",
    options: [
      { "value": "Very high", "label": "Very high" },
      { "value": "High", "label": "High" },
      { "value": "Somewhat high", "label": "Somewhat high" },
      { "value": "Medium", "label": "Medium" },
      { "value": "Somewhat low", "label": "Somewhat low" },
      { "value": "Low", "label": "Low" },
      { "value": "Very low", "label": "Very low" }
    ]
  },
  {
    questionId: '2',
    question: "At what level do you estimate your need for support from your hierarchy to integrate training resources into your practice?",
    shortName: "Hierarchical support",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    organizationalType: "Hierarchical support",
    options: [
      { "value": "Very high", "label": "Very high" },
      { "value": "High", "label": "High" },
      { "value": "Somewhat high", "label": "Somewhat high" },
      { "value": "Medium", "label": "Medium" },
      { "value": "Somewhat low", "label": "Somewhat low" },
      { "value": "Low", "label": "Low" },
      { "value": "Very low", "label": "Very low" }
    ]
  },
  {
    questionId: '3',
    question: "At what level do you estimate your need for support from your colleagues to integrate training resources into your practice?",
    shortName: "Colleague support",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    organizationalType: "Colleague support",
    options: [
      { "value": "Very high", "label": "Very high" },
      { "value": "High", "label": "High" },
      { "value": "Somewhat high", "label": "Somewhat high" },
      { "value": "Medium", "label": "Medium" },
      { "value": "Somewhat low", "label": "Somewhat low" },
      { "value": "Low", "label": "Low" },
      { "value": "Very low", "label": "Very low" }
    ]
  },
  {
    questionId: '4',
    question: "At what level do you estimate your need for technical support to integrate training resources into your practice?",
    shortName: "Technical support",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    organizationalType: "Technical support",
    options: [
      { "value": "Very high", "label": "Very high" },
      { "value": "High", "label": "High" },
      { "value": "Somewhat high", "label": "Somewhat high" },
      { "value": "Medium", "label": "Medium" },
      { "value": "Somewhat low", "label": "Somewhat low" },
      { "value": "Low", "label": "Low" },
      { "value": "Very low", "label": "Very low" }
    ]
  },
  {
    questionId: '5',
    question: "At what level do you estimate your need for mentoring (for example, with a more experienced colleague) to integrate training resources into your practice?",
    shortName: "Mentoring",
    workshop: "",
    fieldName: "q6",
    questionType: "radio-ordered",
    organizationalType: "Mentoring",
    options: [
      { "value": "Very high", "label": "Very high" },
      { "value": "High", "label": "High" },
      { "value": "Somewhat high", "label": "Somewhat high" },
      { "value": "Medium", "label": "Medium" },
      { "value": "Somewhat low", "label": "Somewhat low" },
      { "value": "Low", "label": "Low" },
      { "value": "Very low", "label": "Very low" }
    ]
  }
]

export const predefinedQuestionsImmediateReactionsEn = [
  {
    questionId: '0',
    question: "How much did you enjoy the workshop?",
    shortName: "Perceived Interest",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    options: [
      { value: "Did not enjoy at all", label: "Did not enjoy at all" },
      { value: "Not very enjoyable", label: "Not very enjoyable" },
      { value: "Somewhat enjoyable", label: "Somewhat enjoyable" },
      { value: "Neutral", label: "Neutral" },
      { value: "Quite enjoyable", label: "Quite enjoyable" },
      { value: "Very enjoyable", label: "Very enjoyable" },
      { value: "Extremely enjoyable", label: "Extremely enjoyable" }
    ]
  },
  {
    questionId: '1',
    question: "How useful did you find the workshop?",
    shortName: "Perceived Usefulness",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    options: [
      { value: "Not useful at all", label: "Not useful at all" },
      { value: "Slightly useful", label: "Slightly useful" },
      { value: "Moderately useful", label: "Moderately useful" },
      { value: "Neutral", label: "Neutral" },
      { value: "Quite useful", label: "Quite useful" },
      { value: "Very useful", label: "Very useful" },
      { value: "Extremely useful", label: "Extremely useful" }
    ]
  },
  {
    questionId: '2',
    question: "How easy do you find integrating what you've seen into a classroom activity?",
    shortName: "Perceived Ease of Use",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    options: [
      { value: "Not easy at all", label: "Not easy at all" },
      { value: "Somewhat difficult", label: "Somewhat difficult" },
      { value: "Moderately easy", label: "Moderately easy" },
      { value: "Neutral", label: "Neutral" },
      { value: "Quite easy", label: "Quite easy" },
      { value: "Very easy", label: "Very easy" },
      { value: "Extremely easy", label: "Extremely easy" }
    ]
  },
  {
    questionId: '3',
    question: "How confident do you feel about integrating what you've seen into a classroom activity?",
    shortName: "Self-Efficacy",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    options: [
      { value: "Not confident at all", label: "Not confident at all" },
      { value: "Slightly confident", label: "Slightly confident" },
      { value: "Moderately confident", label: "Moderately confident" },
      { value: "Neutral", label: "Neutral" },
      { value: "Quite confident", label: "Quite confident" },
      { value: "Very confident", label: "Very confident" },
      { value: "Extremely confident", label: "Extremely confident" }
    ]
  },
  {
    questionId: '4',
    question: "To what extent do you intend to use what you've seen to conduct a classroom activity?",
    shortName: "Behavioral Intention",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    options: [
      { value: "Do not intend to use at all", label: "Do not intend to use at all" },
      { value: "Slightly intend to use", label: "Slightly intend to use" },
      { value: "Moderately intend to use", label: "Moderately intend to use" },
      { value: "Neutral", label: "Neutral" },
      { value: "Quite intend to use", label: "Quite intend to use" },
      { value: "Very much intend to use", label: "Very much intend to use" },
      { value: "Definitely intend to use", label: "Definitely intend to use" }
    ]
  }
];

export const predefinedQuestionsSustainabilityConditionsEn = [
  {
    questionId: '0',
    question: "How compatible is the introduced material with your prior teaching practices?",
    shortName: "Compatibility",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    options: [
      { value: "Not compatible at all", label: "Not compatible at all" },
      { value: "Slightly compatible", label: "Slightly compatible" },
      { value: "Somewhat compatible", label: "Somewhat compatible" },
      { value: "Neutral", label: "Neutral" },
      { value: "Moderately compatible", label: "Moderately compatible" },
      { value: "Very compatible", label: "Very compatible" },
      { value: "Extremely compatible", label: "Extremely compatible" }
    ]
  },
  {
    questionId: '1',
    question: "How do you find your students progress with the new materials?",
    shortName: "Student Progress",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    options: [
      { value: "Declined significantly", label: "Declined significantly" },
      { value: "Declined slightly", label: "Declined slightly" },
      { value: "Remained the same", label: "Remained the same" },
      { value: "Improved slightly", label: "Improved slightly" },
      { value: "Improved moderately", label: "Improved moderately" },
      { value: "Improved significantly", label: "Improved significantly" }
    ]
  },
  {
    questionId: '2',
    question: "How would you rate your sense of self-efficacy in using the new resources?",
    shortName: "Self-Efficacy",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    options: [
      { value: "Not confident at all", label: "Not confident at all" },
      { value: "Slightly confident", label: "Slightly confident" },
      { value: "Somewhat confident", label: "Somewhat confident" },
      { value: "Neutral", label: "Neutral" },
      { value: "Moderately confident", label: "Moderately confident" },
      { value: "Very confident", label: "Very confident" },
      { value: "Extremely confident", label: "Extremely confident" }
    ]
  },
  {
    questionId: '3',
    question: "How mentally taxing do you find implementing the new resources?",
    shortName: "Cognitive Strain",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    options: [
      { value: "Extremely taxing", label: "Extremely taxing" },
      { value: "Very taxing", label: "Very taxing" },
      { value: "Quite taxing", label: "Quite taxing" },
      { value: "Moderately taxing", label: "Moderately taxing" },
      { value: "Slightly taxing", label: "Slightly taxing" },
      { value: "Not taxing at all", label: "Not taxing at all" }
    ]
  },
  {
    questionId: '4',
    question: "To what extent do the new resources increase your workload?",
    shortName: "Workload Impact",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    options: [
      { value: "Massively increased", label: "Massively increased" },
      { value: "Significantly increased", label: "Significantly increased" },
      { value: "Quite increased", label: "Quite increased" },
      { value: "Neutral", label: "Neutral" },
      { value: "Moderately increased", label: "Moderately increased" },
      { value: "Slightly increased", label: "Slightly increased" },
      { value: "Did not increase at all", label: "Did not increase at all" },
    ]
  },
  {
    questionId: '5',
    question: "How long did it take for you to implement the new materials after the training?",
    shortName: "Implementation Time",
    workshop: "",
    fieldName: "q6",
    questionType: "radio-ordered",
    options: [
      { value: "Haven't implemented yet", label: "Haven't implemented yet" },
      { value: "More than 3 months", label: "More than 3 months" },
      { value: "Within 3 months", label: "Within 3 months" },
      { value: "Within a month", label: "Within a month" },
      { value: "Within a week", label: "Within a week" },
      { value: "Immediately", label: "Immediately" },
    ]
  }
];

export const predefinedQuestionsStudentCharacteristicsEn = [
  {
    questionId: '0',
    question: "Which age group do you belong to?",
    shortName: "Age",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-unordered",
    options: [
      { value: "Under 10 years", label: "Under 10 years" },
      { value: "10-15 years", label: "10-15 years" },
      { value: "16-20 years", label: "16-20 years" },
      { value: "21-25 years", label: "21-25 years" },
      { value: "26-30 years", label: "26-30 years" },
      { value: "31 years and above", label: "31 years and above" }
    ]
  },
  {
    questionId: '1',
    question: "What is your main area of specialization or interest?",
    shortName: "Specialization",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-unordered",
    options: [
      { value: "Sciences", label: "Sciences" },
      { value: "Arts", label: "Arts" },
      { value: "Technology", label: "Technology" },
      { value: "Mathematics", label: "Mathematics" },
      { value: "Humanities", label: "Humanities" },
      { value: "Other", label: "Other" }
    ]
  },
  {
    questionId: '2',
    question: "Which education level are you currently in?",
    shortName: "Education Level",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-unordered",
    options: [
      { value: "Primary", label: "Primary" },
      { value: "Secondary", label: "Secondary" },
      { value: "Undergraduate", label: "Undergraduate" },
      { value: "Postgraduate", label: "Postgraduate" }
    ]
  },
  {
    questionId: '3',
    question: "How often do you use digital tools for learning or leisure?",
    shortName: "Use of Digital Tools",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-unordered",
    options: [
      { value: "Never", label: "Never" },
      { value: "Rarely", label: "Rarely" },
      { value: "Sometimes", label: "Sometimes" },
      { value: "Often", label: "Often" },
      { value: "Always", label: "Always" }
    ]
  },
  {
    questionId: '4',
    question: "Do you believe there are certain genders more suited to computer science?",
    shortName: "Gender Stereotypes",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-unordered",
    options: [
      { value: "Yes, males", label: "Yes, males" },
      { value: "Yes, females", label: "Yes, females" },
      { value: "No, all genders are equally suited", label: "No, all genders are equally suited" },
      { value: "I don't have an opinion on this", label: "I don't have an opinion on this" }
    ]
  }
];