export const predefinedQuestionsTraineeCharacteristicsFr = [
  {
    questionId: '0',
    question: "Dans quelle mesure êtes-vous motivé à participer à cette formation ?",
    shortName: "Motivation",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    options: [
      { value: "Pas du tout motivé", label: "Pas du tout motivé" },
      { value: "Peu motivé", label: "Peu motivé" },
      { value: "Plutôt non motivé", label: "Plutôt non motivé" },
      { value: "Ni motivé ni non motivé", label: "Ni motivé ni non motivé" },
      { value: "Plutôt motivé", label: "Plutôt motivé" },
      { value: "Très motivé", label: "Très motivé" },
      { value: "Extrêmement motivé", label: "Extrêmement motivé" },
    ],
  },
  {
    questionId: '1',
    question: "Dans quelle mesure considérez-vous la technologie comme bénéfique pour votre travail ?",
    shortName: "Bénéfices de la technologie",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    options: [
      { value: "Pas du tout bénéfique", label: "Pas du tout bénéfique" },
      { value: "Peu bénéfique", label: "Peu bénéfique" },
      { value: "Plutôt pas bénéfique", label: "Plutôt pas bénéfique" },
      { value: "Ni bénéfique ni préjudiciable", label: "Ni bénéfique ni préjudiciable" },
      { value: "Plutôt bénéfique", label: "Plutôt bénéfique" },
      { value: "Très bénéfique", label: "Très bénéfique" },
      { value: "Extrêmement bénéfique", label: "Extrêmement bénéfique" },
    ],
  },
  {
    questionId: '2',
    question: "Dans quelle mesure les opinions des autres influencent-elles votre décision de participer à cette formation ?",
    shortName: "Influence des pairs",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    options: [
      { value: "Pas du tout influencé", label: "Pas du tout influencé" },
      { value: "Peu influencé", label: "Peu influencé" },
      { value: "Plutôt pas influencé", label: "Plutôt pas influencé" },
      { value: "Ni influencé ni non influencé", label: "Ni influencé ni non influencé" },
      { value: "Plutôt influencé", label: "Plutôt influencé" },
      { value: "Très influencé", label: "Très influencé" },
      { value: "Extrêmement influencé", label: "Extrêmement influencé" },
    ],
  },
  {
    questionId: '4',
    question: "Dans quelle mesure votre inscription à cette formation a-t-elle été un choix personnel plutôt qu'une obligation ?",
    shortName: "Choix personnel",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    options: [
      { value: "Complètement une obligation", label: "Complètement une obligation" },
      { value: "Plutôt une obligation", label: "Plutôt une obligation" },
      { value: "Un peu une obligation", label: "Un peu une obligation" },
      { value: "Ni un choix personnel ni une obligation", label: "Ni un choix personnel ni une obligation" },
      { value: "Plutôt un choix personnel", label: "Plutôt un choix personnel" },
      { value: "Davantage un choix personnel", label: "Davantage un choix personnel" },
      { value: "Complètement un choix personnel", label: "Complètement un choix personnel" },
    ],
  },
  {
    questionId: '5',
    question: "Dans quelle mesure vous sentez-vous capable d'utiliser les technologies informatiques pour accomplir vos tâches ?",
    shortName: "Confiance en la technologie",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    options: [
      { value: "Pas du tout capable", label: "Pas du tout capable" },
      { value: "Peu capable", label: "Peu capable" },
      { value: "Plutôt pas capable", label: "Plutôt pas capable" },
      { value: "Ni capable ni incapable", label: "Ni capable ni incapable" },
      { value: "Plutôt capable", label: "Plutôt capable" },
      { value: "Très capable", label: "Très capable" },
      { value: "Extrêmement capable", label: "Extrêmement capable" },
    ],
  },
];

export const predefinedQuestionsTrainingCharacteristicsFr = [

  {
    questionId: '0',
    question: "Comment évalueriez-vous la capacité du formateur à gérer le temps?",
    shortName: "Gestion du temps",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    options: [
      { value: "Très mauvaise gestion du temps", label: "Très mauvaise gestion du temps" },
      { value: "Mauvaise gestion du temps", label: "Mauvaise gestion du temps" },
      { value: "Plutôt mauvaise gestion du temps", label: "Plutôt mauvaise gestion du temps" },
      { value: "Gestion du temps moyenne", label: "Gestion du temps moyenne" },
      { value: "Bonne gestion du temps", label: "Bonne gestion du temps" },
      { value: "Très bonne gestion du temps", label: "Très bonne gestion du temps" },
      { value: "Excellente gestion du temps", label: "Excellente gestion du temps" }
    ]
  },

  {
    questionId: '1',
    question: "Comment évalueriez-vous la pertinence des méthodes d'enseignement du formateur?",
    shortName: "Méthodes d'enseignement",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    options: [
      { value: "Pas du tout adapté", label: "Pas du tout adapté" },
      { value: "Pas très adapté", label: "Pas très adapté" },
      { value: "Plutôt inadapté", label: "Plutôt inadapté" },
      { value: "Neutre", label: "Ni adapté ni inadapté" },
      { value: "Assez adapté", label: "Assez adapté" },
      { value: "Très adapté", label: "Très adapté" },
      { value: "Extrêmement adapté", label: "Extrêmement adapté" }
    ]
  },

  {
    questionId: '2',
    question: "Comment évalueriez-vous la pertinence de l'environnement d'apprentissage?",
    shortName: "Environnement",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    options: [
      { value: "Pas du tout adapté", label: "Pas du tout adapté" },
      { value: "Pas très adapté", label: "Pas très adapté" },
      { value: "Plutôt inadapté", label: "Plutôt inadapté" },
      { value: "Neutre", label: "Ni adapté ni inadapté" },
      { value: "Assez adapté", label: "Assez adapté" },
      { value: "Très adapté", label: "Très adapté" },
      { value: "Extrêmement adapté", label: "Extrêmement adapté" }
    ]
  },

  {
    questionId: '3',
    question: "Comment évalueriez-vous la pertinence des outils pédagogiques utilisés?",
    shortName: "Pertinence des outils",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    options: [
      { value: "Pas du tout adapté", label: "Pas du tout adapté" },
      { value: "Pas très adapté", label: "Pas très adapté" },
      { value: "Plutôt inadapté", label: "Plutôt inadapté" },
      { value: "Neutre", label: "Ni adapté ni inadapté" },
      { value: "Assez adapté", label: "Assez adapté" },
      { value: "Très adapté", label: "Très adapté" },
      { value: "Extrêmement adapté", label: "Extrêmement adapté" }
    ]
  },

  {
    questionId: '4',
    question: "Comment évalueriez-vous la capacité du formateur à susciter l'intérêt des participants?",
    shortName: "Intérêt",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    options: [
      { value: "Pas du tout adapté", label: "Pas du tout adapté" },
      { value: "Rarement adapté", label: "Rarement adapté" },
      { value: "Plutôt inadapté", label: "Plutôt inadapté" },
      { value: "Neutre", label: "Ni adapté ni inadapté" },
      { value: "Assez adapté", label: "Assez adapté" },
      { value: "Généralement adapté", label: "Généralement adapté" },
      { value: "Toujours adapté", label: "Toujours adapté" }
    ]
  }
]

export const predefinedQuestionsOrganizationalConditionsFr = [
  {
    questionId: '0',
    question: "À quel niveau estimez-vous votre besoin d'équipement pour intégrer efficacement les ressources de formation dans votre pratique ?",
    shortName: "Ressources matérielles",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    organizationalType: "Ressources matérielles",
    options: [
      { "value": "Très élevé", "label": "Très élevé" },
      { "value": "Élevé", "label": "Élevé" },
      { "value": "Plutôt élevé", "label": "Plutôt élevé" },
      { "value": "Moyen", "label": "Moyen" },
      { "value": "Plutôt faible", "label": "Plutôt faible" },
      { "value": "Faible", "label": "Faible" },
      { "value": "Très faible", "label": "Très faible" }
    ]
  },
  {
    questionId: '1',
    question: "À quel niveau estimez-vous votre besoin de temps disponible pour intégrer les ressources de formation dans votre pratique?",
    shortName: "Ressources temporelles",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    organizationalType: "Ressources temporelles",
    options: [
      { "value": "Très élevé", "label": "Très élevé" },
      { "value": "Élevé", "label": "Élevé" },
      { "value": "Plutôt élevé", "label": "Plutôt élevé" },
      { "value": "Moyen", "label": "Moyen" },
      { "value": "Plutôt faible", "label": "Plutôt faible" },
      { "value": "Faible", "label": "Faible" },
      { "value": "Très faible", "label": "Très faible" }
    ]
  },
  {
    questionId: '2',
    question: "À quel niveau estimez-vous votre besoin de soutien de votre hiérarchie pour intégrer les ressources de formation dans votre pratique?",
    shortName: "Soutien hiérarchique",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    organizationalType: "Soutien hiérarchique",
    options: [
      { "value": "Très élevé", "label": "Très élevé" },
      { "value": "Élevé", "label": "Élevé" },
      { "value": "Plutôt élevé", "label": "Plutôt élevé" },
      { "value": "Moyen", "label": "Moyen" },
      { "value": "Plutôt faible", "label": "Plutôt faible" },
      { "value": "Faible", "label": "Faible" },
      { "value": "Très faible", "label": "Très faible" }
    ]
  },
  {
    questionId: '3',
    question: "À quel niveau estimez-vous votre besoin de soutien de vos collègues pour intégrer les ressources de formation dans votre pratique?",
    shortName: "Soutien des collègues",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    organizationalType: "Soutien des collègues",
    options: [
      { "value": "Très élevé", "label": "Très élevé" },
      { "value": "Élevé", "label": "Élevé" },
      { "value": "Plutôt élevé", "label": "Plutôt élevé" },
      { "value": "Moyen", "label": "Moyen" },
      { "value": "Plutôt faible", "label": "Plutôt faible" },
      { "value": "Faible", "label": "Faible" },
      { "value": "Très faible", "label": "Très faible" }
    ]
  },
  {
    questionId: '4',
    question: "À quel niveau estimez-vous votre besoin de soutien technique pour intégrer les ressources de formation dans votre pratique?",
    shortName: "Soutien technique",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    organizationalType: "Soutien technique",
    options: [
      { "value": "Très élevé", "label": "Très élevé" },
      { "value": "Élevé", "label": "Élevé" },
      { "value": "Plutôt élevé", "label": "Plutôt élevé" },
      { "value": "Moyen", "label": "Moyen" },
      { "value": "Plutôt faible", "label": "Plutôt faible" },
      { "value": "Faible", "label": "Faible" },
      { "value": "Très faible", "label": "Très faible" }
    ]
  },
  {
    questionId: '5',
    question: "À quel niveau estimez-vous votre besoin de mentorat (avec un·e collègue plus expérimenté par exemple) pour intégrer les ressources de formation dans votre pratique?",
    shortName: "Mentorat",
    workshop: "",
    fieldName: "q6",
    questionType: "radio-ordered",
    organizationalType: "Mentorat",
    options: [
      { "value": "Très élevé", "label": "Très élevé" },
      { "value": "Élevé", "label": "Élevé" },
      { "value": "Plutôt élevé", "label": "Plutôt élevé" },
      { "value": "Moyen", "label": "Moyen" },
      { "value": "Plutôt faible", "label": "Plutôt faible" },
      { "value": "Faible", "label": "Faible" },
      { "value": "Très faible", "label": "Très faible" }
    ]
  }
]

export const predefinedQuestionsImmediateReactionsFr = [
  {
    questionId: '0',
    question: "Avez-vous apprécié l'atelier?",
    shortName: "Intérêt Perçu",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    options: [
      { value: "Pas du tout apprécié", label: "Pas du tout apprécié" },
      { value: "Peu apprécié", label: "Peu apprécié" },
      { value: "Assez apprécié", label: "Assez apprécié" },
      { value: "Neutre", label: "Neutre" },
      { value: "Très apprécié", label: "Très apprécié" },
      { value: "Extrêmement apprécié", label: "Extrêmement apprécié" }
    ]
  },
  {
    questionId: '1',
    question: "Quelle a été l'utilité de l'atelier pour vous ?",
    shortName: "Utilité Perçue",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    options: [
      { value: "Pas utile du tout", label: "Pas utile du tout" },
      { value: "Légèrement utile", label: "Légèrement utile" },
      { value: "Moyennement utile", label: "Moyennement utile" },
      { value: "Neutre", label: "Neutre" },
      { value: "Très utile", label: "Très utile" },
      { value: "Extrêmement utile", label: "Extrêmement utile" }
    ]
  },
  {
    questionId: '2',
    question: "Trouvez-vous facile la transposition de ce que vous avez vu en classe?",
    shortName: "Facilité d'Utilisation Perçue",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    options: [
      { value: "Pas facile du tout", label: "Pas facile du tout" },
      { value: "Un peu difficile", label: "Un peu difficile" },
      { value: "Moyennement facile", label: "Moyennement facile" },
      { value: "Neutre", label: "Neutre" },
      { value: "Assez facile", label: "Assez facile" },
      { value: "Très facile", label: "Très facile" },
      { value: "Extrêmement facile", label: "Extrêmement facile" }
    ]
  },
  {
    questionId: '3',
    question: "Quelle est votre confiance concernant l'intégration de ce que vous avez vu en classe ?",
    shortName: "Auto-Efficacité",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    options: [
      { value: "Pas confiant du tout", label: "Pas confiant du tout" },
      { value: "Légèrement confiant", label: "Légèrement confiant" },
      { value: "Moyennement confiant", label: "Moyennement confiant" },
      { value: "Neutre", label: "Neutre" },
      { value: "Très confiant", label: "Très confiant" },
      { value: "Extrêmement confiant", label: "Extrêmement confiant" }
    ]
  },
  {
    questionId: '4',
    question: "Dans quelle mesure avez-vous l'intention d'intégrer ce que vous avez vu en classe?",
    shortName: "Intention Comportementale",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    options: [
      { value: "N'ai pas du tout l'intention d'intégrer", label: "N'ai pas du tout l'intention d'intégrer" },
      { value: "Légère intention d'intégrer", label: "Légère intention d'intégrer" },
      { value: "Moyenne intention d'intégrer", label: "Moyenne intention d'intégrer" },
      { value: "Neutre", label: "Neutre" },
      { value: "Forte intention d'intégrer", label: "Forte intention d'intégrer" },
      { value: "Très forte intention d'intégrer", label: "Très forte intention d'intégrer" },
      { value: "Absolument l'intention d'intégrer", label: "Absolument l'intention d'intégrer" }
    ]
  }
];

export const predefinedQuestionsSustainabilityConditionsFr = [
  {
    questionId: '1',
    question: "Dans quelle mesure le matériel introduit est-il compatible avec vos pratiques d'enseignement antérieures ?",
    shortName: "Compatibilité",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    options: [
      { value: "Pas compatible du tout", label: "Pas compatible du tout" },
      { value: "Légèrement compatible", label: "Légèrement compatible" },
      { value: "Assez compatible", label: "Assez compatible" },
      { value: "Neutre", label: "Neutre" },
      { value: "Moyennement compatible", label: "Moyennement compatible" },
      { value: "Très compatible", label: "Très compatible" },
      { value: "Extrêmement compatible", label: "Extrêmement compatible" }
    ]
  },
  {
    questionId: '2',
    question: "Comment jugez-vous la progression de vos élèves avec les nouveaux matériels ?",
    shortName: "Progression des élèves",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    options: [
      { value: "A nettement diminué", label: "A nettement diminué" },
      { value: "A légèrement diminué", label: "A légèrement diminué" },
      { value: "Est restée la même", label: "Est restée la même" },
      { value: "A légèrement augmenté", label: "A légèrement augmenté" },
      { value: "A moyennement augmenté", label: "A moyennement augmenté" },
      { value: "A nettement augmenté", label: "A nettement augmenté" }
    ]
  },
  {
    questionId: '3',
    question: "Comment évalueriez-vous votre sentiment d'auto-efficacité en utilisant les nouvelles ressources ?",
    shortName: "Auto-Efficacité",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    options: [
      { value: "Pas confiant du tout", label: "Pas confiant du tout" },
      { value: "Légèrement confiant", label: "Légèrement confiant" },
      { value: "Assez confiant", label: "Assez confiant" },
      { value: "Neutre", label: "Neutre" },
      { value: "Moyennement confiant", label: "Moyennement confiant" },
      { value: "Très confiant", label: "Très confiant" },
      { value: "Extrêmement confiant", label: "Extrêmement confiant" }
    ]
  },
  {
    questionId: '4',
    question: "À quel point trouvez-vous l'implémentation des nouvelles ressources mentalement éprouvante ?",
    shortName: "Charge Cognitive",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    options: [
      { value: "Extrêmement éprouvant", label: "Extrêmement éprouvant" },
      { value: "Très éprouvant", label: "Très éprouvant" },
      { value: "Assez éprouvant", label: "Assez éprouvant" },
      { value: "Moyennement éprouvant", label: "Moyennement éprouvant" },
      { value: "Légèrement éprouvant", label: "Légèrement éprouvant" },
      { value: "Pas du tout éprouvant", label: "Pas du tout éprouvant" }
    ]
  },
  {
    questionId: '5',
    question: "Dans quelle mesure les nouvelles ressources augmentent-elles votre charge de travail ?",
    shortName: "Impact sur la charge de travail",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    options: [
      { value: "Massivement augmenté", label: "Massivement augmenté" },
      { value: "Nettement augmenté", label: "Nettement augmenté" },
      { value: "Assez augmenté", label: "Assez augmenté" },
      { value: "Neutre", label: "Neutre" },
      { value: "Moyennement augmenté", label: "Moyennement augmenté" },
      { value: "Légèrement augmenté", label: "Légèrement augmenté" },
      { value: "N'a pas du tout augmenté", label: "N'a pas du tout augmenté" },
    ]
  },
  {
    questionId: '6',
    question: "Combien de temps vous a-t-il fallu pour mettre en œuvre les nouveaux matériaux après la formation ?",
    shortName: "Temps de mise en œuvre",
    workshop: "",
    fieldName: "q6",
    questionType: "radio-ordered",
    options: [
      { value: "Pas encore mis en œuvre", label: "Pas encore mis en œuvre" },
      { value: "Plus de 3 mois", label: "Plus de 3 mois" },
      { value: "Dans les 3 mois", label: "Dans les 3 mois" },
      { value: "Dans le mois", label: "Dans le mois" },
      { value: "Dans la semaine", label: "Dans la semaine" },
      { value: "Immédiatement", label: "Immédiatement" },
    ]
  }
];


export const predefinedQuestionsStudentCharacteristicsFr = [
  {
    questionId: '0',
    question: "À quelle tranche d'âge appartenez-vous ?",
    shortName: "Âge",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-unordered",
    options: [
      { value: "Moins de 10 ans", label: "Moins de 10 ans" },
      { value: "10-15 ans", label: "10-15 ans" },
      { value: "16-20 ans", label: "16-20 ans" },
      { value: "21-25 ans", label: "21-25 ans" },
      { value: "26-30 ans", label: "26-30 ans" },
      { value: "31 ans et plus", label: "31 ans et plus" }
    ]
  },
  {
    questionId: '1',
    question: "Quel est votre principal domaine de spécialisation ou d'intérêt ?",
    shortName: "Spécialisation",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-unordered",
    options: [
      { value: "Sciences", label: "Sciences" },
      { value: "Arts", label: "Arts" },
      { value: "Technologie", label: "Technologie" },
      { value: "Mathématiques", label: "Mathématiques" },
      { value: "Sciences humaines", label: "Sciences humaines" },
      { value: "Autre", label: "Autre" }
    ]
  },
  {
    questionId: '2',
    question: "Dans quel niveau d'éducation êtes-vous actuellement ?",
    shortName: "Niveau d'éducation",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-unordered",
    options: [
      { value: "Primaire", label: "Primaire" },
      { value: "Secondaire", label: "Secondaire" },
      { value: "Licence (ou équivalent)", label: "Licence (ou équivalent)" },
      { value: "Master/Doctorat (ou équivalent)", label: "Master/Doctorat (ou équivalent)" }
    ]
  },
  {
    questionId: '3',
    question: "À quelle fréquence utilisez-vous des outils numériques pour l'apprentissage ou les loisirs ?",
    shortName: "Utilisation d'outils numériques",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-unordered",
    options: [
      { value: "Jamais", label: "Jamais" },
      { value: "Rarement", label: "Rarement" },
      { value: "Parfois", label: "Parfois" },
      { value: "Souvent", label: "Souvent" },
      { value: "Toujours", label: "Toujours" }
    ]
  },
  {
    questionId: '4',
    question: "Pensez-vous qu'il y ait certains genres plus adaptés à l'informatique ?",
    shortName: "Stéréotypes de genre",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-unordered",
    options: [
      { value: "Oui, les hommes", label: "Oui, les hommes" },
      { value: "Oui, les femmes", label: "Oui, les femmes" },
      { value: "Non, tous les genres sont également adaptés", label: "Non, tous les genres sont également adaptés" },
      { value: "Je n'ai pas d'opinion à ce sujet", label: "Je n'ai pas d'opinion à ce sujet" }
    ]
  }
];
