import React from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext'; 

const PrivateRoute = ({ children, authorizedEmails }) => {
  const { isAuthenticated, currentUser } = React.useContext(AuthContext);

  // First, check if the user is authenticated.
  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  // Next, if a list of authorized emails is provided, check against it.
  if (authorizedEmails && currentUser && !authorizedEmails.includes(currentUser.email)) {
    return <Navigate to="/unauthorized" />;  // Redirect to home or an "unauthorized" page if you have one.
  }

  return children;
};

export default PrivateRoute;
