import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Box, Button, Typography, TextField } from '@mui/material';
import axios from 'axios';
import SurveyQuestion from '../../components/SurveyQuestion';
import logo_dtc from "../../assets/logo.svg";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { BACKEND_URL } from "../../config";

const MemoizedSurveyQuestion = React.memo(SurveyQuestion);

const CompleteSurvey = () => {
  const location = useLocation();
  const { t } = useTranslation('translation');
  const params = new URLSearchParams(location.search);
  const monitoring = params.get('monitoring');
  const user = params.get('user');
  const language = params.get('lng');
  const sandbox = params.get('sandbox');
  const link = params.get('link') === 'true';
  const [assessmentType, setAssessmentType] = useState(""); 
  const [assessmentName, setAssessmentName] = useState("");
  const [assessmentStatus, setAssessmentStatus] = useState("");
  const navigate = useNavigate();
  const [surveyData, setSurveyData] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [currentAssessmentIndex, setCurrentAssessmentIndex] = useState(0);
  const assessmentIds = params.getAll('assessment[]');
  const [showGlobalError, setShowGlobalError] = useState(false);
  const [codeValidated, setCodeValidated] = useState(!link);
  const [linkingCode, setLinkingCode] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(false); 
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  const fetchSurveyData = useCallback(async () => {
    const currentAssessmentId = assessmentIds[currentAssessmentIndex];
    
    try {
      const response = await axios.get(
        `${BACKEND_URL}/survey`,
        {
          params: { 
            currentAssessmentServerId: currentAssessmentId, 
            currentMonitoringServerId: monitoring,
            sandbox: sandbox 
          },
        }
      );

      const { survey, type, name, status } = response.data;
      setSurveyData(survey.map(question => ({
        ...question,
        choices: question.choices.map(choice => ({ value: choice, label: choice })),
        items: question.items ? question.items.map(item => ({ label: item, value: item })) : [],
      })));

      setAssessmentType(type); 
      setAssessmentName(name); 
      setAssessmentStatus(status); 

      const initialValues = survey.reduce((values, question) => {
        return { ...values, [`q${question.questionId}`]: '' };
      }, {});

      setInitialValues(initialValues);
    } catch (error) {
      console.error('Error fetching survey data:', error);
    }
  }, [assessmentIds, currentAssessmentIndex, monitoring]);

  useEffect(() => {
    if (codeValidated) {
      fetchSurveyData();
    }
  }, [fetchSurveyData, codeValidated]);

  const handleSubmit = useCallback(async (values, { setSubmitting }) => {
    const isAnyMandatoryUnanswered = surveyData.some(question => (
      question.isMandatory && (!values[`q${question.questionId}`] || values[`q${question.questionId}`] === '')
    ));

    if (isAnyMandatoryUnanswered) {
      setShowGlobalError(true);
      setSubmitting(false);
      return;
    }

    setShowGlobalError(false); 

    const currentAssessmentId = assessmentIds[currentAssessmentIndex];
    
    const responses = surveyData.map(question => ({
      questionId: question.questionId,
      linkingId: linkingCode,
      shortName: question.shortName,
      question: question.question,
      correctAnswer: question.correctAnswer,
      workshop: question.workshop,
      questionType: question.questionType,
      learningType: question.learningType,
      adoptionType: question.adoptionType,
      organizationalType: question.organizationalType,
      choices: question.choices.map(choice => choice.value),
      items: question.items.map(item => item.value),
      competencies: question.competencies,
      response: Array.isArray(values[`q${question.questionId}`])
        ? values[`q${question.questionId}`]
        : [values[`q${question.questionId}`]],
    }));

    const responseData = {
      userId: user,
      assessmentId: currentAssessmentId,
      monitoringId: monitoring,
      assessmentType: assessmentType,
      survey: responses,
      sandbox: sandbox
    };

    console.log(responseData);

    try {
      const response = await axios.post(`${BACKEND_URL}/response`, responseData);

      if (currentAssessmentIndex < assessmentIds.length - 1) {
        setCurrentAssessmentIndex(prevIndex => prevIndex + 1);
        setInitialValues({});
        fetchSurveyData();
        
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      } else {
        navigate(`/endSurvey?lng=${language}`);
      }
    } catch (error) {
      console.error('Error submitting survey data:', error);
    }

    setSubmitting(false);
  }, [surveyData, assessmentIds, currentAssessmentIndex, language, navigate, fetchSurveyData]);

  const workshops = useMemo(() => {
    return surveyData.reduce((workshops, question) => {
      if (!workshops[question.workshop]) {
        workshops[question.workshop] = [];
      }
      workshops[question.workshop].push(question);
      return workshops;
    }, {});
  }, [surveyData]);

  const handleGenerateCode = () => {
    const consonantPool = "BCDFGHJKLMNPRSTVWXYZ"; 
    const vowelPool = "AEIOU";
    const allChars = consonantPool + vowelPool;

    let code = "";
    // Ensure at least 4 consonants and 4 vowels
    while (code.split("").filter(c => consonantPool.includes(c)).length < 4 ||
          code.split("").filter(c => vowelPool.includes(c)).length < 4) {
      code = "";
      for (let i = 0; i < 8; i++) {
        code += allChars[Math.floor(Math.random() * allChars.length)];
      }
    }

    setLinkingCode(code.toUpperCase());
    setIsCodeValid(true);
  };

  const handleLinkingCodeChange = (e) => {
    const code = e.target.value.toUpperCase();
    setLinkingCode(code);
    setIsCodeValid(code.length > 0); 
    setErrorMessage(code.length === 0 ? t('complete_survey.error_linking_code') : ''); // Set error message if empty
  };

  const preventEnterKey = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <>
      {!codeValidated ? (
        <Box m="20px" display="flex" alignItems="center" justifyContent="center" flexDirection="column" sx={{ width: '100%', maxWidth: '400px', mx: 'auto' }}>
          <Box>
            <img alt="Logo" width="100%" height="100%" src={logo_dtc} style={{ cursor: 'pointer', borderRadius: '0%' }} />
          </Box>
          <Typography variant="h3" mt="100px" mb="10px">{t('complete_survey.linking_code')}</Typography>
          <TextField
            variant="outlined"
            value={linkingCode}
            onChange={handleLinkingCodeChange}
            error={!!errorMessage}
            helperText={errorMessage}
            inputProps={{
              maxLength: 8,
              style: {
                letterSpacing: '0.5em',
                textAlign: 'center',
                fontSize: '2rem',
                textTransform: 'uppercase'
              }
            }}
            sx={{
              width: '300px',
              mb: 2,
              '& .MuiOutlinedInput-root': {
                backgroundImage: 'url("data:image/svg+xml,<svg xmlns=\\"http://www.w3.org/2000/svg\\" width=\\"300\\" height=\\"24\\"><line x1=\\"0\\" y1=\\"20\\" x2=\\"100%\\" y2=\\"20\\" stroke=\\"#000\\" stroke-width=\\"2\\" stroke-dasharray=\\"6,6\\"/></svg>")',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              },
              '& input': {
                textAlign: 'center',
                fontWeight: 'bold',
                backgroundColor: 'transparent'
              }
            }}
          />
          <Button
            type="submit"
            variant="contained"
            disabled={!isCodeValid}
            onClick={() => setCodeValidated(true)}
            sx={{
              backgroundColor: '#F7941E',
              borderRadius: '50px',
              color: 'black',
              '&:hover': { backgroundColor: '#D17A1D' }
            }}
          >
            {t('complete_survey.validate_linking_code')}
          </Button>
          <Typography variant="body1" mt="20px" mb="10px">{t('complete_survey.or')}</Typography>
          <Button
            type="submit"
            variant="contained"
            onClick={handleGenerateCode}
            sx={{
              backgroundColor: '#F7941E',
              borderRadius: '50px',
              color: 'black',
              '&:hover': { backgroundColor: '#D17A1D' }
            }}
          >
            {t('complete_survey.generate_linking_code')}
          </Button>
        </Box>
      ) : (
        <Box>
          <Box
            m="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column">
            <Box>
              <img
                alt=""
                width="100%"
                height="100%"
                src={logo_dtc}
                style={{ cursor: 'pointer', borderRadius: '0%' }}/>
            </Box>
            
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt="50px"
              sx={{
                padding: '20px',
                width: {
                  xs: '90vw', 
                  md: '50vw',
                },
              }}>

              {assessmentStatus === "Close" ? (
                <Typography variant="h3" align="justify" style={{ margin: '10px' }}>
                  {t('complete_survey.message_not_available')}
                </Typography>
              ) : assessmentStatus === "Draft" ? (
                <Typography variant="h3" align="justify" style={{ margin: '10px' }}>
                  {t('complete_survey.message_under_construction')}
                </Typography>
              ) : null}
            </Box>
          </Box>

          {assessmentStatus !== "Close" && assessmentStatus !== "Draft" && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box display="flex" alignItems="center" justifyContent="center" sx={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', borderRadius: '15px', padding: '20px', backgroundColor: '#fff', width: { xs: '90vw', md: '70vw' }}}>
                <Formik key={currentAssessmentIndex} initialValues={initialValues} onSubmit={handleSubmit}>
                  {({ setFieldValue }) => (
                    <Form onKeyDown={preventEnterKey}>
                      {Object.entries(workshops).map(([workshopName, questions]) => (
                        <div key={workshopName}>
                          <Box display="flex" justifyContent="center" alignItems="center">
                            {workshopName !== "undefined" && (
                              <Typography variant="h2" fontWeight="bold" color="rgb(102,102,102)" m="15px 10px 0px 0px"> 
                                {workshopName}
                              </Typography>
                            )}
                          </Box>

                          {questions.map((question, index) => (
                            <MemoizedSurveyQuestion
                              key={index}
                              imageUrl={question.imageUrl}
                              question={question.question}
                              correctAnswer={question.correctAnswer}
                              explanation={question.explanation}
                              workshop={question.workshop}
                              fieldName={`q${question.questionId}`}
                              type={question.questionType}
                              isMandatory={question.isMandatory}
                              options={question.choices}
                              items={question.items}
                              setFieldValue={setFieldValue}
                              assessmentType={assessmentType} 
                              displayCorrectAnswer={false}
                              matrixFullWidth={true}
                            />
                          ))}
                        </div>
                      ))}
                      {showGlobalError && (
                        <Box mt={2} display="flex" justifyContent="center">
                          <Typography color="error">{t('complete_survey.message_mandatory_error')}</Typography>
                        </Box>
                      )}
                      <Box mt={2} display="flex" justifyContent="center">
                        {surveyData.length > 0 && (
                          <Button type="submit" variant="contained" sx={{ backgroundColor: '#F7941E', borderRadius: '50px', color: 'black', '&:hover': { backgroundColor: '#D17A1D' }, }}>
                            <Typography variant="h5">
                              {currentAssessmentIndex < assessmentIds.length - 1 ? t('complete_survey.next') : t('complete_survey.submit')}
                            </Typography>
                          </Button>
                        )}
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default CompleteSurvey;
