/**
 * The `App` component serves as the core of this React application, orchestrating the theme, authentication context, and routing across various pages and features. 
 * Utilizing Material-UI for styling, it ensures a consistent look and feel across the app while supporting dynamic theme changes (light/dark mode) through a custom hook `useMode`.
 *  Authentication is managed globally via the `AuthProvider`, securing access to app features based on user authentication status.

 * Routes are defined for each page within the application, including:
 * - Home, Dashboard, Signup, and Signin pages for general navigation and user management.
 * - Survey-related pages like CreateSurvey, Survey, Results, and Summaries for handling survey operations.
 * - Additional utility pages like Tutorial, ResetPassword, VerifyEmail, and EndSurvey for user guidance and account management.
 * - Administrative features are protected by `PrivateRoute` components, which restrict access to authorized users, indicated by specific criteria such as email verification.

 * Key Features:
 * - `AuthProvider` wraps the entire application, providing a context for authentication state.
 * - `ColorModeContext` and `ThemeProvider` enable dynamic styling and theme switching.
 * - `CssBaseline` from Material-UI standardizes the baseline CSS across the app.
 * - The application's navigation is managed by `Routes` and `Route` components from `react-router-dom`, defining the path and component for each page.
 * - `PrivateRoute` components are used to protect routes that require user authentication, with custom logic to include authorization by email.
 */

import { AuthProvider } from "./contexts/AuthContext";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./scenes/dashboard";
import Survey from "./scenes/survey";
import Results from "./scenes/results";
import Summaries from "./scenes/summaries";
import Tutorial from "./scenes/tutorial";
import Signup from "./scenes/signup";
import Signin from "./scenes/signin";
import CreateSurvey from "./scenes/createSurvey";
import PreviewSurvey from "./scenes/previewSurvey";
import CompleteSurvey from "./scenes/completeSurvey";
import Reporting from "./scenes/reporting";
import Reports from "./scenes/reports";
import Logbooks from "./scenes/logbooks";
import Frameworks from "./scenes/frameworks";
import Model from "./scenes/models";
import Home from "./scenes/home";
import ResetPassword from "./scenes/resetPassword";
import VerifyEmail from "./scenes/verifyEmail";
import EndSurvey from "./scenes/endSurvey";
import Embedder from "./scenes/embedder";
import PrivateRoute from "./components/PrivateRoute";

function App() {
  const [theme, colorMode] = useMode();
  const authorizedEmbedderEmails = ["sunny.avry@gmail.com"];

  return (
    <AuthProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/verifyEmail/" element={<VerifyEmail />} />
            <Route path="/resetPassword/:token" element={<ResetPassword />} />
            <Route path="/survey" element={<Survey />} />
            <Route path="/completeSurvey" element={<CompleteSurvey />} />
            <Route path="/reporting" element={<Reporting />} />
            <Route path="/results" element={<Results />} />
            <Route path="/endSurvey" element={<EndSurvey />} />

            <Route
              path="/embedder/"
              element={
                <PrivateRoute authorizedEmails={authorizedEmbedderEmails}>
                  <Embedder />
                </PrivateRoute>
              }
            />
            <Route path="/createSurvey"
              element={
                <PrivateRoute>
                  <CreateSurvey />
                </PrivateRoute>
              }
            />

            <Route
              path="/previewSurvey"
              element={
                <PrivateRoute>
                  <PreviewSurvey />
                </PrivateRoute>
              }
            />
            <Route
              path="/reports"
              element={
                <PrivateRoute>
                  <Reports />
                </PrivateRoute>
              }
            />
            <Route
              path="/summaries"
              element={
                <PrivateRoute>
                  <Summaries />
                </PrivateRoute>
              }
            />
            <Route
              path="/logbooks"
              element={
                <PrivateRoute>
                  <Logbooks />
                </PrivateRoute>
              }
            />

            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route path="/frameworks" element={
              <PrivateRoute>
                <Frameworks />
              </PrivateRoute>
            } />
            <Route path="/tutorial" element={
              <PrivateRoute>
                <Tutorial />
              </PrivateRoute>
            } />
          </Routes>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </AuthProvider>
  );
}

export default App;