import React, { useEffect, useState } from 'react';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); 

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(token ? true : false); 
  }, []);

  if (isAuthenticated === null) {
    return <div></div>; // or a loading spinner
  }

  // The state and function to update it will be shared with children components
  return <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>{children}</AuthContext.Provider>
}
