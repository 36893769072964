export const predefinedQuestionsTraineeCharacteristicsDe = [
  {
    questionId: '0',
    question:
      "Wie motiviert sind Sie, an diesem Training teilzunehmen?",
    shortName: "Motivation",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    options: [
      { value: "Gar nicht motiviert", label: "Gar nicht motiviert" },
      { value: "Wenig motiviert", label: "Wenig motiviert" },
      { value: "Etwas unmotiviert", label: "Etwas unmotiviert" },
      {
        value: "Weder noch",
        label: "Weder motiviert noch unmotiviert",
      },
      { value: "Etwas motiviert", label: "Etwas motiviert" },
      { value: "Sehr motiviert", label: "Sehr motiviert" },
      { value: "Äußerst motiviert", label: "Äußerst motiviert" },
    ],
  },
  {
    questionId: '1',
    question:
      "Inwieweit halten Sie Technologie für nützlich für Ihre Arbeit?",
    shortName: "Technik-Nutzen",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    options: [
      { value: "Gar nicht nützlich", label: "Gar nicht nützlich" },
      { value: "Wenig nützlich", label: "Wenig nützlich" },
      { value: "Etwas nicht nützlich", label: "Etwas nicht nützlich" },
      {
        value: "Weder noch",
        label: "Weder nützlich noch schädlich",
      },
      { value: "Etwas nützlich", label: "Etwas nützlich" },
      { value: "Sehr nützlich", label: "Sehr nützlich" },
      { value: "Äußerst nützlich", label: "Äußerst nützlich" },
    ],
  },
  {
    questionId: '2',
    question:
      "Inwieweit beeinflussen die Meinungen anderer Ihre Entscheidung, an diesem Training teilzunehmen?",
    shortName: "Einfluss Andere",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    options: [
      { value: "Gar nicht beeinflusst", label: "Gar nicht beeinflusst" },
      { value: "Wenig beeinflusst", label: "Wenig beeinflusst" },
      { value: "Etwas nicht beeinflusst", label: "Etwas nicht beeinflusst" },
      {
        value: "Weder noch",
        label: "Weder beeinflusst noch nicht beeinflusst",
      },
      { value: "Etwas beeinflusst", label: "Etwas beeinflusst" },
      { value: "Sehr beeinflusst", label: "Sehr beeinflusst" },
      { value: "Äußerst beeinflusst", label: "Äußerst beeinflusst" },
    ],
  },
  {
    questionId: '3',
    question:
      "Inwieweit war Ihre Anmeldung zu diesem Training eine persönliche Wahl und keine Verpflichtung?",
    shortName: "Persönliche Wahl",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    options: [
      { value: "Vollständige Pflicht", label: "Vollständige Pflicht" },
      { value: "Mehr eine Pflicht", label: "Mehr eine Pflicht" },
      { value: "Etwas eine Pflicht", label: "Etwas eine Pflicht" },
      {
        value: "Weder noch",
        label: "Weder persönliche Wahl noch Pflicht",
      },
      {
        value: "Etwas eine Wahl",
        label: "Etwas eine persönliche Wahl",
      },
      {
        value: "Mehr eine Wahl",
        label: "Mehr eine persönliche Wahl",
      },
      {
        value: "Vollständige Wahl",
        label: "Vollständige persönliche Wahl",
      },
    ],
  },
  {
    questionId: '4',
    question:
      "Inwieweit fühlen Sie sich in der Lage, Computertechnologie zur Bewältigung Ihrer Aufgaben zu nutzen?",
    shortName: "Technik-Können",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    options: [
      { value: "Gar nicht fähig", label: "Gar nicht fähig" },
      { value: "Wenig fähig", label: "Wenig fähig" },
      { value: "Etwas nicht fähig", label: "Etwas nicht fähig" },
      {
        value: "Weder noch",
        label: "Weder fähig noch unfähig",
      },
      { value: "Etwas fähig", label: "Etwas fähig" },
      { value: "Sehr fähig", label: "Sehr fähig" },
      { value: "Äußerst fähig", label: "Äußerst fähig" },
    ],
  },
];



export const predefinedQuestionsTrainingCharacteristicsDe = [

  {
    questionId: '0',
    question: "Wie würden Sie die Fähigkeit des Trainers bewerten, das Publikum zu fesseln?",
    shortName: "Engagement",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    options: [
      { value: "Überhaupt nicht fesselnd", label: "Überhaupt nicht fesselnd" },
      { value: "Nicht sehr fesselnd", label: "Nicht sehr fesselnd" },
      { value: "Eher wenig fesselnd", label: "Eher wenig fesselnd" },
      { value: "Weder fesselnd noch langweilig", label: "Weder fesselnd noch langweilig" },
      { value: "Ziemlich fesselnd", label: "Ziemlich fesselnd" },
      { value: "Sehr fesselnd", label: "Sehr fesselnd" },
      { value: "Äußerst fesselnd", label: "Äußerst fesselnd" }
    ]
  },

  {
    questionId: '1',
    question: "Wie würden Sie die Zeitmanagementfähigkeit des Trainers bewerten?",
    shortName: "Zeitmanagement",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    options: [
      { value: "Sehr schlechtes Zeitmanagement", label: "Sehr schlechtes Zeitmanagement" },
      { value: "Schlechtes Zeitmanagement", label: "Schlechtes Zeitmanagement" },
      { value: "Eher schlechtes Zeitmanagement", label: "Eher schlechtes Zeitmanagement" },
      { value: "Durchschnittliches Zeitmanagement", label: "Durchschnittliches Zeitmanagement" },
      { value: "Gutes Zeitmanagement", label: "Gutes Zeitmanagement" },
      { value: "Sehr gutes Zeitmanagement", label: "Sehr gutes Zeitmanagement" },
      { value: "Ausgezeichnetes Zeitmanagement", label: "Ausgezeichnetes Zeitmanagement" }
    ]
  },

  {
    questionId: '2',
    question: "Wie würden Sie die Relevanz der Lehrmethoden des Trainers bewerten?",
    shortName: "Lehrmethoden",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    options: [
      { value: "Überhaupt nicht angepasst", label: "Überhaupt nicht angepasst" },
      { value: "Nicht sehr angepasst", label: "Nicht sehr angepasst" },
      { value: "Eher nicht angepasst", label: "Eher nicht angepasst" },
      { value: "Neutral", label: "Weder angepasst noch nicht angepasst" },
      { value: "Ziemlich angepasst", label: "Ziemlich angepasst" },
      { value: "Sehr angepasst", label: "Sehr angepasst" },
      { value: "Äußerst angepasst", label: "Äußerst angepasst" }
    ]
  },

  {
    questionId: '3',
    question: "Wie würden Sie die Relevanz der Lernumgebung bewerten?",
    shortName: "Lernumgebung",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    options: [
      { value: "Überhaupt nicht angepasst", label: "Überhaupt nicht angepasst" },
      { value: "Nicht sehr angepasst", label: "Nicht sehr angepasst" },
      { value: "Eher nicht angepasst", label: "Eher nicht angepasst" },
      { value: "Neutral", label: "Weder angepasst noch nicht angepasst" },
      { value: "Ziemlich angepasst", label: "Ziemlich angepasst" },
      { value: "Sehr angepasst", label: "Sehr angepasst" },
      { value: "Äußerst angepasst", label: "Äußerst angepasst" }
    ]
  },

  {
    questionId: '4',
    question: "Wie würden Sie die Relevanz der pädagogischen Werkzeuge bewerten?",
    shortName: "Werkzeugrelevanz",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    options: [
      { value: "Überhaupt nicht angepasst", label: "Überhaupt nicht angepasst" },
      { value: "Nicht sehr angepasst", label: "Nicht sehr angepasst" },
      { value: "Eher nicht angepasst", label: "Eher nicht angepasst" },
      { value: "Neutral", label: "Weder angepasst noch nicht angepasst" },
      { value: "Ziemlich angepasst", label: "Ziemlich angepasst" },
      { value: "Sehr angepasst", label: "Sehr angepasst" },
      { value: "Äußerst angepasst", label: "Äußerst angepasst" }
    ]
  },

  {
    questionId: '5',
    question: "Wie würden Sie die Fähigkeit des Trainers bewerten, seine Lehrmethoden an das Publikum anzupassen?",
    shortName: "Pädagogik",
    workshop: "",
    fieldName: "q6",
    questionType: "radio-ordered",
    options: [
      { value: "Überhaupt nicht angepasst", label: "Überhaupt nicht angepasst" },
      { value: "Selten angepasst", label: "Selten angepasst" },
      { value: "Eher nicht angepasst", label: "Eher nicht angepasst" },
      { value: "Neutral", label: "Weder angepasst noch nicht angepasst" },
      { value: "Ziemlich angepasst", label: "Ziemlich angepasst" },
      { value: "Meistens angepasst", label: "Meistens angepasst" },
      { value: "Immer angepasst", label: "Immer angepasst" }
    ]
  }
]

export const predefinedQuestionsOrganizationalConditionsDe = [
  {
    questionId: '0',
    question: "Auf welchem Niveau schätzen Sie Ihren Bedarf an notwendiger Ausrüstung, um Ausbildungsressourcen effektiv in Ihre Praxis zu integrieren?",
    shortName: "Materielle Ressourcen",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    organizationalType: "Materielle Ressourcen",
    options: [
      { "value": "Sehr hoch", "label": "Sehr hoch" },
      { "value": "Hoch", "label": "Hoch" },
      { "value": "Eher hoch", "label": "Eher hoch" },
      { "value": "Mittel", "label": "Mittel" },
      { "value": "Eher niedrig", "label": "Eher niedrig" },
      { "value": "Niedrig", "label": "Niedrig" },
      { "value": "Sehr niedrig", "label": "Sehr niedrig" }
    ]
  },
  {
    questionId: '1',
    question: "Auf welchem Niveau schätzen Sie Ihren Bedarf an verfügbarer Zeit, um Ausbildungsressourcen in Ihre Praxis zu integrieren?",
    shortName: "Zeitliche Ressourcen",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    organizationalType: "Zeitliche Ressourcen",
    options: [
      { "value": "Sehr hoch", "label": "Sehr hoch" },
      { "value": "Hoch", "label": "Hoch" },
      { "value": "Eher hoch", "label": "Eher hoch" },
      { "value": "Mittel", "label": "Mittel" },
      { "value": "Eher niedrig", "label": "Eher niedrig" },
      { "value": "Niedrig", "label": "Niedrig" },
      { "value": "Sehr niedrig", "label": "Sehr niedrig" }
    ]
  },
  {
    questionId: '2',
    question: "Auf welchem Niveau schätzen Sie Ihren Bedarf an Unterstützung durch Ihre Hierarchie, um Ausbildungsressourcen in Ihre Praxis zu integrieren?",
    shortName: "Hierarchische Unterstützung",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    organizationalType: "Hierarchische Unterstützung",
    options: [
      { "value": "Sehr hoch", "label": "Sehr hoch" },
      { "value": "Hoch", "label": "Hoch" },
      { "value": "Eher hoch", "label": "Eher hoch" },
      { "value": "Mittel", "label": "Mittel" },
      { "value": "Eher niedrig", "label": "Eher niedrig" },
      { "value": "Niedrig", "label": "Niedrig" },
      { "value": "Sehr niedrig", "label": "Sehr niedrig" }
    ]
  },
  {
    questionId: '3',
    question: "Auf welchem Niveau schätzen Sie Ihren Bedarf an Unterstützung durch Ihre Kollegen, um Ausbildungsressourcen in Ihre Praxis zu integrieren?",
    shortName: "Unterstützung durch Kollegen",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    organizationalType: "Unterstützung durch Kollegen",
    options: [
      { "value": "Sehr hoch", "label": "Sehr hoch" },
      { "value": "Hoch", "label": "Hoch" },
      { "value": "Eher hoch", "label": "Eher hoch" },
      { "value": "Mittel", "label": "Mittel" },
      { "value": "Eher niedrig", "label": "Eher niedrig" },
      { "value": "Niedrig", "label": "Niedrig" },
      { "value": "Sehr niedrig", "label": "Sehr niedrig" }
    ]
  },
  {
    questionId: '4',
    question: "Auf welchem Niveau schätzen Sie Ihren Bedarf an technischer Unterstützung, um Ausbildungsressourcen in Ihre Praxis zu integrieren?",
    shortName: "Technische Unterstützung",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    organizationalType: "Technische Unterstützung",
    options: [
      { "value": "Sehr hoch", "label": "Sehr hoch" },
      { "value": "Hoch", "label": "Hoch" },
      { "value": "Eher hoch", "label": "Eher hoch" },
      { "value": "Mittel", "label": "Mittel" },
      { "value": "Eher niedrig", "label": "Eher niedrig" },
      { "value": "Niedrig", "label": "Niedrig" },
      { "value": "Sehr niedrig", "label": "Sehr niedrig" }
    ]
  },
  {
    questionId: '5',
    question: "Auf welchem Niveau schätzen Sie Ihren Bedarf an Mentoring (z.B. durch einen erfahreneren Kollegen), um Ausbildungsressourcen in Ihre Praxis zu integrieren?",
    shortName: "Mentoring",
    workshop: "",
    fieldName: "q6",
    questionType: "radio-ordered",
    organizationalType: "Mentoring",
    options: [
      { "value": "Sehr hoch", "label": "Sehr hoch" },
      { "value": "Hoch", "label": "Hoch" },
      { "value": "Eher hoch", "label": "Eher hoch" },
      { "value": "Mittel", "label": "Mittel" },
      { "value": "Eher niedrig", "label": "Eher niedrig" },
      { "value": "Niedrig", "label": "Niedrig" },
      { "value": "Sehr niedrig", "label": "Sehr niedrig" }
    ]
  }
]

export const predefinedQuestionsImmediateReactionsDe = [
  {
    questionId: '0',
    question: "Wie hat Ihnen der Workshop gefallen?",
    shortName: "Wahrgenommenes Interesse",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    options: [
      { value: "Hat mir überhaupt nicht gefallen", label: "Hat mir überhaupt nicht gefallen" },
      { value: "Nicht besonders ansprechend", label: "Nicht besonders ansprechend" },
      { value: "Einigermaßen ansprechend", label: "Einigermaßen ansprechend" },
      { value: "Neutral", label: "Neutral" },
      { value: "Ziemlich ansprechend", label: "Ziemlich ansprechend" },
      { value: "Sehr ansprechend", label: "Sehr ansprechend" },
      { value: "Äußerst ansprechend", label: "Äußerst ansprechend" }
    ]
  },
  {
    questionId: '1',
    question: "Wie nützlich fanden Sie den Workshop?",
    shortName: "Wahrgenommener Nutzen",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    options: [
      { value: "Überhaupt nicht nützlich", label: "Überhaupt nicht nützlich" },
      { value: "Etwas nützlich", label: "Etwas nützlich" },
      { value: "Mäßig nützlich", label: "Mäßig nützlich" },
      { value: "Neutral", label: "Neutral" },
      { value: "Ziemlich nützlich", label: "Ziemlich nützlich" },
      { value: "Sehr nützlich", label: "Sehr nützlich" },
      { value: "Äußerst nützlich", label: "Äußerst nützlich" }
    ]
  },
  {
    questionId: '2',
    question: "Wie einfach finden Sie es, das Gesehene in eine Unterrichtsaktivität zu integrieren?",
    shortName: "Wahrgenommene Benutzerfreundlichkeit",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    options: [
      { value: "Überhaupt nicht einfach", label: "Überhaupt nicht einfach" },
      { value: "Etwas schwierig", label: "Etwas schwierig" },
      { value: "Mäßig einfach", label: "Mäßig einfach" },
      { value: "Neutral", label: "Neutral" },
      { value: "Ziemlich einfach", label: "Ziemlich einfach" },
      { value: "Sehr einfach", label: "Sehr einfach" },
      { value: "Äußerst einfach", label: "Äußerst einfach" }
    ]
  },
  {
    questionId: '3',
    question: "Wie zuversichtlich fühlen Sie sich, das Gesehene in eine Unterrichtsaktivität zu integrieren?",
    shortName: "Selbstwirksamkeit",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    options: [
      { value: "Überhaupt nicht zuversichtlich", label: "Überhaupt nicht zuversichtlich" },
      { value: "Etwas zuversichtlich", label: "Etwas zuversichtlich" },
      { value: "Mäßig zuversichtlich", label: "Mäßig zuversichtlich" },
      { value: "Neutral", label: "Neutral" },
      { value: "Ziemlich zuversichtlich", label: "Ziemlich zuversichtlich" },
      { value: "Sehr zuversichtlich", label: "Sehr zuversichtlich" },
      { value: "Äußerst zuversichtlich", label: "Äußerst zuversichtlich" }
    ]
  },
  {
    questionId: '4',
    question: "Inwieweit beabsichtigen Sie, das Gesehene für eine Unterrichtsaktivität zu nutzen?",
    shortName: "Verhaltensabsicht",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    options: [
      { value: "Beabsichtige überhaupt nicht zu nutzen", label: "Beabsichtige überhaupt nicht zu nutzen" },
      { value: "Beabsichtige etwas zu nutzen", label: "Beabsichtige etwas zu nutzen" },
      { value: "Beabsichtige mäßig zu nutzen", label: "Beabsichtige mäßig zu nutzen" },
      { value: "Neutral", label: "Neutral" },
      { value: "Beabsichtige ziemlich zu nutzen", label: "Beabsichtige ziemlich zu nutzen" },
      { value: "Beabsichtige sehr zu nutzen", label: "Beabsichtige sehr zu nutzen" },
      { value: "Beabsichtige definitiv zu nutzen", label: "Beabsichtige definitiv zu nutzen" }
    ]
  }
];


export const predefinedQuestionsSustainabilityConditionsDe = [
  {
    questionId: '0',
    question: "Wie kompatibel ist das vorgestellte Material mit Ihren bisherigen Unterrichtsmethoden?",
    shortName: "Kompatibilität",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-ordered",
    options: [
      { value: "Überhaupt nicht kompatibel", label: "Überhaupt nicht kompatibel" },
      { value: "Etwas kompatibel", label: "Etwas kompatibel" },
      { value: "Mäßig kompatibel", label: "Mäßig kompatibel" },
      { value: "Neutral", label: "Neutral" },
      { value: "Ziemlich kompatibel", label: "Ziemlich kompatibel" },
      { value: "Sehr kompatibel", label: "Sehr kompatibel" },
      { value: "Äußerst kompatibel", label: "Äußerst kompatibel" }
    ]
  },
  {
    questionId: '1',
    question: "Wie bewerten Sie den Fortschritt Ihrer Schüler mit den neuen Materialien?",
    shortName: "Schülerfortschritt",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-ordered",
    options: [
      { value: "Hat sich stark verschlechtert", label: "Hat sich stark verschlechtert" },
      { value: "Hat sich leicht verschlechtert", label: "Hat sich leicht verschlechtert" },
      { value: "Bleibt gleich", label: "Bleibt gleich" },
      { value: "Hat sich leicht verbessert", label: "Hat sich leicht verbessert" },
      { value: "Hat sich mäßig verbessert", label: "Hat sich mäßig verbessert" },
      { value: "Hat sich deutlich verbessert", label: "Hat sich deutlich verbessert" }
    ]
  },
  {
    questionId: '2',
    question: "Wie würden Sie Ihr Selbstvertrauen in Bezug auf die Nutzung der neuen Ressourcen bewerten?",
    shortName: "Selbstwirksamkeit",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-ordered",
    options: [
      { value: "Überhaupt nicht sicher", label: "Überhaupt nicht sicher" },
      { value: "Etwas sicher", label: "Etwas sicher" },
      { value: "Mäßig sicher", label: "Mäßig sicher" },
      { value: "Neutral", label: "Neutral" },
      { value: "Ziemlich sicher", label: "Ziemlich sicher" },
      { value: "Sehr sicher", label: "Sehr sicher" },
      { value: "Äußerst sicher", label: "Äußerst sicher" }
    ]
  },
  {
    questionId: '3',
    question: "Wie geistig anstrengend finden Sie es, die neuen Ressourcen umzusetzen?",
    shortName: "Kognitive Belastung",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-ordered",
    options: [
      { value: "Äußerst anstrengend", label: "Äußerst anstrengend" },
      { value: "Sehr anstrengend", label: "Sehr anstrengend" },
      { value: "Ziemlich anstrengend", label: "Ziemlich anstrengend" },
      { value: "Mäßig anstrengend", label: "Mäßig anstrengend" },
      { value: "Etwas anstrengend", label: "Etwas anstrengend" },
      { value: "Überhaupt nicht anstrengend", label: "Überhaupt nicht anstrengend" }
    ]
  },
  {
    questionId: '4',
    question: "Inwieweit erhöhen die neuen Ressourcen Ihre Arbeitsbelastung?",
    shortName: "Auswirkung auf Arbeitsaufwand",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-ordered",
    options: [
      { value: "Massiv erhöht", label: "Massiv erhöht" },
      { value: "Deutlich erhöht", label: "Deutlich erhöht" },
      { value: "Etwas erhöht", label: "Etwas erhöht" },
      { value: "Neutral", label: "Neutral" },
      { value: "Mäßig erhöht", label: "Mäßig erhöht" },
      { value: "Leicht erhöht", label: "Leicht erhöht" },
      { value: "Gar nicht erhöht", label: "Gar nicht erhöht" },
    ]
  },
  {
    questionId: '5',
    question: "Wie lange hat es gedauert, bis Sie die neuen Materialien nach der Schulung umgesetzt haben?",
    shortName: "Umsetzungszeit",
    workshop: "",
    fieldName: "q6",
    questionType: "radio-ordered",
    options: [
      { value: "Noch nicht umgesetzt", label: "Noch nicht umgesetzt" },
      { value: "Mehr als 3 Monate", label: "Mehr als 3 Monate" },
      { value: "Innerhalb von 3 Monaten", label: "Innerhalb von 3 Monaten" },
      { value: "Innerhalb eines Monats", label: "Innerhalb eines Monats" },
      { value: "Innerhalb einer Woche", label: "Innerhalb einer Woche" },
      { value: "Sofort", label: "Sofort" },
    ]
  }
];

export const predefinedQuestionsStudentCharacteristicsDe = [
  {
    questionId: '0',
    question: "Zu welcher Altersgruppe gehören Sie?",
    shortName: "Alter",
    workshop: "",
    fieldName: "q1",
    questionType: "radio-unordered",
    options: [
      { value: "Unter 10 Jahren", label: "Unter 10 Jahren" },
      { value: "10-15 Jahre", label: "10-15 Jahre" },
      { value: "16-20 Jahre", label: "16-20 Jahre" },
      { value: "21-25 Jahre", label: "21-25 Jahre" },
      { value: "26-30 Jahre", label: "26-30 Jahre" },
      { value: "31 Jahre und älter", label: "31 Jahre und älter" }
    ]
  },
  {
    questionId: '1',
    question: "Was ist Ihr Hauptfachgebiet oder Interesse?",
    shortName: "Spezialisierung",
    workshop: "",
    fieldName: "q2",
    questionType: "radio-unordered",
    options: [
      { value: "Naturwissenschaften", label: "Naturwissenschaften" },
      { value: "Kunst", label: "Kunst" },
      { value: "Technologie", label: "Technologie" },
      { value: "Mathematik", label: "Mathematik" },
      { value: "Geisteswissenschaften", label: "Geisteswissenschaften" },
      { value: "Andere", label: "Andere" }
    ]
  },
  {
    questionId: '2',
    question: "Auf welchem Bildungsniveau befinden Sie sich derzeit?",
    shortName: "Bildungsstand",
    workshop: "",
    fieldName: "q3",
    questionType: "radio-unordered",
    options: [
      { value: "Grundschule", label: "Grundschule" },
      { value: "Sekundarstufe", label: "Sekundarstufe" },
      { value: "Bachelor", label: "Bachelor" },
      { value: "Master/Doktor", label: "Master/Doktor" }
    ]
  },
  {
    questionId: '3',
    question: "Wie oft nutzen Sie digitale Werkzeuge zum Lernen oder in der Freizeit?",
    shortName: "Nutzung digitaler Werkzeuge",
    workshop: "",
    fieldName: "q4",
    questionType: "radio-unordered",
    options: [
      { value: "Nie", label: "Nie" },
      { value: "Selten", label: "Selten" },
      { value: "Manchmal", label: "Manchmal" },
      { value: "Oft", label: "Oft" },
      { value: "Immer", label: "Immer" }
    ]
  },
  {
    questionId: '4',
    question: "Glauben Sie, dass bestimmte Geschlechter besser für die Informatik geeignet sind?",
    shortName: "Geschlechterstereotype",
    workshop: "",
    fieldName: "q5",
    questionType: "radio-unordered",
    options: [
      { value: "Ja, Männer", label: "Ja, Männer" },
      { value: "Ja, Frauen", label: "Ja, Frauen" },
      { value: "Nein, alle Geschlechter sind gleich gut geeignet", label: "Nein, alle Geschlechter sind gleich gut geeignet" },
      { value: "Ich habe dazu keine Meinung", label: "Ich habe dazu keine Meinung" }
    ]
  }
];
