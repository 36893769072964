import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Sidebar from "../../scenes/global/Sidebar";
import Topbar from "../../scenes/global/Topbar";
import { InputLabel, Box, Tabs, Tab, MenuItem, FormControl, Typography, Button } from "@mui/material";
import Select from '@mui/material/Select';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import BarChartReports from '../../components/BarChartReports';
import DisplayTextQuestion from '../../components/DisplayTextQuestions';
import CommentIcon from '@mui/icons-material/Comment';
import { BACKEND_URL } from "../../config"; 
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

const Reports = () => {
  const [monitorings, setMonitorings] = useState([]);
  const [selectedMonitoring, setSelectedMonitoring] = useState('');
  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedDayAssessments, setSelectedDayAssessments] = useState([]);
  const [totalAssessments, setTotalAssessments] = useState(0)
  const [latestResponseDate, setLatestResponseDate] = useState('');
  const [currentUserId, setCurrentUserId] = useState('');
  const [valueOne, setValueOne] = useState(0);
  const [valueTwo, setValueTwo] = useState(0);
  const [valueThree, setValueThree] = useState(0);
  const [valueFour, setValueFour] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [commentData, setCommentData] = useState([]);
  const [isExportingPDF, setIsExportingPDF] = useState(false);

  // Get all the monitorings of the current user when page loads
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log('No token found');
      return;
    }
    const decodedToken = jwt_decode(token);
    setCurrentUserId(decodedToken._id)

    const fetchMonitorings = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${BACKEND_URL}/monitorings/${decodedToken._id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setMonitorings(response.data.monitorings);

      } catch (error) {
        console.log(error);
      }
    };

    fetchMonitorings(); 
  }, [currentUserId]); 

  useEffect(() => {
    if (selectedMonitoring) {
      const selectedMonitoringData = monitorings.find(monitoring => monitoring._id === selectedMonitoring);
      if (selectedMonitoringData) {
        const days = selectedMonitoringData.assessments.map(assessment => assessment.day);
        setDays(Array.from(new Set(days)));
      } else {
        setDays([]);
      }
    }
  }, [selectedMonitoring, monitorings]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedMonitoring && selectedDay) {
        const selectedMonitoringData = monitorings.find(monitoring => monitoring._id === selectedMonitoring);

        if (selectedMonitoringData) {
          const assessments = selectedMonitoringData.assessments.filter(assessment => assessment.day === selectedDay && (assessment.status === 'Open' || assessment.status === 'Close'));
          const assessmentsInfo = assessments.map(({ _id, type, name }) => ({ _id, type, name }));

          const assessmentsWithResponses = await Promise.all(assessmentsInfo.map(async ({ _id, type, name }) => {
            const responses = await getResponsesById(_id);
            return { name, type, responses: responses ? responses : [] };
          }));

          setSelectedDayAssessments(assessmentsWithResponses);
          setTotalAssessments(assessmentsWithResponses.length);

          const chartDataByType = prepareChartData(assessmentsWithResponses);
          const commentDataByType = prepareCommentData(assessmentsWithResponses);

          setChartData(chartDataByType);
          setCommentData(commentDataByType);

          const allResponses = assessmentsWithResponses.flatMap(assessment => assessment.responses);
          const latestDate = getLatestResponseDate(allResponses);

          if (latestDate) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            let hours = latestDate.getHours();
            const minutes = String(latestDate.getMinutes()).padStart(2, '0');
            const amOrPm = hours >= 12 ? 'PM' : 'AM';

            hours = hours % 12;
            hours = hours ? hours : 12; 
            const timeString = `${String(hours).padStart(2, '0')}:${minutes} ${amOrPm}`;

            setLatestResponseDate(
              latestDate.toDateString() === today.toDateString() ? `Today at ${timeString}` : `${latestDate.toLocaleDateString()} at ${timeString}`
            );
          } else {
            setLatestResponseDate(null);
          }
        }
      }
    };

    fetchData();
  }, [selectedMonitoring, selectedDay]);

  const handleChangeTabsOne = (event, newValue) => {
    setValueOne(newValue);
  };

  const handleChangeTabsTwo = (event, newValue) => {
    setValueTwo(newValue);
  };

  const handleChangeTabsThree = (event, newValue) => {
    setValueThree(newValue);
  };

  const handleChangeTabsFour = (event, newValue) => {
    setValueFour(newValue);
  };

  const handleChangeMonitoring = (event) => {
    setSelectedMonitoring(event.target.value);
    setSelectedDay('');
  };

  const handleChangeDay = (event) => {
    setSelectedDay(event.target.value);
  };

  const getResponsesById = async (_id) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${BACKEND_URL}/responses/assessment/${_id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching responses: ${error}`);
    }
  };

  const getLatestResponseDate = (responses) => {
    if (responses.length === 0) return null;

    let latest = new Date(responses[responses.length - 1].completionDate);
    responses.forEach(response => {
      const responseDate = new Date(response.date);
      if (responseDate > latest) latest = responseDate;
    });

    return latest;
  };

const convertToCSV = (data) => {

  const escapeCSVValue = (value) => {
    if (typeof value === 'string' && (value.includes(';') || value.includes('\n') || value.includes('\r'))) {
      return `"${value.replace(/"/g, '""')}"`;
    }
    return value;
  };

  let csvContent = [];

  if (!data || !Array.isArray(data) || data.length === 0) {
    console.error('convertToCSV: Invalid data array');
    return '';
  }

  let headers = ["Assessment Name", "Assessment Type", "User ID", "Monitoring ID", "Linking ID", "Completion Date"];
  let questionHeaders = {};
  
  data.forEach(assessment => {
    assessment.responses.forEach(response => {
      response.survey.forEach(surveyItem => {
        const workshopHeader = surveyItem.workshop ? ` - ${surveyItem.workshop}` : "";
        const baseHeader = `${surveyItem.shortName}${workshopHeader}`;

        if (surveyItem.questionType === 'matrix-radio-ordered') {
          surveyItem.items.forEach(item => {
            questionHeaders[`${baseHeader} - ${item} - Response`] = true;
          });
        } else if (surveyItem.questionType === 'text') {
          questionHeaders[`${baseHeader} - Open Question`] = true;
        } else {
          questionHeaders[`${baseHeader} - Response`] = true;
        }

        if (surveyItem.correctAnswer) {
          questionHeaders[`${baseHeader} - Correct Answer`] = true;
        }
      });
    });
  });

  headers = headers.concat(Object.keys(questionHeaders));
  csvContent.push(headers.map(escapeCSVValue).join(';'));

  data.forEach(assessment => {
    assessment.responses.forEach(response => {
      let completionDate = new Date(response.completionDate);
      let formattedDate = `${completionDate.getDate().toString().padStart(2, '0')}/${(completionDate.getMonth() + 1).toString().padStart(2, '0')}/${completionDate.getFullYear()}`;

      let row = [
        escapeCSVValue(assessment.name),
        escapeCSVValue(assessment.type),
        escapeCSVValue(response.userId),
        escapeCSVValue(response.monitoringId),
        escapeCSVValue(response.survey[0].linkingId),
        escapeCSVValue(formattedDate),
      ];

      const responseRowData = headers.slice(6).reduce((rowData, header) => {
        rowData[header] = "";
        return rowData;
      }, {});

      response.survey.forEach(surveyItem => {
        const workshopHeader = surveyItem.workshop ? ` - ${surveyItem.workshop}` : "";
        const baseHeader = `${surveyItem.shortName}${workshopHeader}`;

        console.log("questionType:", surveyItem.questionType)

        if (surveyItem.questionType === 'matrix-radio-ordered') {
          surveyItem.items.forEach((item, rowIndex) => {
            const responseHeader = `${baseHeader} - ${item} - Response`;
            responseRowData[responseHeader] = escapeCSVValue(surveyItem.response[rowIndex]);
          });
        } else if (surveyItem.questionType === 'text') {
          const responseHeader = `${baseHeader} - Open Question`;
          responseRowData[responseHeader] = escapeCSVValue(surveyItem.response[0]);
        } else {
          const responseHeader = `${baseHeader} - Response`;
          responseRowData[responseHeader] = escapeCSVValue(Array.isArray(surveyItem.response) ? surveyItem.response.join(";") : surveyItem.response);
        }

        if (surveyItem.correctAnswer) {
          const correctAnswerHeader = `${baseHeader} - Correct Answer`;
          responseRowData[correctAnswerHeader] = escapeCSVValue(surveyItem.correctAnswer);
        }
      });

      row = row.concat(Object.values(responseRowData));
      csvContent.push(row.join(';'));
    });
  });

  return csvContent.join('\n');
};

  const handleExportData = async () => {
    const zip = new JSZip();

    const groupedData = groupDataByAssessment(selectedDayAssessments);

    Object.entries(groupedData).forEach(([groupName, data]) => {
      const csvData = convertToCSV(data);
      zip.file(`${groupName}.csv`, csvData);
    });

    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, "assessments.zip");
  };

  const groupDataByAssessment = (data) => {
    const groupedData = {};
    data.forEach(assessment => {
      const groupName = assessment.name; 
      if (!groupedData[groupName]) {
        groupedData[groupName] = [];
      }
      groupedData[groupName].push(assessment);
    });
    return groupedData;
  };

  const handleExportReport = async () => {
    try {
      setIsExportingPDF(true);
      if (!selectedMonitoring || !selectedDay) {
        console.error('No monitoring selected');
        return;
      }
      const token = localStorage.getItem("token");

      const response = await axios.post(`${BACKEND_URL}/export/pdf`, { monitoringId: selectedMonitoring, selectedDay: selectedDay }, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const file = new Blob([response.data], { type: 'application/pdf' });

      saveAs(file, 'report.pdf');
    } catch (error) {
      console.error('Error exporting PDF:', error);
    } finally {
      setIsExportingPDF(false);
    }
  };

  const prepareChartData = (data) => {
    let chartDataByType = {};

    const uniqueTypes = Array.from(new Set(data.map(item => item.type)));

    uniqueTypes.forEach(type => {
      switch (type) {
        case 'Trainee characteristics':
          chartDataByType[type] = prepareTraineeChartData(data);
          break;
        case 'Training characteristics':
          chartDataByType[type] = prepareTrainingChartData(data);
          break;
        case 'Immediate reactions':
          chartDataByType[type] = prepareReactionsChartData(data);
          break;
        case 'Learning':
          chartDataByType[type] = prepareLearningChartData(data);
          break;
        case 'Organizational conditions':
          chartDataByType[type] = prepareOrganizationalChartData(data);
          break;
        case 'Behavioral changes':
          chartDataByType[type] = prepareBehavioralChartData(data);
          break;
        case 'Sustainability conditions':
          chartDataByType[type] = prepareSustainabilityChartData(data);
          break;
        case 'Student characteristics':
          chartDataByType[type] = prepareStudentChartData(data);
          break;
        case 'Student learning outcomes':
          chartDataByType[type] = prepareStudentLearningChartData(data);
          break;
        default:
          chartDataByType[type] = [];
      }
    });

    return chartDataByType;
  };

  const prepareCommentData = (data) => {
    let commentDataByType = {};

    const uniqueTypes = Array.from(new Set(data.map(item => item.type)));

    uniqueTypes.forEach(type => {
      switch (type) {
        case 'Trainee characteristics':
          commentDataByType[type] = prepareTraineeCommentData(data);
          break;
        case 'Training characteristics':
          commentDataByType[type] = prepareTrainingCommentData(data);
          break;
        case 'Immediate reactions':
          commentDataByType[type] = prepareReactionsCommentData(data);
          break;
        case 'Learning':
          commentDataByType[type] = prepareLearningCommentData(data);
          break;
        case 'Organizational conditions':
          commentDataByType[type] = prepareOrganizationalCommentData(data);
          break;
        case 'Behavioral changes':
          commentDataByType[type] = prepareBehavioralCommentData(data);
          break;
        case 'Sustainability conditions':
          commentDataByType[type] = prepareSustainabilityCommentData(data);
          break;
        case 'Student characteristics':
          commentDataByType[type] = prepareStudentCommentData(data);
          break;
        case 'Student learning outcomes':
          commentDataByType[type] = prepareStudentLearningCommentData(data);
          break;
        default:
          commentDataByType[type] = [];
      }
    });

    return commentDataByType;
  };

  const prepareTraineeChartData = (assessmentsWithResponses) => {
    let traineeChartData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Trainee characteristics');

    filteredData.forEach(assessment => {
      const { name: assessmentName } = assessment;

      assessment.responses.forEach(response => {
        response.survey.forEach(({
          shortName,
          response: surveyResponse,
          choices,
          items,
          question,
          questionType,
          workshop,
        }) => {
          const key = workshop || "default";

          if (!traineeChartData[key]) {
            traineeChartData[key] = [];
          }

          if (questionType === 'text') {
            return;
          }

          if (questionType === 'matrix-radio-ordered') {
            items.forEach((item, rowIndex) => {
              const uniqueItemKey = `${assessmentName}-${shortName}-${item}`;

              let result = traineeChartData[key].find(r => r.uniqueQuestionKey === uniqueItemKey);

              if (!result) {
                result = {
                  uniqueQuestionKey: uniqueItemKey,
                  question: `${question} - ${item}`,
                  shortName: item,
                  choices: choices,
                  type: questionType,
                  assessmentName: assessmentName,
                  workshop: workshop || "default",
                };

                for (let i = 1; i <= choices.length; i++) {
                  result[i] = 0;
                }
                traineeChartData[key].push(result);
              }

              const responseValue = surveyResponse[rowIndex];
              const responseIndex = choices.indexOf(responseValue);
              if (responseIndex !== -1) {
                result[responseIndex + 1]++;
              }
            });
          } else {
            const uniqueQuestionKey = `${assessmentName}-${shortName}`;

            let result = traineeChartData[key].find(r => r.uniqueQuestionKey === uniqueQuestionKey);

            if (!result) {
              result = {
                uniqueQuestionKey,
                question: question,
                shortName: shortName,
                choices: choices,
                type: questionType,
                assessmentName: assessmentName,
                workshop: workshop || "default",
              };

              for (let i = 1; i <= choices.length; i++) {
                result[i] = 0;
              }
              traineeChartData[key].push(result);
            }

            if (questionType === 'radio-ordered' || questionType === 'radio-unordered') {
              const responseIndex = choices.indexOf(surveyResponse[0]);
              if (responseIndex !== -1) {
                result[responseIndex + 1]++;
              }
            } else if (questionType === 'checkbox') {
              surveyResponse.forEach(responseValue => {
                const index = choices.indexOf(responseValue);
                if (index !== -1) {
                  if (!result.hasOwnProperty(index + 1)) {
                    result[index + 1] = 0;
                  }
                  result[index + 1]++;
                }
              });
            }
          }
        });
      });
    });

    return traineeChartData;
  };

  const prepareTraineeCommentData = (assessmentsWithResponses) => {
    let traineeCommentData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Trainee characteristics');

    filteredData.forEach(({ name: assessmentName, responses }) => {
      responses.forEach(({ survey }) => {
        survey.forEach(({ question, response, questionType, workshop, shortName }) => {
          const key = workshop || "default";
          const uniqueQuestionKey = `${assessmentName}-${shortName || question}`;

          if (questionType === 'text') {
            if (!traineeCommentData[key]) {
              traineeCommentData[key] = {};
            }

            if (!traineeCommentData[key][uniqueQuestionKey]) {
              traineeCommentData[key][uniqueQuestionKey] = {
                uniqueQuestionKey: uniqueQuestionKey,
                question: question,
                shortName: shortName,
                assessmentName: assessmentName,
                responses: []
              };
            }

            traineeCommentData[key][uniqueQuestionKey].responses.push(response[0]);
          }
        });
      });
    });

    Object.keys(traineeCommentData).forEach(key => {
      traineeCommentData[key] = Object.values(traineeCommentData[key]);
    });

    return traineeCommentData;
  };

  const prepareTrainingChartData = (assessmentsWithResponses) => {
    let trainingChartData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Training characteristics');

    filteredData.forEach(assessment => {
      const { name: assessmentName } = assessment;

      assessment.responses.forEach(response => {
        response.survey.forEach(({
          response: surveyResponse,
          choices,
          items,
          question,
          shortName,
          questionType,
          workshop,
        }) => {
          const key = workshop || "default";

          if (!trainingChartData[key]) {
            trainingChartData[key] = [];
          }

          if (questionType === 'text') {
            return;
          }

          if (questionType === 'matrix-radio-ordered') {
            items.forEach((item, rowIndex) => {
              const uniqueItemKey = `${assessmentName}-${item}`;

              let result = trainingChartData[key].find(r => r.uniqueQuestionKey === uniqueItemKey);

              if (!result) {
                result = {
                  uniqueQuestionKey: uniqueItemKey,
                  question: `${question} - ${item}`,
                  shortName: item,
                  choices: choices,
                  type: questionType,
                  assessmentName: assessmentName,
                  workshop: workshop || "default",
                };

                for (let i = 1; i <= choices.length; i++) {
                  result[i] = 0;
                }
                trainingChartData[key].push(result);
              }

              const responseValue = surveyResponse[rowIndex];
              const responseIndex = choices.indexOf(responseValue);
              if (responseIndex !== -1) {
                result[responseIndex + 1]++;
              }
            });
          } else {
            const uniqueQuestionKey = `${assessmentName}-${question}`;

            let result = trainingChartData[key].find(r => r.uniqueQuestionKey === uniqueQuestionKey);

            if (!result) {
              result = {
                uniqueQuestionKey,
                question: question,
                shortName: shortName,
                choices: choices,
                type: questionType,
                assessmentName: assessmentName,
                workshop: workshop || "default",
              };

              for (let i = 1; i <= choices.length; i++) {
                result[i] = 0;
              }
              trainingChartData[key].push(result);
            }

            if (questionType === 'radio-ordered' || questionType === 'radio-unordered') {
              const responseIndex = choices.indexOf(surveyResponse[0]);
              if (responseIndex === -1) {
                return;
              }
              result[responseIndex + 1]++;
            } else if (questionType === 'checkbox') {
              const responseIndices = [];
              for (const responseValue of surveyResponse) {
                const index = choices.indexOf(responseValue);
                if (index !== -1) {
                  responseIndices.push(index);
                }
              }
              for (const index of responseIndices) {
                result[index + 1]++;
              }
            }
          }
        });
      });
    });

    return trainingChartData;
  };

  const prepareTrainingCommentData = (assessmentsWithResponses) => {
    let trainingCommentData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Training characteristics');

    filteredData.forEach(({ name: assessmentName, responses }) => {
      responses.forEach(({ survey }) => {
        survey.forEach(({ question, response, questionType, workshop, shortName }) => {
          const key = workshop || "default";
          const uniqueQuestionKey = `${assessmentName}-${shortName || question}`;

          if (questionType === 'text') {
            if (!trainingCommentData[key]) {
              trainingCommentData[key] = {};
            }

            if (!trainingCommentData[key][uniqueQuestionKey]) {
              trainingCommentData[key][uniqueQuestionKey] = {
                uniqueQuestionKey: uniqueQuestionKey,
                question: question,
                shortName: shortName,
                assessmentName: assessmentName,
                responses: []
              };
            }

            trainingCommentData[key][uniqueQuestionKey].responses.push(response[0]);
          }
        });
      });
    });

    Object.keys(trainingCommentData).forEach(key => {
      trainingCommentData[key] = Object.values(trainingCommentData[key]);
    });

    return trainingCommentData;
  };

  const prepareReactionsChartData = (assessmentsWithResponses) => {
    let reactionsChartData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Immediate reactions');

    filteredData.forEach(assessment => {
      const { name: assessmentName } = assessment;

      assessment.responses.forEach(response => {
        response.survey.forEach(({
          response: surveyResponse,
          choices,
          items,
          question,
          shortName,
          questionType,
          workshop,
        }) => {
          const key = workshop || "default";

          if (!reactionsChartData[key]) {
            reactionsChartData[key] = [];
          }

          if (questionType === 'text') {
            return;
          }

          if (questionType === 'matrix-radio-ordered') {
            items.forEach((item, rowIndex) => {
              const uniqueItemKey = `${assessmentName}-${item}`;

              let result = reactionsChartData[key].find(r => r.uniqueQuestionKey === uniqueItemKey);

              if (!result) {
                result = {
                  uniqueQuestionKey: uniqueItemKey,
                  question: `${question} - ${item}`,
                  shortName: item,
                  choices: choices,
                  type: questionType,
                  assessmentName: assessmentName,
                  workshop: workshop || "default",
                };

                for (let i = 1; i <= choices.length; i++) {
                  result[i] = 0;
                }
                reactionsChartData[key].push(result);
              }

              const responseValue = surveyResponse[rowIndex];
              const responseIndex = choices.indexOf(responseValue);
              if (responseIndex !== -1) {
                result[responseIndex + 1]++;
              }
            });
          } else {
            const uniqueQuestionKey = `${assessmentName}-${question}`;

            let result = reactionsChartData[key].find(r => r.uniqueQuestionKey === uniqueQuestionKey);

            if (!result) {
              result = {
                uniqueQuestionKey,
                question: question,
                shortName: shortName,
                choices: choices,
                type: questionType,
                assessmentName: assessmentName,
                workshop: workshop || "default",
              };

              for (let i = 1; i <= choices.length; i++) {
                result[i] = 0;
              }
              reactionsChartData[key].push(result);
            }

            if (questionType === 'radio-ordered' || questionType === 'radio-unordered') {
              const responseIndex = choices.indexOf(surveyResponse[0]);
              if (responseIndex !== -1) {
                result[responseIndex + 1]++;
              }
            } else if (questionType === 'checkbox') {
              surveyResponse.forEach(responseValue => {
                const index = choices.indexOf(responseValue);
                if (index !== -1) {
                  if (!result.hasOwnProperty(index + 1)) {
                    result[index + 1] = 0;
                  }
                  result[index + 1]++;
                }
              });
            }
          }
        });
      });
    });

    return reactionsChartData;
  };

  const prepareReactionsCommentData = (assessmentsWithResponses) => {
    let reactionsCommentData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Immediate reactions');

    filteredData.forEach(assessment => {
      const { name: assessmentName } = assessment;

      assessment.responses.forEach(response => {
        response.survey.forEach(({ shortName, response: surveyResponse, choices, question, questionType, workshop }) => {
          const key = workshop || "default";
          const uniqueQuestionKey = `${assessmentName}-${shortName}`;

          if (!reactionsCommentData[key]) {
            reactionsCommentData[key] = {};
          }

          if (questionType === 'text') {
            if (!reactionsCommentData[key][uniqueQuestionKey]) {
              reactionsCommentData[key][uniqueQuestionKey] = {
                uniqueQuestionKey: uniqueQuestionKey,
                question: question,
                shortName: shortName,
                assessmentName: assessmentName,
                workshop: workshop || "default",
                responses: []
              };
            }

            reactionsCommentData[key][uniqueQuestionKey].responses.push(surveyResponse[0]);
          }
        });
      });
    });

    Object.keys(reactionsCommentData).forEach(key => {
      reactionsCommentData[key] = Object.values(reactionsCommentData[key]);
    });

    return reactionsCommentData;
  };

  const prepareLearningChartData = (assessmentsWithResponses) => {
    let learningChartData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Learning');

    filteredData.forEach(assessment => {
      const { name: assessmentName } = assessment;

      assessment.responses.forEach(response => {
        response.survey.forEach(({
          shortName,
          response: surveyResponse,
          choices,
          correctAnswer,
          competencies,
          question,
          questionType,
          workshop,
        }) => {
          if (questionType === 'text') {
            return;
          }

          const key = workshop || "default";
          const uniqueQuestionKey = `${assessmentName}-${shortName}`;

          if (!learningChartData[key]) {
            learningChartData[key] = [];
          }

          let result = learningChartData[key].find(r => r.uniqueQuestionKey === uniqueQuestionKey);
          if (!result) {
            result = {
              uniqueQuestionKey,
              question: question,
              shortName: shortName,
              choices: choices,
              correctAnswer: correctAnswer,
              competencies: competencies.map(competency => competency.split(':')[0]),
              type: questionType,
              assessmentName: assessmentName,
              workshop: workshop || "default",
            };

            for (let i = 1; i <= choices.length; i++) {
              result[i] = 0;
            }
            learningChartData[key].push(result);
          }

          if (questionType === 'radio-ordered' || questionType === 'radio-unordered') {
            const responseIndex = choices.indexOf(surveyResponse[0]);
            if (responseIndex === -1) {
              return;
            }
            result[responseIndex + 1]++;
          } else if (questionType === 'checkbox') {
            const responseIndices = [];
            for (const responseValue of surveyResponse) {
              const index = choices.indexOf(responseValue);
              if (index !== -1) {
                responseIndices.push(index);
              }
            }
            for (const index of responseIndices) {
              result[index + 1]++;
            }
          }
        });
      });
    });

    return learningChartData;
  };

  const prepareLearningCommentData = (assessmentsWithResponses) => {
    let learningCommentData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Learning');

    filteredData.forEach(assessment => {
      const { name: assessmentName } = assessment;

      assessment.responses.forEach(response => {
        response.survey.forEach(({ question, response: surveyResponse, questionType, workshop, shortName }) => {
          const key = workshop || "default";
          const uniqueQuestionKey = `${assessmentName}-${shortName || question}`;

          if (questionType === 'text') {
            if (!learningCommentData[key]) {
              learningCommentData[key] = {};
            }

            if (!learningCommentData[key][uniqueQuestionKey]) {
              learningCommentData[key][uniqueQuestionKey] = {
                uniqueQuestionKey: uniqueQuestionKey,
                question: question,
                shortName: shortName,
                assessmentName: assessmentName,
                responses: []
              };
            }

            learningCommentData[key][uniqueQuestionKey].responses.push(surveyResponse[0]);
          }
        });
      });
    });

    Object.keys(learningCommentData).forEach(key => {
      learningCommentData[key] = Object.values(learningCommentData[key]);
    });

    return learningCommentData;
  };

  const prepareOrganizationalChartData = (assessmentsWithResponses) => {
    let organizationalChartData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Organizational conditions');

    filteredData.forEach(assessment => {
      const { name: assessmentName } = assessment;

      assessment.responses.forEach(response => {
        response.survey.forEach(({
          response: surveyResponse,
          choices,
          items,
          question,
          shortName,
          questionType,
          workshop,
        }) => {
          const key = workshop || "default";

          if (!organizationalChartData[key]) {
            organizationalChartData[key] = [];
          }

          if (questionType === 'text') {
            return;
          }

          if (questionType === 'matrix-radio-ordered') {
            items.forEach((item, rowIndex) => {
              const uniqueItemKey = `${assessmentName}-${item}`;

              let result = organizationalChartData[key].find(r => r.uniqueQuestionKey === uniqueItemKey);

              if (!result) {
                result = {
                  uniqueQuestionKey: uniqueItemKey,
                  question: `${question} - ${item}`,
                  shortName: item,
                  choices: choices,
                  type: questionType,
                  assessmentName: assessmentName,
                  workshop: workshop || "default",
                };

                for (let i = 1; i <= choices.length; i++) {
                  result[i] = 0;
                }
                organizationalChartData[key].push(result);
              }

              const responseValue = surveyResponse[rowIndex];
              const responseIndex = choices.indexOf(responseValue);
              if (responseIndex !== -1) {
                result[responseIndex + 1]++;
              }
            });
          } else {
            const uniqueQuestionKey = `${assessmentName}-${question}`;

            let result = organizationalChartData[key].find(r => r.uniqueQuestionKey === uniqueQuestionKey);

            if (!result) {
              result = {
                uniqueQuestionKey,
                question: question,
                shortName: shortName,
                choices: choices,
                type: questionType,
                assessmentName: assessmentName,
                workshop: workshop || "default",
              };

              for (let i = 1; i <= choices.length; i++) {
                result[i] = 0;
              }
              organizationalChartData[key].push(result);
            }

            if (questionType === 'radio-ordered' || questionType === 'radio-unordered') {
              const responseIndex = choices.indexOf(surveyResponse[0]);
              if (responseIndex === -1) {
                return;
              }
              result[responseIndex + 1]++;
            } else if (questionType === 'checkbox') {
              const responseIndices = [];
              for (const responseValue of surveyResponse) {
                const index = choices.indexOf(responseValue);
                if (index !== -1) {
                  responseIndices.push(index);
                }
              }
              for (const index of responseIndices) {
                result[index + 1]++;
              }
            }
          }
        });
      });
    });

    return organizationalChartData;
  };

  const prepareOrganizationalCommentData = (assessmentsWithResponses) => {
    let organizationalCommentData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Organizational conditions');

    filteredData.forEach(assessment => {
      const { name: assessmentName } = assessment;

      assessment.responses.forEach(response => {
        response.survey.forEach(({ question, response: surveyResponse, questionType, workshop, shortName }) => {
          const key = workshop || "default";
          const uniqueQuestionKey = `${assessmentName}-${shortName || question}`;

          if (questionType === 'text') {
            if (!organizationalCommentData[key]) {
              organizationalCommentData[key] = {};
            }

            if (!organizationalCommentData[key][uniqueQuestionKey]) {
              organizationalCommentData[key][uniqueQuestionKey] = {
                uniqueQuestionKey: uniqueQuestionKey,
                question: question,
                shortName: shortName,
                assessmentName: assessmentName,
                responses: []
              };
            }

            organizationalCommentData[key][uniqueQuestionKey].responses.push(surveyResponse[0]);
          }
        });
      });
    });

    Object.keys(organizationalCommentData).forEach(key => {
      organizationalCommentData[key] = Object.values(organizationalCommentData[key]);
    });

    return organizationalCommentData;
  };

  const prepareBehavioralChartData = (assessmentsWithResponses) => {
    let behavioralChartData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Behavioral changes');

    filteredData.forEach(assessment => {
      const { name: assessmentName } = assessment;

      assessment.responses.forEach(response => {
        response.survey.forEach(({
          shortName,
          response: surveyResponse,
          choices,
          competencies,     
          question,
          questionType,
          workshop,
        }) => {
          if (questionType === 'text') {
            return;
          }

          const key = workshop || "default";
          const uniqueQuestionKey = `${assessmentName}-${shortName}`;

          if (!behavioralChartData[key]) {
            behavioralChartData[key] = [];
          }

          let result = behavioralChartData[key].find(r => r.uniqueQuestionKey === uniqueQuestionKey);
          if (!result) {
            result = {
              uniqueQuestionKey,
              question: question,
              shortName: shortName,
              choices: choices,
              competencies: competencies.map(competency => competency.split(':')[0]),
              type: questionType,
              assessmentName: assessmentName,
              workshop: workshop || "default",
            };

            for (let i = 1; i <= choices.length; i++) {
              result[i] = 0;
            }
            behavioralChartData[key].push(result);
          }

          if (questionType === 'radio-ordered' || questionType === 'radio-unordered') {
            const responseIndex = choices.indexOf(surveyResponse[0]);
            if (responseIndex === -1) {
              return;
            }
            result[responseIndex + 1]++;
          } else if (questionType === 'checkbox') {
            const responseIndices = [];
            for (const responseValue of surveyResponse) {
              const index = choices.indexOf(responseValue);
              if (index !== -1) {
                responseIndices.push(index);
              }
            }
            for (const index of responseIndices) {
              result[index + 1]++;
            }
          }
        });
      });
    });

    return behavioralChartData;
  };

  const prepareBehavioralCommentData = (assessmentsWithResponses) => {
    let behavioralCommentData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Behavioral changes');

    filteredData.forEach(assessment => {
      const { name: assessmentName } = assessment;

      assessment.responses.forEach(response => {
        response.survey.forEach(({ question, response: surveyResponse, questionType, workshop, shortName }) => {
          const key = workshop || "default";
          const uniqueQuestionKey = `${assessmentName}-${shortName || question}`;

          if (questionType === 'text') {
            if (!behavioralCommentData[key]) {
              behavioralCommentData[key] = {};
            }

            if (!behavioralCommentData[key][uniqueQuestionKey]) {
              behavioralCommentData[key][uniqueQuestionKey] = {
                uniqueQuestionKey: uniqueQuestionKey,
                question: question,
                shortName: shortName,
                assessmentName: assessmentName,
                responses: []
              };
            }

            behavioralCommentData[key][uniqueQuestionKey].responses.push(surveyResponse[0]);
          }
        });
      });
    });

    Object.keys(behavioralCommentData).forEach(key => {
      behavioralCommentData[key] = Object.values(behavioralCommentData[key]);
    });

    return behavioralCommentData;
  };

  const prepareSustainabilityChartData = (assessmentsWithResponses) => {
    let sustainabilityChartData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Sustainability conditions');

    filteredData.forEach(assessment => {
      const { name: assessmentName } = assessment;

      assessment.responses.forEach(response => {
        response.survey.forEach(({
          response: surveyResponse,
          choices,
          items,
          question,
          shortName,
          questionType,
          workshop,
        }) => {
          if (questionType === 'text') {
            return;
          }

          const key = workshop || "default";

          if (!sustainabilityChartData[key]) {
            sustainabilityChartData[key] = [];
          }

          if (questionType === 'matrix-radio-ordered') {
            items.forEach((item, rowIndex) => {
              const uniqueItemKey = `${assessmentName}-${item}`;

              let result = sustainabilityChartData[key].find(r => r.uniqueQuestionKey === uniqueItemKey);

              if (!result) {
                result = {
                  uniqueQuestionKey: uniqueItemKey,
                  question: `${question} - ${item}`,
                  shortName: item,
                  choices: choices,
                  type: questionType,
                  assessmentName: assessmentName,
                  workshop: workshop || "default",
                };

                for (let i = 1; i <= choices.length; i++) {
                  result[i] = 0;
                }
                sustainabilityChartData[key].push(result);
              }

              const responseValue = surveyResponse[rowIndex];
              const responseIndex = choices.indexOf(responseValue);
              if (responseIndex !== -1) {
                result[responseIndex + 1]++;
              }
            });
          } else {
            const uniqueQuestionKey = `${assessmentName}-${question}`;

            let result = sustainabilityChartData[key].find(r => r.uniqueQuestionKey === uniqueQuestionKey);

            if (!result) {
              result = {
                uniqueQuestionKey,
                question: question,
                shortName: shortName,
                choices: choices,
                type: questionType,
                assessmentName: assessmentName,
                workshop: workshop || "default",
              };

              for (let i = 1; i <= choices.length; i++) {
                result[i] = 0;
              }
              sustainabilityChartData[key].push(result);
            }

            if (questionType === 'radio-ordered' || questionType === 'radio-unordered') {
              const responseIndex = choices.indexOf(surveyResponse[0]);
              if (responseIndex === -1) {
                return;
              }
              result[responseIndex + 1]++;
            } else if (questionType === 'checkbox') {
              const responseIndices = [];
              for (const responseValue of surveyResponse) {
                const index = choices.indexOf(responseValue);
                if (index !== -1) {
                  responseIndices.push(index);
                }
              }
              for (const index of responseIndices) {
                result[index + 1]++;
              }
            }
          }
        });
      });
    });

    return sustainabilityChartData;
  };

  const prepareSustainabilityCommentData = (assessmentsWithResponses) => {
    let sustainabilityCommentData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Sustainability conditions');

    filteredData.forEach(assessment => {
      const { name: assessmentName } = assessment;

      assessment.responses.forEach(response => {
        response.survey.forEach(({ question, response: surveyResponse, questionType, workshop, shortName }) => {
          const key = workshop || "default";
          const uniqueQuestionKey = `${assessmentName}-${shortName || question}`;

          if (questionType === 'text') {
            if (!sustainabilityCommentData[key]) {
              sustainabilityCommentData[key] = {};
            }

            if (!sustainabilityCommentData[key][uniqueQuestionKey]) {
              sustainabilityCommentData[key][uniqueQuestionKey] = {
                uniqueQuestionKey: uniqueQuestionKey,
                question: question,
                shortName: shortName,
                assessmentName: assessmentName,
                responses: []
              };
            }

            sustainabilityCommentData[key][uniqueQuestionKey].responses.push(surveyResponse[0]);
          }
        });
      });
    });

    Object.keys(sustainabilityCommentData).forEach(key => {
      sustainabilityCommentData[key] = Object.values(sustainabilityCommentData[key]);
    });

    return sustainabilityCommentData;
  };

  const prepareStudentChartData = (assessmentsWithResponses) => {
    let studentChartData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Student characteristics');

    filteredData.forEach(assessment => {
      const { name: assessmentName } = assessment;

      assessment.responses.forEach(response => {
        response.survey.forEach(({
          response: surveyResponse,
          choices,
          items,
          question,
          shortName,
          questionType,
          workshop,
        }) => {
          if (questionType === 'text') {
            return;
          }

          const key = workshop || "default";

          if (!studentChartData[key]) {
            studentChartData[key] = [];
          }

          if (questionType === 'matrix-radio-ordered') {
            items.forEach((item, rowIndex) => {
              const uniqueItemKey = `${assessmentName}-${item}`;

              let result = studentChartData[key].find(r => r.uniqueQuestionKey === uniqueItemKey);

              if (!result) {
                result = {
                  uniqueQuestionKey: uniqueItemKey,
                  question: `${question} - ${item}`,
                  shortName: item,
                  choices: choices,
                  type: questionType,
                  assessmentName: assessmentName,
                  workshop: workshop || "default",
                };

                for (let i = 1; i <= choices.length; i++) {
                  result[i] = 0;
                }
                studentChartData[key].push(result);
              }

              const responseValue = surveyResponse[rowIndex];
              const responseIndex = choices.indexOf(responseValue);
              if (responseIndex !== -1) {
                result[responseIndex + 1]++;
              }
            });
          } else {
            const uniqueQuestionKey = `${assessmentName}-${question}`;

            let result = studentChartData[key].find(r => r.uniqueQuestionKey === uniqueQuestionKey);

            if (!result) {
              result = {
                uniqueQuestionKey,
                question: question,
                shortName: shortName,
                choices: choices,
                type: questionType,
                assessmentName: assessmentName,
                workshop: workshop || "default",
              };

              for (let i = 1; i <= choices.length; i++) {
                result[i] = 0;
              }
              studentChartData[key].push(result);
            }

            if (questionType === 'radio-ordered' || questionType === 'radio-unordered') {
              const responseIndex = choices.indexOf(surveyResponse[0]);
              if (responseIndex === -1) {
                return;
              }
              result[responseIndex + 1]++;
            } else if (questionType === 'checkbox') {
              const responseIndices = [];
              for (const responseValue of surveyResponse) {
                const index = choices.indexOf(responseValue);
                if (index !== -1) {
                  responseIndices.push(index);
                }
              }
              for (const index of responseIndices) {
                result[index + 1]++;
              }
            }
          }
        });
      });
    });

    return studentChartData;
  };

  const prepareStudentCommentData = (assessmentsWithResponses) => {
    let studentCommentData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Student characteristics');

    filteredData.forEach(assessment => {
      const { name: assessmentName } = assessment;

      assessment.responses.forEach(response => {
        response.survey.forEach(({ question, response: surveyResponse, questionType, workshop, shortName }) => {
          const key = workshop || "default";
          const uniqueQuestionKey = `${assessmentName}-${shortName || question}`;

          if (questionType === 'text') {
            if (!studentCommentData[key]) {
              studentCommentData[key] = {};
            }

            if (!studentCommentData[key][uniqueQuestionKey]) {
              studentCommentData[key][uniqueQuestionKey] = {
                uniqueQuestionKey: uniqueQuestionKey,
                question: question,
                shortName: shortName,
                assessmentName: assessmentName,
                responses: []
              };
            }

            studentCommentData[key][uniqueQuestionKey].responses.push(surveyResponse[0]);
          }
        });
      });
    });

    Object.keys(studentCommentData).forEach(key => {
      studentCommentData[key] = Object.values(studentCommentData[key]);
    });

    return studentCommentData;
  };

  const prepareStudentLearningChartData = (assessmentsWithResponses) => {
    let studentLearningChartData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Student learning outcomes');

    filteredData.forEach(assessment => {
      const { name: assessmentName } = assessment;

      assessment.responses.forEach(response => {
        response.survey.forEach(({
          shortName,
          response: surveyResponse,
          choices,
          correctAnswer,
          competencies,
          question,
          questionType,
          workshop,
        }) => {
          if (questionType === 'text') {
            return;
          }

          const key = workshop || "default";
          const uniqueQuestionKey = `${assessmentName}-${shortName}`;

          if (!studentLearningChartData[key]) {
            studentLearningChartData[key] = [];
          }

          let result = studentLearningChartData[key].find(r => r.uniqueQuestionKey === uniqueQuestionKey);
          if (!result) {
            result = {
              uniqueQuestionKey,
              question: question,
              shortName: shortName,
              choices: choices,
              correctAnswer: correctAnswer,
              competencies: competencies.map(competency => competency.split(':')[0]),
              type: questionType,
              assessmentName: assessmentName,
              workshop: workshop || "default",
            };

            for (let i = 1; i <= choices.length; i++) {
              result[i] = 0;
            }
            studentLearningChartData[key].push(result);
          }

          if (questionType === 'radio-ordered' || questionType === 'radio-unordered') {
            const responseIndex = choices.indexOf(surveyResponse[0]);
            if (responseIndex === -1) {
              return;
            }
            result[responseIndex + 1]++;
          } else if (questionType === 'checkbox') {
            const responseIndices = [];
            for (const responseValue of surveyResponse) {
              const index = choices.indexOf(responseValue);
              if (index !== -1) {
                responseIndices.push(index);
              }
            }
            for (const index of responseIndices) {
              result[index + 1]++;
            }
          }
        });
      });
    });

    return studentLearningChartData;
  };

  const prepareStudentLearningCommentData = (assessmentsWithResponses) => {
    let studentLearningCommentData = {};

    const filteredData = assessmentsWithResponses.filter(item => item.type === 'Student learning outcomes');

    filteredData.forEach(assessment => {
      const { name: assessmentName } = assessment;

      assessment.responses.forEach(response => {
        response.survey.forEach(({ question, response: surveyResponse, questionType, workshop, shortName }) => {
          const key = workshop || "default";
          const uniqueQuestionKey = `${assessmentName}-${shortName || question}`;

          if (questionType === 'text') {
            if (!studentLearningCommentData[key]) {
              studentLearningCommentData[key] = {};
            }

            if (!studentLearningCommentData[key][uniqueQuestionKey]) {
              studentLearningCommentData[key][uniqueQuestionKey] = {
                uniqueQuestionKey: uniqueQuestionKey,
                question: question,
                shortName: shortName,
                assessmentName: assessmentName,
                responses: []
              };
            }

            studentLearningCommentData[key][uniqueQuestionKey].responses.push(surveyResponse[0]);
          }
        });
      });
    });

    Object.keys(studentLearningCommentData).forEach(key => {
      studentLearningCommentData[key] = Object.values(studentLearningCommentData[key]);
    });

    return studentLearningCommentData;
  };

  const groupChartData = (assessmentType) => {
    let groupedData = {};

    if (chartData[assessmentType]) {
      Object.keys(chartData[assessmentType]).forEach(workshopKey => {
        chartData[assessmentType][workshopKey].forEach(item => {
          const assessmentKey = item.assessmentName || "default";

          if (!groupedData[assessmentKey]) {
            groupedData[assessmentKey] = {};
          }
          if (!groupedData[assessmentKey][workshopKey]) {
            groupedData[assessmentKey][workshopKey] = [];
          }
          groupedData[assessmentKey][workshopKey].push(item);
        });
      });
    }

    return groupedData;
  };

  const groupCommentData = (assessmentType) => {
    let groupedData = {};

    if (commentData[assessmentType]) {
      Object.entries(commentData[assessmentType]).forEach(([workshopKey, items]) => {
        items.forEach(item => {
          const assessmentKey = item.assessmentName;

          if (!groupedData[assessmentKey]) {
            groupedData[assessmentKey] = {};
          }

          if (!groupedData[assessmentKey][workshopKey]) {
            groupedData[assessmentKey][workshopKey] = [];
          }

          groupedData[assessmentKey][workshopKey].push(item);
        });
      });
    }

    return groupedData;
  };

  return (
    <Box display="flex" backgroundColor="white" style={{ height: '100vh', overflow: 'auto' }}>
      <Sidebar />
      <Box flex={1}>
        <Box mt="10px" ml="10px">
          <Topbar title="My Results" />
        </Box>

        <Box 
          display="grid" 
          gridTemplateColumns="repeat(12, 1fr)" 
          gridTemplateRows="4vh 39vh 39vh"
          gap="20px" 
          ml="20px" 
          mr="20px"
        >
          <Box gridColumn="span 12" gridRow="1" display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <FormControl variant="outlined" size="small" sx={{ minWidth: 170, marginRight: '20px' }}>
                <InputLabel id="monitoring">Choose a monitoring</InputLabel>
                <Select
                  labelId="monitoring"
                  id="monitoring"
                  value={selectedMonitoring}
                  onChange={handleChangeMonitoring}
                  autoWidth
                  label="Choose a monitoring"
                >
                  {monitorings && monitorings.map((monitoring) => (
                    <MenuItem key={monitoring._id} value={monitoring._id}>
                      {monitoring.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
                <InputLabel id="day">Select the session</InputLabel>
                <Select
                  labelId="day"
                  id="day"
                  value={selectedDay}
                  onChange={handleChangeDay}
                  autoWidth
                  label="Select the session"
                >
                  {days && days.map((day, index) => (
                    <MenuItem key={index} value={day}>
                      {day}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl variant="outlined" size="small" sx={{ minWidth: 140, marginRight: '20px' }}>
                <Button 
                  onClick={handleExportReport}
                  variant="contained"
                  color="primary"
                  sx={{ 
                    color: "black",
                    backgroundColor: "#F7941E",
                    borderRadius: "50px",
                    "&:hover": {
                      backgroundColor: "#D17A1D"
                    }
                  }}
                  disabled={!selectedDayAssessments || selectedDayAssessments.length === 0}
                >
                  {isExportingPDF ? "Creating report..." : "Export a Report in PDF"}
                </Button>
              </FormControl>
              <FormControl variant="outlined" size="small" sx={{ minWidth: 140, marginRight: '20px' }}>
                <Button 
                  onClick={handleExportData}
                  variant="contained"
                  color="primary"
                  sx={{ 
                    color: "black",
                    backgroundColor: "#F7941E",
                    borderRadius: "50px",
                    "&:hover": {
                      backgroundColor: "#D17A1D"
                    }
                  }}
                  disabled={!selectedDayAssessments || selectedDayAssessments.length === 0}
                >
                  Export data in CSV
                </Button>
              </FormControl>
            </Box>
          </Box>

          <Box
            gridColumn="span 6" gridRow="2"
            sx={{
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              borderRadius: '15px',
              backgroundColor: '#fff',
              mt: '15px',
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={valueOne} onChange={handleChangeTabsOne} aria-label="">
                <Tab label="Trainee characteristics"  />
                <Tab icon={<CommentIcon />} />
                <Tab label="Training characteristics"  />
                <Tab icon={<CommentIcon />} />
              </Tabs>
            </Box>
            <CustomTabPanel value={valueOne} index={0}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto' }}>
                {Object.entries(groupChartData('Trainee characteristics')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], index) => (
                      <React.Fragment key={index}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        <div style={{ height: '25vh', width: '100%' }}>
                          <BarChartReports data={items} />
                        </div>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={valueOne} index={1}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto' }}>
                {Object.entries(groupCommentData('Trainee characteristics')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], subIndex) => (
                      <React.Fragment key={subIndex}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        {items.map((item) => (
                          <DisplayTextQuestion
                            key={item.uniqueQuestionKey}
                            title={`${item.shortName ? `${item.shortName} - ` : ''}${item.question}`}
                            content={item.responses}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={valueOne} index={2}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto' }}>
                {Object.entries(groupChartData('Training characteristics')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], index) => (
                      <React.Fragment key={index}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        <div style={{ height: '25vh', width: '100%' }}>
                          <BarChartReports data={items} />
                        </div>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={valueOne} index={3}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto' }}>
                {Object.entries(groupCommentData('Training characteristics')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], subIndex) => (
                      <React.Fragment key={subIndex}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        {items.map((item) => (
                          <DisplayTextQuestion
                            key={item.uniqueQuestionKey}
                            title={`${item.shortName ? `${item.shortName} - ` : ''}${item.question}`}
                            content={item.responses}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
          </Box>
          
          <Box
            gridColumn="span 6" gridRow="2"
            sx={{
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              borderRadius: '15px',
              backgroundColor: '#fff',
              mt: '15px'
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={valueTwo} onChange={handleChangeTabsTwo} aria-label="">
                <Tab label="Immediate reactions"  />
                <Tab icon={<CommentIcon />} />
                <Tab label="Learning"  />
                <Tab icon={<CommentIcon />} />
              </Tabs>
            </Box>
            <CustomTabPanel value={valueTwo} index={0}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto' }}>
                {Object.entries(groupChartData('Immediate reactions')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], index) => (
                      <React.Fragment key={index}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        <div style={{ height: '25vh', width: '100%' }}>
                          <BarChartReports data={items} />
                        </div>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={valueTwo} index={1}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto' }}>
                {Object.entries(groupCommentData('Immediate reactions')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], subIndex) => (
                      <React.Fragment key={subIndex}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        {items.map((item) => (
                          <DisplayTextQuestion 
                            key={item.uniqueQuestionKey}
                            title={`${item.shortName ? `${item.shortName} - ` : ''}${item.question}`}
                            content={item.responses}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={valueTwo} index={2}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto' }}>
                {Object.entries(groupChartData('Learning')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], index) => (
                      <React.Fragment key={index}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        <div style={{ height: '25vh', width: '100%' }}>
                          <BarChartReports data={items} />
                        </div>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={valueTwo} index={3}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto' }}>
                {Object.entries(groupCommentData('Learning')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], subIndex) => (
                      <React.Fragment key={subIndex}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        {items.map((item) => (
                          <DisplayTextQuestion 
                            key={item.uniqueQuestionKey}
                            title={`${item.shortName ? `${item.shortName} - ` : ''}${item.question}`}
                            content={item.responses}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
          </Box>

          <Box gridColumn="span 6" gridRow="3"
            sx={{
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              borderRadius: '15px',
              backgroundColor: '#fff',
            }}>
           
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={valueThree} onChange={handleChangeTabsThree} aria-label="">
                <Tab label="Organizational conditions"  />
                <Tab icon={<CommentIcon />} />
                <Tab label="Behavioral changes"  />
                <Tab icon={<CommentIcon />} />
                <Tab label="Sustainability"  />
                <Tab icon={<CommentIcon />} />
              </Tabs>
            </Box>
            <CustomTabPanel value={valueThree} index={0}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto' }}>
                {Object.entries(groupChartData('Organizational conditions')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], index) => (
                      <React.Fragment key={index}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        <div style={{ height: '25vh', width: '100%' }}>
                          <BarChartReports data={items} />
                        </div>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={valueThree} index={1}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto' }}>
                {Object.entries(groupCommentData('Organizational conditions')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], subIndex) => (
                      <React.Fragment key={subIndex}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        {items.map((item) => (
                          <DisplayTextQuestion 
                            key={item.uniqueQuestionKey}
                            title={`${item.shortName ? `${item.shortName} - ` : ''}${item.question}`}
                            content={item.responses}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={valueThree} index={2}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto' }}>
                {Object.entries(groupChartData('Behavioral changes')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], subIndex) => (
                      <React.Fragment key={subIndex}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        <div style={{ height: '25vh', width: '100%' }}>
                          <BarChartReports data={items} />
                        </div>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={valueThree} index={3}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto' }}>
                {Object.entries(groupCommentData('Behavioral changes')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], subIndex) => (
                      <React.Fragment key={subIndex}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        {items.map((item) => (
                          <DisplayTextQuestion 
                            key={item.uniqueQuestionKey}
                            title={`${item.shortName ? `${item.shortName} - ` : ''}${item.question}`}
                            content={item.responses}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={valueThree} index={4}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {Object.entries(groupChartData('Sustainability conditions')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], subIndex) => (
                      <React.Fragment key={subIndex}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        <BarChartReports data={items} />
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={valueThree} index={5}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto' }}>
                {Object.entries(groupCommentData('Sustainability conditions')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], subIndex) => (
                      <React.Fragment key={subIndex}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        {items.map((item) => (
                          <DisplayTextQuestion 
                            key={item.uniqueQuestionKey}
                            title={`${item.shortName ? `${item.shortName} - ` : ''}${item.question}`}
                            content={item.responses}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
          </Box>

          <Box gridColumn="span 6" gridRow="3"
            sx={{
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              borderRadius: '15px',
              backgroundColor: '#fff',
            }}>
           
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={valueFour} onChange={handleChangeTabsFour} aria-label="">
                <Tab label="Student characteristics"  />
                <Tab icon={<CommentIcon />} />
                <Tab label="Student learning outcomes"  />
                <Tab icon={<CommentIcon />} />
              </Tabs>
            </Box>
            <CustomTabPanel value={valueFour} index={0}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {Object.entries(groupChartData('Student characteristics')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], subIndex) => (
                      <React.Fragment key={subIndex}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        <BarChartReports data={items} />
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={valueFour} index={1}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto' }}>
                {Object.entries(groupCommentData('Student characteristics')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], subIndex) => (
                      <React.Fragment key={subIndex}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        {items.map((item) => (
                          <DisplayTextQuestion 
                            key={item.uniqueQuestionKey}
                            title={`${item.shortName ? `${item.shortName} - ` : ''}${item.question}`}
                            content={item.responses}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={valueFour} index={2}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto' }}>
                {Object.entries(groupChartData('Student learning outcomes')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], index) => (
                      <React.Fragment key={index}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        <div style={{ height: '25vh', width: '100%' }}>
                          <BarChartReports data={items} />
                        </div>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={valueFour} index={3}>
              <Box style={{ height: '31vh', width: '100%', overflowY: 'auto' }}>
                {Object.entries(groupCommentData('Student learning outcomes')).map(([assessmentName, workshops], index) => (
                  <React.Fragment key={index}>
                    <Typography align="center" color="rgb(102,102,102)" variant="h5" fontWeight="bold" mt='10px'>
                      {assessmentName}
                    </Typography>
                    {Object.entries(workshops).map(([workshopName, items], subIndex) => (
                      <React.Fragment key={subIndex}>
                        {workshopName !== "default" && workshopName !== assessmentName && (
                          <Typography align="center" color="rgb(102,102,102)" variant="h6" mt='5px'>
                            {workshopName}
                          </Typography>
                        )}
                        {items.map((item) => (
                          <DisplayTextQuestion
                            key={item.uniqueQuestionKey}
                            title={`${item.shortName ? `${item.shortName} - ` : ''}${item.question}`}
                            content={item.responses}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            </CustomTabPanel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Reports;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'center',
        overflow: 'auto',        
        
      }}
      {...other}
    >
      {value === index && (
         <>{children}</> 
      )}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
