/**
 * Main Function:
 * - The AddBehavioralChangesQuestions component is specifically designed to aid in the creation and management of questions aimed at assessing behavioral changes following training sessions. It facilitates both manual question creation and AI-assisted generation, accommodating a variety of assessment needs.
 * 
 * Frontend Operations:
 * - Leverages Formik for form submission handling, enabling dynamic question addition and customization based on user inputs or AI-generated suggestions.
 * - Allows questions to be tied to specific workshops, frameworks, and competency areas, enhancing the relevance and specificity of each question to the training content.
 * - Provides AI assistance for question generation, offering users the option to generate questions based on predefined types such as "Actual use of training content" and "Transfer of digital skills," thus streamlining the question creation process.
 * - Supports manual entry of questions for users seeking more control over question content, including the ability to specify question types (e.g., text, multiple choice) and mandatory status.
 * - Features a dynamic UI for selecting competencies and activities related to the question, facilitating granular targeting of behavioral changes.
 * 
 * Backend Connections:
 * - Utilizes Axios for API interactions, specifically:
 *   - A POST request to `${BACKEND_URL}/query-embedding-faiss` for finding competencies related to a given query. This endpoint is used to match input questions with relevant competencies based on AI analysis.
 *   - Interaction with OpenAI's ChatGPT API through a POST request to `https://api.openai.com/v1/chat/completions` for AI-assisted question generation, using an API key for authentication.
 *   - The component may also interact with other endpoints for framework-specific data retrieval, although these are not explicitly defined within the provided code context.
 * - Incorporates environmental variables for secure API access, such as utilizing an API key for OpenAI's ChatGPT to assist in question generation.
 * - Employs backend communication for competency encoding, leveraging AI to match questions with relevant competencies based on question content, streamlining the process of aligning questions with educational objectives.
 */


import { Formik, Form, useFormikContext, FieldArray } from "formik";
import * as Yup from "yup";
import { Box,  TextField, Button, Select, MenuItem, InputLabel, Typography, FormControl, IconButton, FormControlLabel, Switch, Chip } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { Delete } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import i18n from 'i18next';
import CircularProgress from "@mui/material/CircularProgress";
import { traineeCompetenceAreas, 
  digCompEduElementaryCompetences, 
  digCompEduActivities, 
  RCNumObligatoireElementaryCompetences,
  RCNumObligatoireActivities,
  RCNumPostObligatoireElementaryCompetences,
  RCNumPostObligatoireActivities } from "../data/frameworksData"

import axios from "axios";
import { BACKEND_URL } from "../config";

const API_KEY = process.env.REACT_APP_OPENAI_KEY; // API Key for Chat-GPT API

const predefinedOptions = {
  "Actual use of training content": {
    "en": ["Not at all", "Rather not", "A little", "Somewhat yes", "Yes", "Absolutely"],
    "fr": ["Pas du tout", "Plutôt pas", "Un peu", "Plutôt oui", "Oui", "Tout à fait"],
    "it": ["Per niente", "Piuttosto no", "Un po", "Piuttosto sì", "Sì", "Assolutamente"],
    "de": ["Überhaupt nicht", "Eher nicht", "Ein wenig", "Ziemlich ja", "Ja", "Völlig"]
  },

  "Transfer of digital skills": {
    en: ["Not at all confident", "Slightly confident", "Somewhat confident", "Moderately confident", "Confident", "Totally confident"],
    fr: ["Pas du tout confiant", "Un peu confiant", "Plutôt confiant", "Moyennement confiant", "Confiant", "Totalement confiant"],
    it: ["Per nulla confidente", "Leggermente confidente", "Abbastanza confidente", "Moderatamente confidente", "Convincente", "Completamente confidente"],
    de: ["Überhaupt nicht zuversichtlich", "Ein wenig zuversichtlich", "Etwas zuversichtlich", "Mäßig zuversichtlich", "Zuversichtlich"]
  },
};

const AddBehavioralChangesQuestions = ({ setQuestions, questions, workshops, splitWorkshops }) => {

  const [numberOfQuestions, setNumberOfQuestions] = useState(1);
  const [response, setResponse] = useState("");
  const [error, setError] = useState(null);
  const [isLoading,setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false); 
  const [helpWithAI, setHelpWithAI] = useState(false);
  const [automaticEncoding, setAutomaticEncoding] = useState(false);
  const [selectedCompetencies, setSelectedCompetencies] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  
  const languageMapping = {
  en: "English",
  de: "Deutsch",
  it: "Italian",
  fr: "Français",
  };

 const getOptionsForAdoptionType = (adoptionType, questionType) => {
  console.log(adoptionType, questionType)
    if (questionType === "radio-ordered") {
        const languageOptions = predefinedOptions[adoptionType] || predefinedOptions[adoptionType].en;
        return languageOptions[currentLanguage] || languageOptions.en;
    }
    else {
      return ["", "", "", ""];
    }
};

  const initialValues = {
    questionId: "",  
    question: "", 
    shortName: "", 
    questionType: "text",
    isMandatory: false,
    learningType: "",
    adoptionType: "", 
    correctAnswer: "",
    options: ["","","",""], 
    framework: "", 
    workshop: "", 
    area: "", 
    competency: "",
    proficiency: "", 
    activity: "",
    data: "",
    day: 0
  };

  const FormResetWatcher = ({ splitWorkshops, initialValues }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!splitWorkshops) {
      // Reset only the workshop field
      setFieldValue('workshop', initialValues.workshop);
    }
  }, [splitWorkshops, setFieldValue, initialValues]);

  return null;
  };

  const addQuestionsSchema = (splitWorkshops, helpWithAI) => {

  let schema = {
    adoptionType: Yup.string().required("The adoption type is required")
  };

  if (helpWithAI === false) {
    schema.question = Yup.string().required("The question is required");
    schema.shortName = Yup.string().max(40, "The short name must be at most 40 characters long").required('The short name is required');
  }

  if (splitWorkshops === true) {
    schema.workshop = Yup.string().required("The workshop is required");
  }

  return Yup.object().shape(schema);
  };

  useEffect(() => {
        const handleLanguageChange = (lang) => {
            setCurrentLanguage(lang);
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);

  // Event handler for changing the state of helpWithAI
  const handleHelpWithAIChange = (event) => {
    setHelpWithAI(event.target.checked);
    setAutomaticEncoding(event.target.checked);
  };

   // Event handler for changing the state of automaticEncoding
    const handleAutomaticEncodingChange = (question, shortName, framework, event) => {
      console.log("question", question);
      console.log("shortName", shortName);
      console.log("framework", framework);
      console.log("automatic encoding checked", event.target.checked); // Notice the use of event.target.checked here

      // Update the automaticEncoding state based on the checkbox/switch state
      setAutomaticEncoding(event.target.checked);

      // Call updateCompetenciesForQuestion only if automaticEncoding is true
      if (event.target.checked) {
        updateCompetenciesForQuestion(question, shortName, framework);
      }
    };


  const handleAdoptionTypeChange = (event, setFieldValue, values) => {
    const adoptionType = event.target.value;
    const questionType = values.questionType || "text";
      
    setFieldValue('options', getOptionsForAdoptionType(adoptionType, questionType)); 
    setFieldValue('adoptionType', adoptionType);
    setFieldValue('questionType', questionType);
  };

  const handleQuestionTypeChange = (event, setFieldValue, values) => {
    const questionType = event.target.value;
    const adoptionType = values.adoptionType || "Actual use of training content";
      
    setFieldValue('options', getOptionsForAdoptionType(adoptionType, questionType)); 
    setFieldValue('adoptionType', adoptionType);
    setFieldValue('questionType', questionType);
  };
 
  const handleSend = async (message, type) => {
      setResponse("");
      setIsLoading(false);
      setIsLoaded(false);
      setError(null); // Reset error message

      setIsLoading(true);

      try {
        const response = await processMessageToChatGPT(message, type);
        setResponse(response);
        setIsLoaded(true);
        setIsLoading(false);
        return response;
      } catch (error) {
        setError(error.message || "An unexpected error occurred");
        setIsLoading(false);      
        }
    };

  function getCompetencies(values) {
    switch (values.framework) {
      case "RCNUM POST-OBLIGATOIRE":
        return RCNumPostObligatoireElementaryCompetences[values.area] || [];
      case "RCNUM OBLIGATOIRE":
        return RCNumObligatoireElementaryCompetences[values.area] || [];
      case "DIGCOMPEDU":
        return digCompEduElementaryCompetences[values.area] || [];
      default:
        return digCompEduElementaryCompetences[values.area] || [];
    }
  }

  function getActivities(values) {
  switch (values.framework) {
    case "RCNUM POST-OBLIGATOIRE":
      return RCNumPostObligatoireActivities[values.competency] || [];
    case "RCNUM OBLIGATOIRE":
      return RCNumObligatoireActivities[values.competency] || [];
    case "DIGCOMPEDU":
      return digCompEduActivities[values.competency] || [];
    default:
      return digCompEduActivities[values.competency] || [];
  }
}
  
  const getAssessmentContent = (type) => {
    switch (type) { 
        case "Actual use of training content":
            return `Based on the training content provided, craft questions that gauge how often trainees applied the learned skills within a workplace setting. The questions must follow a specific multiple-choice format and the question shortname (not more than 40 characters). Follow this example strictly: \n
            
            1. At work, did you have the possibility ... ?
            A) Never
            B) Once
            C) Twice
            D) Occasionally
            E) Often
            F) Every day

            ShortName: Skills use`;

        case "Transfer of digital skills":
            return `Based on the training content provided, craft questions that gauge the trainee's confidence level in applying the learned skills within a workplace setting. The questions must follow a specific multiple-choice format and the question shortname (not more than 40 characters). Follow this example strictly: \n
            
            1. At work, how confident ... ?
            A) Not at all confident
            B) Slightly confident
            C) Somewhat confident
            D) Moderately confident
            E) Confident
            F) Totally confident
            
            ShortName: Skills confidence`;

        default:
            return "";
      }
};

  async function processMessageToChatGPT(message, type) {
    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: getAssessmentContent(type)
            
        },
        { role: "user", content: message },
      ],
      temperature: 0.2,
    };

    const response = await fetch(
      "https://api.openai.com/v1/chat/completions",

      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + API_KEY,
          "Content-Type": "application/json",
        },

        body: JSON.stringify(apiRequestBody),
      }
    );

    const data = await response.json();
    return data.choices[0].message.content;
  }

const findCompetencies = async (question, shortName, framework) => {
  try {
    if (framework) {
      const query = `${question} ${shortName}`;
      const response = await axios.post(`${BACKEND_URL}/query-embedding-faiss`, {
        query,
        collectionName: framework
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      return response.data;
    } else {
      console.log("Framework is not specified. Skipping the request.");
      return null;
    }
  } catch (error) {
    console.error('Error sending query to backend:', error);
  }
};


  const updateCompetenciesForQuestion = async (question, shortName, framework) => {
    // Fetch competencies
    const competencies = await findCompetencies(question, shortName, framework);
    console.log("competencies: ", competencies);

    // Assume competencies is an array of competency strings
    if (competencies && competencies.length > 0) {
      // Convert fetched competencies to the desired format
      const competenciesWithFramework = competencies.map(competency => `${framework} ${competency}`);
      // Update state
      setSelectedCompetencies(competenciesWithFramework);
      return competenciesWithFramework; // Return the competencies for further use
    } else {
      // Handle the case where no competencies are found or an error occurred
      setSelectedCompetencies([]);
      return [];
    }
};

const handleCompetencyChange = (event) => {
  setSelectedCompetencies([...selectedCompetencies, event.target.value]);
};

const handleDeleteCompetency = (competencyToRemove) => {
  setSelectedCompetencies(selectedCompetencies.filter(competency => competency !== competencyToRemove));
};

const handleSubmit = async (values, { resetForm }) => {

    let newQuestionId = questions.reduce((maxId, question) => Math.max(maxId, parseInt(question.questionId, 10)), 0) + 1;

    if (helpWithAI) {
        let content = `Make sure that the questions are aligned with the following information.\n
        Number of questions: ${numberOfQuestions}.\n
        Description of the activity: ${values.data}.\n
        Output language: ${languageMapping[currentLanguage]}`;
        
       try {
            const response = await handleSend(content, values.adoptionType);
            const questionsRegex = /(\d+\..+?)(?=(\d+\.)|$)/gs;
            const matches = [...response.matchAll(questionsRegex)];

            let currentQuestionId = newQuestionId;
            let parsedQuestions = []; // Initialize an empty array to hold the parsed questions

            for (const match of matches) {
              const lines = match[0].trim().split('\n');
              const questionText = lines[0].replace(/^\d+\.\s*/, '');
              const options = lines.filter(line => /^[A-Z]\)/.test(line)).map(line => line.split(') ')[1]);
              const shortNameRegex = /ShortName:\s*(.+)/;
              const shortNameMatch = match[0].match(shortNameRegex);
              const shortName = shortNameMatch ? shortNameMatch[1].trim() : '';

              // Fetch competencies for each question asynchronously
              const competencies = await updateCompetenciesForQuestion(questionText, shortName, values.framework);

              // Construct the question object with its competencies
              const question = {
                questionId: currentQuestionId.toString(),
                question: questionText,
                shortName: shortName,
                questionType: 'radio-unordered',
                isMandatory: values.isMandatory,
                learningType: values.learningType,
                workshop: values.workshop,
                framework: values.framework,
                options: options.map((option, optionIndex) => ({ value: String.fromCharCode(65 + optionIndex), label: option })),
                competencies // Directly use the fetched competencies
              };

              currentQuestionId++; // Increment the questionId for the next question

              parsedQuestions.push(question); // Add the question to the parsedQuestions array
            }

            // After all questions have been processed and competencies set, update the questions state
            setQuestions(prevQuestions => [...prevQuestions, ...parsedQuestions]);

          } catch (error) {
              console.error("Error:", error);
          }

        } else {

            const newQuestion = {
                questionId: newQuestionId.toString(),
                question: values.question,
                shortName: values.shortName,
                correctAnswer: values.correctAnswer,
                questionType: values.questionType,
                adoptionType: values.adoptionType,
                workshop: values.workshop,
                framework: values.framework,
                options: values.questionType === "text" 
                    ? [] 
                    : values.options.map((option, index) => ({ value: `${index + 1}`, label: option })),
                competencies: [...selectedCompetencies]
 
            };

        setQuestions(prevQuestions => [...prevQuestions, newQuestion]);
        setSelectedCompetencies([]);
        setAutomaticEncoding(false);

    }

    resetForm({
        values: {
            ...values,
            question: '',
            shortName: '',
            workshop: '',
            adoptionType: '',
            competencies: [],
        }
    });
};

  const styles = {
    formGroup: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "1rem",
    },
    invalidFeedback: {
      color: "red",
      marginTop: "0.25rem",
    },
  };

  return (
    <>

    <Box
      flexDirection="column"
      display="flex"
      sx={{
          backgroundColor: "#fff",
          marginBottom: "20px",
          paddingLeft: "20px",
      }}
      >

      <FormControlLabel
      control={<Switch checked={helpWithAI} onChange={handleHelpWithAIChange} />}
      label="Create questions with AI"
      /> 
    </Box>

    <Box ml="20px" mr="20px">
      <Box
        sx={{
          p: "10px",
          borderRadius: "4px",
        }}
      >

      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={addQuestionsSchema(splitWorkshops,helpWithAI)}>
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
          <Form>
            <FormResetWatcher splitWorkshops={splitWorkshops} initialValues={initialValues} />
            {splitWorkshops && (
              
              <FormControl fullWidth>
                  <InputLabel id="selectedWorkshop">Choose the workshop</InputLabel>
                  <Select
                  labelId="selectedWorkshop"
                  id="selectedWorkshop"
                  name="workshop"
                  value={values.workshop}
                  label="Choose the workshop"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.workshop && errors.workshop ? true : false}
                  sx={{mb:"15px"}}
                >
                  {workshops.length > 0 && 
                    workshops.map((workshop, index) => (
                      <MenuItem key={index} value={workshop}>
                        {workshop}
                      </MenuItem>
                    ))
                  }
                </Select>
                </FormControl>
              )}

              {!helpWithAI && (
                <FormControl fullWidth>
                   <TextField
                    id="question"
                    label="Enter your question"
                    value={values.question}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.question && errors.question ? true : false}
                    sx={{mb:"15px"}}
                    helperText={touched.question && errors.question ? errors.question : ""}
                    />
                </FormControl>
              )}

              {!helpWithAI && (
                <FormControl fullWidth>
                  <TextField
                      id="shortName"
                      label="Enter a short name for your question"
                      name="shortName"
                      value={values.shortName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ mb: "15px" }}
                      error={touched.shortName && errors.shortName ? true : false}
                      helperText={touched.shortName && errors.shortName ? errors.shortName : ""}
                    />
                </FormControl>
              )}

              {helpWithAI && (

              <FormControl fullWidth sx={{ mb: "15px" }}>
                <TextField
                  id="selectedData"
                  label="Please provide a description or content of the activity (up to 3500 characters)"
                  name="data"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  multiline
                  rows={10}
                  error={touched.selectedData && errors.selectedData ? true : false}
                  helperText={touched.selectedData && errors.selectedData ? errors.selectedData : ""}
                />
              </FormControl>

            )}

              <FormControl fullWidth>
                <InputLabel id="adoptionType">Adoption Type</InputLabel>
                <Select
                  labelId="adoptionType"
                  id="adoptionType"
                  name="adoptionType"
                  value={values.adoptionType}
                  label="Adoption Type"
                  onChange={(event) => handleAdoptionTypeChange(event, setFieldValue, values)}
                  onBlur={handleBlur}
                  error={touched.adoptionType && errors.adoptionType ? true : false}
                  sx={{mb:"15px"}}
                >
                  <MenuItem value="Actual use of training content">Actual use of training content</MenuItem>
                  <MenuItem value="Transfer of digital skills">Transfer of digital skills</MenuItem>
                </Select>
              </FormControl>

            {!helpWithAI && (
                <>
                  <FormControl fullWidth>
                    <InputLabel id="questionType">Question Type</InputLabel>
                    <Select
                      labelId="questionType"
                      id="questionType"
                      name="questionType"
                      value={values.questionType}
                      label="Question Type"
                      onChange={(event) => handleQuestionTypeChange(event, setFieldValue, values)} // Passing `values` here
                      onBlur={handleBlur}
                      error={touched.questionType && errors.questionType ? true : false}
                    >
                      <MenuItem value="text">Text</MenuItem>
                      <MenuItem value="radio-ordered">Ordered Choices</MenuItem>
                      <MenuItem value="radio-unordered">Unordered Choices</MenuItem>
                      <MenuItem value="checkbox">Checkboxes</MenuItem>
                    </Select>
                  </FormControl>

                  {values.questionType !== "text" && (
                    <>        
                      <h4>Answer Options</h4>
                      <FieldArray name="options">
                        {({ remove, push }) => (
                          <>
                            {values.options.map((option, index) => (
                              <div key={index} style={styles.formGroup}>
                                <div style={styles.inputGroup}>
                                  <TextField
                                    id={`options.${index}`}
                                    name={`options.${index}`}
                                    placeholder={`Option ${index + 1}`}
                                    value={option}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                      touched.options &&
                                      touched.options[index] &&
                                      errors.options &&
                                      errors.options[index]
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      touched.options &&
                                      touched.options[index] &&
                                      errors.options &&
                                      errors.options[index]
                                        ? errors.options[index]
                                        : ''
                                    }
                                  />
                                  <IconButton onClick={() => remove(index)}>
                                    <Delete />
                                  </IconButton>
                                </div>
                              </div>
                            ))}

                            <Button
                              onClick={() => push('')}
                              variant="contained"
                              sx={{
                                  backgroundColor: "#F7941E",
                                  borderRadius: "50px",
                                  color: "black",
                                  "&:hover": {
                                      backgroundColor: "#D17A1D",
                                  },
                              }}
                            >
                              <Typography variant="h5">ADD OPTION</Typography>
                            </Button>
                          </>
                        )}
                      </FieldArray>

                      <Box sx={{mt:"15px", mb:"30px"}}>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                              backgroundColor: "#F7941E",
                              borderRadius: "50px",
                              color: "black",
                              "&:hover": {
                                  backgroundColor: "#D17A1D",
                              },
                          }}
                        >
                          <Typography variant="h5">ADD</Typography>
                        </Button>
                      </Box>
                    </>
                  )}
                </>
              )}

            <FormControl fullWidth sx={{ mb: "15px", mt: "20px" }}> {/* Add mt (margin-top) for space */}
              <InputLabel id="selectedFramework">Choose a framework</InputLabel>
              <Tooltip placement="top" title="Allows you to categorize your learning outcomes in connection with recognized frameworks">
                <Select
                  labelId="selectedFramework"
                  id="selectedFramework"
                  name="framework"
                  value={values.framework}
                  label="Choose a framework"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.framework && errors.framework ? true : false}
                >
                  {Object.keys(traineeCompetenceAreas).map((framework, index) => (
                    <MenuItem key={index} value={framework}>
                      {framework}
                    </MenuItem>
                  ))}
                </Select>
              </Tooltip>
            </FormControl>

            <FormControlLabel
            control={<Switch 
              checked={automaticEncoding} 
              onChange={(event) => handleAutomaticEncodingChange(values.question, values.shortName, values.framework, event)}
              disabled={helpWithAI} />}
            label="Automatic encoding of competencies"
            sx={{mb:"15px"}}/> 

            {!helpWithAI && (

             <>
            <Typography sx={{ mb: "15px" }}>Add competencies manually :</Typography>
            <FormControl fullWidth>
              <InputLabel id="selectedArea">Choose a competency area </InputLabel>
              <Select
                labelId="selectedArea"
                id="selectedArea"
                name="area"
                value={values.area}
                label="Choose a competency area"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.area && errors.area ? true : false}
                sx={{ mb: "15px" }}
              >
                {(traineeCompetenceAreas[values.framework] || []).map((area, index) => (
                  <MenuItem key={index} value={area}>
                    {area}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="selectedCompetency">Choose an elementary competency </InputLabel>
              <Select
                labelId="selectedCompetency"
                id="selectedCompetency"
                name="competency"
                value={values.competency}
                label="Choose an elementary competency"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.competency && errors.competency ? true : false}
                sx={{ mb: "15px" }}
              >
                {getCompetencies(values).map((competency, index) => (
                  <MenuItem key={index} value={competency}>
                    {competency}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="selectedActivity">Choose an activity</InputLabel>
              <Select
                labelId="selectedActivity"
                id="selectedActivity"
                name="activity"
                value={values.activity}
                label="Choose an activity"
                onChange={handleCompetencyChange}
                onBlur={handleBlur}
                error={touched.activity && errors.activity ? true : false}
                sx={{ mb: "15px" }}
              >
                {getActivities(values).map((activity, index) => (
                  <MenuItem key={index} value={activity}>
                    {activity}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box display="flex" flexWrap="wrap" sx={{ mb: "15px" }}>
              {selectedCompetencies.map((competency, index) => (
                <Chip
                  key={index}
                  label={competency}
                  onDelete={() => handleDeleteCompetency(competency)}
                  sx={{ mr: "5px", mb: "5px" }}
                />
              ))}
            </Box>



            {/* <FormControl fullWidth>
              <InputLabel id="selectedProficiency">Choose a proficiency level </InputLabel>
              <Select
                labelId="selectedProficiency"
                id="selectedProficiency"
                name="proficiency"
                value={values.proficiency}
                label="Choose a proficiency level"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.proficiency && errors.proficiency ? true : false}
                sx={{ mb: "15px" }}
              >
                {(traineeProficiencyLevels).map((proficiency, index) => (
                  <MenuItem key={index} value={proficiency}>
                    {proficiency}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            </>

            )}

            {helpWithAI && (
              <FormControl fullWidth>
                <Box display="flex" alignItems="center">
                  <Typography>How many questions? &nbsp; </Typography>
                  <TextField 
                  id="numberOfQuestions" 
                  type="number"
                  autoFocus
                  size="small"
                  style={{ width: "70px" }}
                  margin="dense"
                  inputProps={{ min: "1" }}
                  value={values.numberOfQuestions}
                  onChange={(e) => {
                      const value = parseInt(e.target.value, 0);
                      if (value >= 1) {
                          setNumberOfQuestions(value);
                          
                        } else {
                            setNumberOfQuestions(1);
                        }
                    }} 
                  />
                </Box>
              </FormControl>
            )}

            <Box sx={{mt:"30px"}}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#F7941E",
                  borderRadius: "50px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#D17A1D",
                  },
                }}
                disabled={isLoading} // Disable the button when loading
              >
                {isLoading ? <CircularProgress size={24} /> : <Typography variant="h5">ADD</Typography>}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  </Box>
  </>
)};

export default AddBehavioralChangesQuestions;
