/**
 * Main Function:
 * - The EndSurvey component displays a thank you message to users upon completing a survey, providing a user-friendly closure to the survey process.
 * 
 * Frontend Operations:
 * - Utilizes the `useTranslation` hook from `react-i18next` for internationalization, allowing the display of messages in the user's selected language.
 * - Dynamically adjusts the application's language setting based on URL parameters, enhancing the user experience by maintaining language consistency across pages.
 * 
 * Backend Connections:
 * - No direct backend API calls are made from this component. However, it indirectly interacts with the backend through the language change functionality, which may affect how subsequent pages or components fetch data based on the user's language preference.
 */


import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import logo_dtc from "../../assets/logo.svg"; 
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const EndSurvey = () => {
  const { t } = useTranslation('translation');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const language = params.get('lng');

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);  // Allow to change the language
    }
  }, [language]);

  return (
    <>
      <Box
        m="40px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box>
          <img
            alt=""
            width="100%"
            height="100%"
            src={logo_dtc}
            style={{ cursor: 'pointer', borderRadius: '0%' }}
          />
        </Box>
       
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt="50px"
            sx={{
                padding: '20px',
                width: {
                xs: '90vw', 
                md: '50vw',
                },
            }}
            >

            <Typography variant="h3" align="center" style={{ margin: '10px' }}>
                {t('end_survey.message_thanks')}
            </Typography> 
         </Box>
      </Box>
    </>
  );
};

export default EndSurvey;
