import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'; 

const CookieConsentBanner = ({ onAccept, lng="en" }) => {
  const [visible, setVisible] = useState(true);
  const { t, i18n } = useTranslation(); 
  useEffect(() => {
    const handleLanguageChange = (lng) => {
      console.log(`Language changed to ${lng}`);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]); 

  const handleAccept = () => {
    onAccept();
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      width="100vw"
      bgcolor="rgba(0,0,0,0.85)"
      color="white"
      p={2}
      m={0}
      textAlign="center"
      boxSizing="border-box"
      zIndex="tooltip"
    >
      <Typography variant="body1">
        {t('cookieConsent.message')} 
      </Typography>
      <Button 
        color="primary" 
        onClick={handleAccept}
        sx={{ 
            mt: 1, 
            backgroundColor: 'white', 
            color: 'black', 
            borderColor: 'white',
            '&:hover': {
            backgroundColor: 'white', 
            color: 'black', 
            }
        }}
        >
        {t('cookieConsent.accept')} 
      </Button>
    </Box>
  );
};

export default CookieConsentBanner;
