/**
 * Main Function:
 * - The CustomTimeline component is designed to display logged activities related to training or monitoring in a chronological timeline format. It allows users to visualize the sequence and details of their activities, enhancing the tracking and reflection process on training progress or outcomes.
 * 
 * Frontend Operations:
 * - Utilizes Material-UI's Timeline components (Timeline, TimelineItem, TimelineSeparator, TimelineDot, and more) to construct a visually appealing timeline. Each log entry is represented as a TimelineItem, providing a structured view of activities.
 * - Implements reverse chronological order display for log entries to present the most recent activities first, enhancing the user experience by displaying the latest updates prominently.
 * - Incorporates icons (Delete, VisibilityIcon, RepeatIcon) to visually distinguish between different types of log entries (e.g., observations, repetitive tasks) and to offer functionality such as deletion of log entries.
 * - Provides interactive elements like IconButton for each log entry, enabling users to perform actions such as deleting log entries directly from the timeline.
 * 
 * Backend Operations:
 * - Handles the deletion of log entries by updating the logs array state and sending a POST request to the backend to sync the updated logs list. This involves constructing a payload with the updated logs array and current user and monitoring IDs, then submitting this data to a specified endpoint (`${BACKEND_URL}/logs/${currentMonitoringId}`) using axios.
 * - Uses JWT tokens for authorization in backend requests, ensuring secure communication with the backend and that operations are performed within the context of the authenticated user.
 * 
 */


import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Box, Typography, IconButton } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import RepeatIcon from '@mui/icons-material/Repeat';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import { BACKEND_URL } from "../config"; 

const CustomTimeline = ({ logs, setLogs, currentMonitoringId, currentUserId }) => {

  const reversedLogs = [...logs].reverse();  // Create a new reversed array

  const handleDelete = async (index) => {
    const originalIndex = logs.length - 1 - index; // Correct index for the original array
    const newLogs = [...logs];
    newLogs.splice(originalIndex, 1); // Delete using the original index
    setLogs(newLogs);

    const serverLogs = {
      userId: currentUserId, 
      monitoringId: currentMonitoringId,
      logs: newLogs,
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BACKEND_URL}/logs/${currentMonitoringId}`,
        serverLogs,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if(response.status === 200) {
        console.log('Log deleted successfully:');
      } else {
        console.log('An error occurred while deleting the log:', response);
      }
    } catch (error) {
      console.log('An error occurred while deleting the log:', error);
    }
  };

  if (logs.length === 0) {
    return <Typography mt="50px" variant="h6">No entries.</Typography>;
  }

  return (
    <Timeline>
      {reversedLogs.map((log, index) => {
        // Split the description into lines of 50 characters each
        const descriptionLines = log.description.match(/.{1,50}/g);

        return (
          <TimelineItem key={index} >
            
            <TimelineOppositeContent
                sx={{ display: 'flex', alignItems: 'center' }}
                variant="body2"
                color="text.secondary"
              >
                
              <Box flexGrow={1}>
              
                <IconButton onClick={() => handleDelete(index)}>
                <Delete />
               </IconButton>
                {log.day}
                </Box> 
               
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
              <TimelineDot style={{ backgroundColor: 'rgb(236, 141, 53)' }}>
                {log.logType === "observation" ? <VisibilityIcon /> : <RepeatIcon />}
              </TimelineDot>
              <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2, minWidth: '150px' }}>
              <Typography variant="h6" component="span" fontWeight="bold">
                {log.assessment}
              </Typography>
              {/* Render each line of the description as a separate Typography */}
              {descriptionLines.map((line, i) => (
                <Typography key={i}>{line}</Typography>
              ))}
            </TimelineContent>
          </TimelineItem>
        )
      })}
    </Timeline>
  );
};

export default CustomTimeline;
