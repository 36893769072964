/**
 * This module configures the i18next framework for internationalizing the React application. It enables
 * the application to support multiple languages, making it more accessible to users worldwide. The configuration
 * includes setting up a backend to load translation resources, detecting the user's preferred language, and initializing
 * the React i18next integration for seamless language switching within the React components.
 *
 * Key components of this setup include:
 * - i18next: The core internationalization framework used for managing translations.
 * - react-i18next: Provides React bindings for i18next, allowing for easy integration of translation functionalities in React components.
 * - i18next-browser-languagedetector: Automatically detects the user's language preferences from the browser settings.
 * - i18next-http-backend: Loads translation resources from a specified path on the server, supporting dynamic language switching.
 *
 * The selected language is determined by checking for a 'selectedLanguage' item in the localStorage; if not found, it defaults to English ('en').
 * This setup ensures that the application starts with the user's preferred language (if previously selected) or a default language, enhancing the user experience.
 *
 * The `init` method configures i18next with options for debugging, the default and fallback languages, interpolation settings to prevent value escaping,
 * and the backend configuration for loading the translation files from the `/locales/{{lng}}/{{ns}}.json` path.
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';

i18n
  .use(Backend)  // Load translations using http, in this case from public folder
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: selectedLanguage,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      resources: ['translation']
    }
  });

export default i18n;
