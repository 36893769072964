/**
 * ResetPassword is a secure interface allowing users to create a new password following a password reset request. It validates the user's token, ensures password and confirmation match, 
 * and communicates with the backend to update the user's password.
 * Functions and Interactions:
 * - **Password Reset Validation**: Checks that the entered password and confirmation password match, preventing user errors.
 * - **Token Verification**: Utilizes a token parameter from the URL to authenticate the password reset request, ensuring that the request is valid and authorized.
 * - **Backend Communication**: Submits the new password to the backend API, using the token for verification and updating the user's password securely.
 * - **User Feedback**: Provides immediate visual feedback through Alert components, informing the user of the success or failure of the password reset process.
 * - **Navigation**: Redirects users to the sign-in page upon successful password reset, streamlining the user experience back into account access.
 * - **Security Focus**: By requiring token verification and user action to reset passwords, the component reinforces the application's commitment to user security.
 *
 */


import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import logo_dtc from "../../assets/logo.svg";
import { BACKEND_URL } from "../../config"; 

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState(null);
  const { token } = useParams();
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const navigate = useNavigate();
  
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setMessage({ type: 'error', text: 'Les mots de passe ne correspondent pas' });
      return;
    }
    try {
      const authToken = localStorage.getItem("token");
      // Send a POST request to your backend endpoint
      const res = await axios.post(`${BACKEND_URL}/reset-password/${token}`, { password }, {
          headers: {
              Authorization: `Bearer ${authToken}`
          }
      });
      setMessage({ type: 'success', text: 'Votre mot de passe a été modifié avec succès' });
      setIsPasswordChanged(true);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setMessage({ type: 'error', text: error.response.data.message });
      } else {
        setMessage({ type: 'error', text: 'Une erreur est survenue' });
      }
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "15px",
          backgroundColor: "#fff",
          width: {
            xs: "90vw",
            md: "50vw",
          },
        }}
        component="form"
        onSubmit={handleSubmit}
      >
        <Box padding="60px">
          <Box
            mb="20px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box mb="20px">
              <img
                alt=""
                width="100%"
                height="100%"
                src={logo_dtc}
                style={{ borderRadius: "0%" }}
              />
            </Box>
          </Box>

          <Typography variant="h2" mb={2}>
            Create a new password
          </Typography>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            type="password"
            label="New password"
            name="password"
            autoComplete="password"
            value={password}
            onChange={handlePasswordChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm the new password"
            type="password"
            id="confirmPassword"
            autoComplete="confirmPassword"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
          <Box mt="10px" mb="10px">
            {message && <Alert severity={message.type}>{message.text}</Alert>}
          </Box>

          <Box mt={5} display="flex" justifyContent="center">
            {isPasswordChanged ? (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#F7941E",
                  borderRadius: "50px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#D17A1D",
                  },
                }}
                onClick={() => navigate("/signin")}
              >
                <Typography variant="h5">SIGN IN</Typography>
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#F7941E",
                  borderRadius: "50px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#D17A1D",
                  },
                }}
              >
                <Typography variant="h5">SUBMIT</Typography>
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
