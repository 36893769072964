/**
 * The Signup component facilitates new user registration, incorporating form inputs for user details and preferences, including first and last names, email, password, and platform mode. It integrates form validation, platform mode-specific fields, and a cookie consent mechanism to enhance user experience and compliance.
 *
 * Functions and Interactions:
 * - **Form Inputs**: Collects user information and preferences with validation through Yup schema, adapting dynamically to the selected platform mode.
 * - **Platform Mode Selection**: Allows users to choose between available platform modes (e.g., Education, Enterprise) with additional status options for certain modes.
 * - **Cookie Consent Check**: Disables form submission until the user has accepted cookie usage, ensuring compliance with privacy regulations.
 * - **Dynamic Help Tooltips**: Provides additional information for certain options, enhancing user understanding and decision-making.
 * - **Navigation and Redirects**: Utilizes `useNavigate` to redirect users post-registration or to the sign-in page, supporting smooth navigation flow.
 * - **Error and Success Handling**: Displays feedback through alerts based on the registration process outcome, informing users of success or detailing errors encountered.
 * - **Backend Integration**: Sends registration data to the backend API upon form submission, handling user registration process server-side.
 *
 */


import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Tooltip, IconButton, Select, MenuItem } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import Alert from "@mui/material/Alert";
import logo_dtc from "../../assets/logo.svg";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../config";
import CookieConsentBanner from '../../components/CookieConsentBanner';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';

const createSignupSchema = (platformMode) => {
  let schema = {
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    platformMode: Yup.string().required('Platform mode is required'),
  };

  if (platformMode === 'Education') {
    schema.userStatus = Yup.string().required('Status is required for Education platform mode');
  }

  return Yup.object().shape(schema);
};

const Signup = () => {
  
  const [message, setMessage] = useState(null);
  const [cookieConsent, setCookieConsent] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const isSandboxMode = location.state?.sandbox;
  const { t } = useTranslation('translation');
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en');
  const [lng, setLng] = useState(i18n.language);


  useEffect(() => {
    const handleLanguageChange = (newLng) => {
      setLng(newLng);
    };

    // Attach the event listener
    i18n.on('languageChanged', handleLanguageChange);

    // Clean up on component unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('selectedLanguage', language); 
    setSelectedLanguage(language);
  };

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    sandbox: isSandboxMode,
    platformMode: "",
    userStatus: ""
  });

  console.log("isSandboxMode",isSandboxMode)

  const handleChange = (event) => {
  const { name, value } = event.target;
  setData(prevData => {
    if (name === "platformMode" && value === "Enterprise") {
      return {
        ...prevData,
        [name]: value,
        userStatus: "Trainer"
      };
    }
    else if (name === "platformMode" && value === "Education") {
      return {
        ...prevData,
        [name]: value,
        userStatus: prevData.userStatus === "Trainer" ? "" : prevData.userStatus
      };
    }
    else {
      return {
        ...prevData,
        [name]: value
      };
    }
  });
};

  const handleAcceptCookies = () => {
  setCookieConsent(true);
  }; 

const handleSubmit = async (event) => {
  event.preventDefault();
  try {
    // Create the schema based on the current platformMode
    const signupSchema = createSignupSchema(data.platformMode);

    // Validate form data against the schema
    await signupSchema.validate(data, { abortEarly: false });

    // API call
    const res = await axios.post(`${BACKEND_URL}/register`, data);
    
    // Reset error message and set success message
    setError("");
    setMessage({
      type: "success",
      text: "Thanks for signing up! Please check your inbox to complete your registration. The confirmation email may take a few minutes to appear. Check your spam folder otherwise."
    });
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      setError(error.errors.join(", "));
    } else {
      setError("An unexpected error occurred. Please try again.");
    }
  }
};

  return (
    <>
    { !cookieConsent && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          bgcolor="rgba(0,0,0,0.5)" // Semi-transparent black background
        />
    )}
    <CookieConsentBanner onAccept={handleAcceptCookies} language={lng}/>  
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="90vh"
      >
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="90vh"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="stretch"
        justifyContent="center"
        sx={{
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "15px",
          backgroundColor: "#fff",
          width: {
            xs: "90vw",
            md: "70vw"
          }
        }}
        component="form"
        onSubmit={handleSubmit}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mr="20px"
          bgcolor="#D17A1D"
          borderRadius="15px 0 0 15px"
          flexGrow={1}
          flexBasis={0}
        >
          <Typography m="10px" variant="h2" color="white">
            {t('signup.welcome')}
          </Typography>
          <Box mt={5} display="flex" justifyContent="center">
            <Button
              disabled={!cookieConsent}
              onClick={() => navigate("/signin")}
              variant="contained"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "50px",
                color: "black",
                "&:hover": {
                  backgroundColor: "#eeeeee"
                }
              }}
            >
              <Typography variant="h5">{t('signup.signin')}</Typography>
            </Button>
          </Box>
        </Box>

        <Box flexGrow={2} flexBasis={0} padding="60px">
          <Box display="flex" justifyContent="flex-end">
            <Select
                  value={selectedLanguage}
                  onChange={(e) => {
                      // Handle the language change event
                      handleLanguageChange(e.target.value);
                  }}
                  size="small"
                  sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                >
                  <MenuItem value="en">
                    <span className="fi fi-gb"></span>      
                  </MenuItem>
                  <MenuItem value="fr">
                    <span className="fi fi-fr"></span>    
                  </MenuItem>
                  <MenuItem value="de">
                    <span className="fi fi-de"></span>      
                </MenuItem>
                <MenuItem value="it">
                  <span className="fi fi-it"></span>    
                </MenuItem>
              </Select>
          </Box>
          <Box
            mb="20px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box>
              <img
                alt=""
                width="100%"
                height="100%"
                src={logo_dtc}
                style={{ borderRadius: "0%" }}
              />
            </Box>
          </Box>
          <Typography variant="h2" mb={2}>
            {t('signup.create_account')}
          </Typography>

          <TextField
            disabled={!cookieConsent}
            variant="outlined"
            margin="normal"
            fullWidth
            id="firstName"
            label={t('signup.first_name')}
            name="firstName"
            autoComplete="firstName"
            autoFocus
            value={data.firstName}
            onChange={handleChange}
          />

          <TextField
            disabled={!cookieConsent}
            variant="outlined"
            margin="normal"
            fullWidth
            id="lastName"
            label={t('signup.last_name')}
            name="lastName"
            autoComplete="lastName"
            autoFocus
            value={data.lastName}
            onChange={handleChange}
          />

          <TextField
            disabled={!cookieConsent}
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label={t('signup.email')}
            name="email"
            autoComplete="email"
            autoFocus
            value={data.email}
            onChange={handleChange}
          />
          <TextField
            disabled={!cookieConsent}
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label={t('signup.password')}
            type="password"
            id="password"
            autoComplete="current-password"
            value={data.password}
            onChange={handleChange}
          />

          <FormControl component="fieldset" margin="normal" sx={{ display: 'flex', alignItems: 'center' }}>
          <FormLabel component="legend" sx={{ mr: 1 }}>
            {t('signup.platform_mode')}
            <Tooltip 
              title={t('signup.platform_mode_note')}
              arrow
            >
              <IconButton size="small">
                <HelpIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </FormLabel>
          <RadioGroup
            row
            name="platformMode"
            value={data.platformMode}
            onChange={handleChange}
          >
            <FormControlLabel value="Education" control={<Radio />} label={t('signup.education')} />
            <FormControlLabel value="Enterprise" control={<Radio />} label={t('signup.enterprise')}  disabled={true} />
          </RadioGroup>
        </FormControl>

        {data.platformMode === "Education" && (
          <FormControl component="fieldset" margin="normal" sx={{ display: 'flex', alignItems: 'center' }}>
            <FormLabel component="legend" sx={{ mr: 1 }}>
              {t('signup.status')}
              <Tooltip 
              title={t('signup.status_note')}
              arrow>
              <IconButton size="small">
                <HelpIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            </FormLabel>
            <RadioGroup
              row
              name="userStatus"
              value={data.userStatus}
              onChange={handleChange}
            >
              <FormControlLabel value="Teacher-trainer" control={<Radio />} label={t('signup.teacher_trainer')} />
              <FormControlLabel value="Teacher" control={<Radio />} label={t('signup.teacher')} disabled={true} />
            </RadioGroup>
          </FormControl>
        )}

          <Box mt="10px" mb="10px">
            {error ? (
                <Alert severity="error">{error}</Alert>
            ) : (
                message && <Alert severity="success">{message ? message.text : ""}</Alert>
            )}
          </Box>

          <Box mt={5} display="flex" justifyContent="center">
            <Button
              disabled={!cookieConsent}
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#F7941E",
                borderRadius: "50px",
                color: "black",
                "&:hover": {
                  backgroundColor: "#D17A1D"
                }
              }}
            >
              <Typography variant="h5">{t('signup.signup')}</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
</>
  );
};

export default Signup;
